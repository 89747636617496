import { observable, computed, action, decorate } from 'mobx'
import { format } from 'date-fns'

import { createClaim } from 'services/claim'

class ClaimFormClass {
  insurerCustomerId = ''
  insurerClaimId = ''
  externalId = ''
  claimantFirstName = ''
  claimantLastName = ''
  insuredFirstName = ''
  addressLine1 = ''
  addressLine2 = ''
  addressLine3 = ''
  city = ''
  zipCode = ''
  geometry = ''
  country = ''
  policyNumber = ''
  insuredLastName = ''
  streetNumber = ''
  claimType = 'WTDG'
  expertiseTypeKey = 'VISIO_EXPERTISE_TYPE'
  dateOfLoss = new Date()
  riskType = ''
  errors = []

  get asJson() {
    return {
      insurerCustomerId: this.insurerCustomerId,
      insurerClaimId: this.insurerClaimId,
      externalId: this.externalId,
      claimantFirstName: this.claimantFirstName,
      claimantLastName: this.claimantLastName,
      insuredFirstName: this.insuredFirstName,
      streetNumber: this.streetNumber,
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2,
      addressLine3: this.addressLine3,
      city: this.city,
      zipCode: this.zipCode,
      geometry: this.geometry,
      country: this.country,
      policyNumber: this.policyNumber,
      insuredLastName: this.insuredLastName,
      claimType: this.claimType,
      expertiseTypeKey: this.expertiseTypeKey,
      dateOfLoss: format(this.dateOfLoss, 'y-MM-dd'),
      riskTypeKey: this.riskType,
    }
  }

  setProperty(key, value) {
    this[key] = value
  }
}

const ClaimForm = decorate(ClaimFormClass, {
  insurerCustomerId: observable,
  insurerClaimId: observable,
  externalId: observable,
  claimantFirstName: observable,
  claimantLastName: observable,
  insuredFirstName: observable,
  addressLine1: observable,
  addressLine2: observable,
  addressLine3: observable,
  city: observable,
  zipCode: observable,
  geometry: observable,
  country: observable,
  policyNumber: observable,
  insuredLastName: observable,
  streetNumber: observable,
  claimType: observable,
  expertiseTypeKey: observable,
  dateOfLoss: observable,
  riskType: observable,
  errors: observable,

  setProperty: action,

  asJson: computed,
})

class CreateClaimCtrl {
  newClaim = null
  showModal = false
  saving = false

  constructor() {
    this.newClaim = new ClaimForm()
  }

  setProperty(key, value) {
    this[key] = value
  }

  createClaim() {
    this.saving = true
    this.newClaim.setProperty('errors', [])

    return createClaim(this.newClaim.asJson)
      .then(
        action(res => {
          this.saving = false
          this.showModal = false
          return res
        }),
      )
      .catch(
        action(err => {
          this.newClaim.setProperty('errors', err)
          this.saving = false
          return Promise.reject(err)
        }),
      )
  }

  clearForm() {
    this.newClaim = new ClaimForm()
  }
}

const DecoratedCreateClaimCtrl = decorate(CreateClaimCtrl, {
  newClaim: observable,
  showModal: observable,
  saving: observable,

  setProperty: action,
  createClaim: action,
  clearForm: action,
})

export default new DecoratedCreateClaimCtrl()
