import { isNil, type, path } from 'ramda'

import RiskConformityCtrl from 'stores/Mission/view/RiskConformityCtrl'
import CausesAndCircumstancesCtrl from 'stores/Mission/view/CausesAndCircumstancesCtrl'
import DamageDescriptionCtrl from 'stores/Mission/view/DamageDescriptionCtrl'
import ConclusionCtrl from 'stores/Mission/ConclusionCtrl'
import IrsiCtrl from 'stores/Mission/view/IrsiCtrl'

export const formatValidator = data => {
  const allIndexation = [
    RiskConformityCtrl.form.indexation,
    CausesAndCircumstancesCtrl.form.indexation,
    DamageDescriptionCtrl.form.indexation,
    ConclusionCtrl.form.indexation,
  ]

  if (IrsiCtrl.form) {
    allIndexation.push(IrsiCtrl.form.indexation)
  }

  const sortedByGroup = data.reduce((acc, error) => {
    let found = false

    for (let i = 0; i < allIndexation.length; i++) {
      const group = allIndexation[i]

      for (let j = 0; j < group.fields.length; j++) {
        const input = group.fields[j]
        if (input.path === error.property_path) {
          error.label = input.label
          error.redirectTo = group.name
          error.focus = input.name
          error.groupId = input.groupId
          error.formCtrl = input.formCtrl
          if (isNil(acc[group.name])) {
            acc[group.name] = [error]
          } else {
            acc[group.name].push(error)
          }
          found = true
          break
        }
      }
      if (found) break
    }

    if (!found) {
      if (acc.noGroup) acc.noGroup.push(error)
      else acc.noGroup = [error]
    }

    return acc
  }, {})

  return sortedByGroup
}

export const handleSendToDarvaErrors = err => {
  const filterSendToDarvaErrors = msg => {
    if (!isNil(msg.detail)) return true
    else if (!isNil(msg) && type(msg) === 'String') return true

    return false
  }

  const formatSendToDarvaErrors = msg => {
    const detail = isNil(msg.detail) ? msg : msg.detail

    if (detail.includes('=>')) return detail.slice(0, detail.indexOf('=>')).trim()
    return detail.trim()
  }

  if (err.errors && type(err.errors) === 'Array') {
    return {
      isSent: false,
      messages: err.errors.filter(filterSendToDarvaErrors).map(formatSendToDarvaErrors),
    }
  } else if (path(['data', 'errors'], err) && type(err.data.errors) === 'Array') {
    return {
      isSent: false,
      messages: err.data.errors.filter(filterSendToDarvaErrors).map(formatSendToDarvaErrors),
    }
  }

  return { isSent: false }
}
