import React from 'react'
import { inject, observer } from 'mobx-react'
import { NavLink, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PendingModal from 'pages/ManagerClaim/pages/Pending/PendingModal'

import ButtonWithConfirmation from 'components/ButtonWithConfirmation/ButtonWithConfirmation'

const ManagerClaimMobile = ({
  UiCtrl: { toggleSideNav },
  CartStore: { easyEstimation },
  ManagerClaimStore: { isClaimClosed, isPending, closeWithNoFurtherAction },
}) => {
  const { t } = useTranslation()
  const { id } = useParams()

  return (
    <div className="side-nav">
      <h5 className="pt-3">{t('claim.sideNav.title')}</h5>
      <NavLink
        className="nav-link"
        activeClassName="active"
        to={`/managerClaim/${id}/information`}
        onClick={() => toggleSideNav(false)}
      >
        {t('claim.sideNav.information')}
      </NavLink>

      <h5 className="border-top pt-3 mt-2">{t('mission.sideNav.titleAnnexe')}</h5>

      <NavLink
        className="nav-link"
        activeClassName="active"
        to={`/managerClaim/${id}/partyInvolved`}
        onClick={() => toggleSideNav(false)}
      >
        {t('mission.sideNav.partyInvolved')}
      </NavLink>

      <NavLink
        className="nav-link"
        activeClassName="active"
        to={`/managerClaim/${id}/supportingDocuments`}
        onClick={() => toggleSideNav(false)}
      >
        {t('mission.sideNav.supportingDocument')}
      </NavLink>

      <h5 className="border-top pt-3 mt-2">{t('mission.sideNav.sinister')}</h5>

      <NavLink
        className="nav-link"
        activeClassName="active"
        to={`/managerClaim/${id}/declaration`}
        onClick={() => toggleSideNav(false)}
      >
        {t('mission.sideNav.sinisterDeclaration')}
      </NavLink>
      <NavLink className="nav-link" activeClassName="active" to={`/managerClaim/${id}/calculation`}>
        {t('mission.sideNav.calculation')}
      </NavLink>
      {!easyEstimation && (
        <NavLink
          className="nav-link"
          activeClassName="active"
          to={`/managerClaim/${id}/ventilation`}
        >
          {t('mission.sideNav.ventilationREN')}
        </NavLink>
      )}
      <NavLink
        className="nav-link"
        activeClassName="active"
        to={`/managerClaim/${id}/instruction`}
        onClick={() => toggleSideNav(false)}
      >
        {t('mission.sideNav.indemnisation')}
      </NavLink>

      <h5 className="mt-4 mb-4">{t('managerClaim.sideNav.compensation')}</h5>
      <NavLink className="nav-link" activeClassName="active" to={`/managerClaim/${id}/orientation`}>
        {t('managerClaim.sideNav.orientation')}
      </NavLink>
      <NavLink className="nav-link" activeClassName="active" to={`/managerClaim/${id}/report`}>
        {t('managerClaim.sideNav.report')}
      </NavLink>
      <h5 className="border-top pt-3 mt-2">{t('mission.sideNav.titleCommunication')}</h5>
      <NavLink className="nav-link" activeClassName="active" to={`/managerClaim/${id}/messaging`}>
        {t('messaging.title')}
      </NavLink>
      <NavLink
        className="nav-link"
        activeClassName="active"
        to={`/managerClaim/${id}/events`}
        onClick={() => toggleSideNav(false)}
      >
        {t('events.title')}
      </NavLink>

      {!isClaimClosed && !isPending && <PendingModal />}

      {!isClaimClosed && (
        <ButtonWithConfirmation
          classNameWrapper="btn-block bg-white"
          className="btn btn-outline-primary btn-block"
          onClick={closeWithNoFurtherAction}
          confirmText={t('mission.report.confirmationCloseMission')}
          text={
            <span>
              Clôture du dossier <i className="fa fa-unlock-alt" />
            </span>
          }
        />
      )}
    </div>
  )
}

export default inject('UiCtrl', 'CartStore', 'ManagerClaimStore')(observer(ManagerClaimMobile))
