import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { useTranslation } from 'react-i18next'

import Input from 'components/Forms/Input'
import DatePicker from 'components/Forms/DatePicker'
import InputPrice from 'components/InputPrice/InputPrice'
import Button from 'components/Button/Button'
import Furniture from 'stores/Mission/domain/Furniture'
import Label from 'components/Label/Label'

const FurnitureAdd = ({ furniture, onCancel, onConfirm }) => {
  const { t } = useTranslation()
  const save = () => {
    if (!isEmpty(furniture.description) && !isEmpty(furniture.dateOfPurchased)) {
      onConfirm()
    }
  }

  return (
    <div>
      <div className="row mb-2">
        <div className="col-6">
          <Label required>{t('mission.calculation.furniture.description')}</Label>
        </div>
        <div className="col-2">
          <Label required>{t('mission.calculation.furniture.dateOfPurchased2')}</Label>
        </div>
        <div className="col-2">
          <Label required>{t('mission.calculation.furniture.totalPrice')}</Label>
        </div>
        <div className="col-2"></div>
      </div>
      <div className="row mb-2">
        <div className="col-6">
          <Input
            name="description"
            className="form-control"
            value={furniture.description}
            required={true}
            onChange={e => furniture.setProperty('description', e.target.value)}
          />
        </div>
        <div className="col-2">
          <DatePicker
            name="dateOfPurchased"
            className="form-control"
            onChange={date => furniture.setProperty('dateOfPurchased', date)}
            selected={furniture.dateOfPurchased}
            maxDate={new Date()}
            required={true}
            placeholder="dd/mm/yyyy"
          />
        </div>
        <div className="col-2">
          <InputPrice
            name="priceWithVat"
            price={furniture.totalPriceVAT}
            classNamesForPrice="font-weight-bold text-primary"
            disableShowUnit
            maxLengthNumber={6}
            required={true}
            onChange={e => furniture.setProperty('priceWithVat', e.target.value)}
          />
        </div>
        <div className="col-2">
          <button className="btn btn-outline-primary rounded-circle mr-3" onClick={onCancel}>
            <i className="fa fa-times"></i>
          </button>
          <Button className="btn btn-outline-primary rounded-circle" onClick={save}>
            <i className="fa fa-check"></i>
          </Button>
        </div>
      </div>
    </div>
  )
}

FurnitureAdd.prototype = {
  furniture: PropTypes.instanceOf(Furniture),
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default observer(FurnitureAdd)
