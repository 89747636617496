import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ButtonWithConfirmation from 'components/ButtonWithConfirmation/ButtonWithConfirmation'

const LineSelectShow = ({ label, price, isMissionClosed, onEdit, onDelete }) => {
  const { t } = useTranslation()

  return (
    <div className="row mb-2">
      <div className="col-8">{label}</div>

      <div className="col-2 text-right">{price}</div>

      {!isMissionClosed && (
        <div className="col-2 d-flex">
          <div>
            <button className="btn btn-outline-primary rounded-circle mr-3" onClick={onEdit}>
              <i className="fa fa-edit" />
            </button>
          </div>

          <ButtonWithConfirmation
            className="btn btn-outline-primary rounded-circle"
            icon
            iconClassName="fa fa-trash"
            onClick={onDelete}
            confirmText={t('mission.calculation.cart.confirmDelete')}
          />
        </div>
      )}
    </div>
  )
}

LineSelectShow.prototype = {
  label: PropTypes.string,
  price: PropTypes.number,
  isMissionClosed: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
}

export default LineSelectShow
