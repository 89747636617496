import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { isNil } from 'ramda'

import Furniture from 'stores/Mission/domain/Furniture'
import ButtonWithConfirmation from 'components/ButtonWithConfirmation/ButtonWithConfirmation'
import Tooltip from 'components/Tooltip/Tooltip'
import { euro } from 'utils/currency'

const FurnitureShow = ({ furniture, onEdit, onDelete }) => {
  const { t } = useTranslation()

  return (
    <div>
      <div className="row mb-2">
        <div className="col-6 py-2 d-flex">
          {`${furniture.brand} ${furniture.description}`}
          <span>&nbsp;</span>
          {!isNil(furniture.parentProduct) && (
            <Tooltip
              className="tooltip-action-bar"
              text={`${t('mission.calculation.furniture.replacementProduct')} ${
                furniture.parentProduct.description
              }`}
            >
              <i className="fa fa-recycle" aria-hidden="true"></i>
            </Tooltip>
          )}
        </div>
        <div className="col-2 py-2">
          {furniture.dateOfPurchased
            ? format(furniture.dateOfPurchased, t('common.dateFormat'))
            : '-'}
        </div>
        <div className="col-2 py-2">{euro(furniture.totalPriceVAT)}</div>
        <div className="col-2 d-flex">
          <div>
            <button className="btn btn-outline-primary rounded-circle mr-3" onClick={onEdit}>
              <i className="fa fa-edit" />
            </button>
          </div>

          <ButtonWithConfirmation
            className="btn btn-outline-primary rounded-circle"
            icon
            iconClassName="fa fa-trash"
            onClick={onDelete}
            confirmText={t('mission.calculation.cart.confirmDelete')}
          />
        </div>
      </div>
    </div>
  )
}

FurnitureShow.proptype = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  furniture: PropTypes.instanceOf(Furniture),
}
export default observer(FurnitureShow)
