import { observable, computed, action, decorate, runInAction } from 'mobx'
import { isNil, path } from 'ramda'

import AbstractField from 'utils/dynform/AbstractField'
import MissionStore from 'stores/Mission/domain/MissionStore'
import ManagerClaimStore from 'stores/ManagerClaim/domain/ManagerClaimStore'
import UserStore from 'stores/Common/domain/UserStore'
import CartStore from 'stores/Mission/domain/CartStore'
import PrejudiceStore from 'stores/Mission/domain/PrejudiceStore'
import AlertCtrl from 'stores/Common/view/AlertCtrl'
import { fetchEmbeddedCollectionModel } from 'services/embeddedCollection'
import Form from './Form'

class EmbeddedCollection extends AbstractField {
  collection = []
  errors = []
  show = true
  originalCollection = []
  type = 'embeddedCollection'
  maxLength = null
  minLength = null
  entryType = null
  fetchingCollectionModel = false
  fields = []
  form = null

  constructor(data) {
    super(data)
    this.entryType = data.entryType || null

    this.fetchCollectionModel().then(fields => {
      runInAction(() => {
        if (isNil(data.collectionData)) {
          console.warn(
            `Embedded collection : ${data.path} path is not found in store data or is not an array.`,
          )
          data.collectionData = []
        }

        this.collection = data.collectionData.map(data => {
          return new Form({
            form: { name: this.name, fields: fields },
            data,
            embeddedCollection: true,
          })
        })
        this.originalCollection = this.collection
        this.fetchingCollectionModel = false
      })
    })

    this.type = data.type || 'embeddedCollection'
    this.maxLength = data.maxLength
    this.minLength = data.minLength
    this.show = data.show
  }

  get changed() {
    if (this.collection.length !== this.originalCollection.length) return true

    let changed = false
    for (let i = 0; i < this.collection.length; i++) {
      if (this.collection[i].changed) {
        changed = true
        break
      }
    }
    return changed
  }

  get reachCollectionLimit() {
    if (isNil(this.maxLength)) return false
    else if (this.collection.length < this.maxLength) return false
    return true
  }

  get asJson() {
    return this.collection.map(form => form.asJson)
  }

  afterSave() {
    this.collection.forEach(form => form.afterSave())
    this.originalCollection = this.collection
  }

  setOriginalData() {
    this.collection = this.originalCollection
  }

  async fetchCollectionModel() {
    this.fetchingCollectionModel = true

    try {
      const fields = await fetchEmbeddedCollectionModel({
        wan: UserStore.isClaimManager ? ManagerClaimStore.id : MissionStore.id,
        entryType: this.entryType,
      })
      runInAction(() => {
        this.fields = fields
      })
      return fields
    } catch (error) {
      console.error(error)
      runInAction(() => {
        this.fetchingCollectionModel = false
      })
    }
  }

  add() {
    if (!this.reachCollectionLimit) {
      this.collection.push(new Form({ form: { name: this.name, fields: this.fields }, data: null }))
    }
  }

  remove(embedded) {
    let shouldDelete = true
    // ADDING EXTRA CONDITION TO REMOVE IRSI INVOLVED PARTY
    if (this.name === 'irsiInvolvedPartys') {
      const inputInvolvedParty = embedded.inputs.find(
        embeddedIP => embeddedIP.path === 'irsi.irsiInvolvedPartys.involvedPartyId',
      )

      // PROTECT IRSI PARTY INVOLVED DATA
      if (inputInvolvedParty && CartStore.uniqIPs.indexOf(inputInvolvedParty.value) > -1) {
        shouldDelete = false
        AlertCtrl.alert('danger', 'partyInvolved.isVentilated')
      }

      // PROTECT INSURED OPTION / PREJUDICE
      if (
        PrejudiceStore.ready &&
        !!PrejudiceStore.payloads.length &&
        path(['totals', 'finalPrice'], PrejudiceStore) > 0 &&
        inputInvolvedParty &&
        inputInvolvedParty.value === MissionStore.insuredInformationId
      ) {
        shouldDelete = false
        AlertCtrl.alert('danger', 'partyInvolved.prejudiceWarning')
      }
    }

    if (shouldDelete) {
      this.collection = this.collection.filter(form => form.id !== embedded.id)
    }
  }
}

const DecoratedEmbeddedCollection = decorate(EmbeddedCollection, {
  collection: observable,
  originalCollection: observable,
  errors: observable,
  show: observable,
  fields: observable,
  fetchingCollectionModel: observable,

  changed: computed,
  reachCollectionLimit: computed,
  asJson: computed,

  setOriginalData: action,
  fetchCollectionModel: action,
  add: action.bound,
  remove: action.bound,
  afterSave: action,
})

export default DecoratedEmbeddedCollection
