import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer, inject } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import Label from 'components/Label/Label'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'
import TextArea from 'components/Forms/TextArea'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import Button from 'components/Button/Button'
import AlertCtrl from 'stores/Common/view/AlertCtrl'
import classNames from 'classnames'

const ActionModal = ({
  CommonStore: { getMotifCancelType },
  MissionStore: { cancelMission },
  history,
  match: {
    params: { id },
  },
  showModal,
  closeModal,
}) => {
  const [grabbing, setGrabbing] = useState(false)
  const [motif, setMotif] = useState('')
  const [cancelMessage, setCancelMessage] = useState('')
  const { t } = useTranslation()
  const [messageDisabled, setMessageDisabled] = useState(true)

  useEffect(() => {
    if ('CANCELED_INJURED_DEFICIENCY' === motif) {
      setMessageDisabled(true)
      setCancelMessage()
    } else {
      setMessageDisabled(false)
    }
  }, [motif])

  const onClose = () => {
    setValue(null)
    setMessage('')
    closeModal()
  }

  const options = getMotifCancelType

  const validateCancel = async () => {
    if (motif) {
      try {
        await cancelMission(id, { motif, message: cancelMessage })
        history.push('/')
      } catch (err) {}
    } else {
      AlertCtrl.alert('danger', 'mission.qualification.motifRequired')
    }
  }

  const setValue = value => setMotif(value)
  const setMessage = value => setCancelMessage(value)

  const message = cancelMessage
  const choicesName = t(`mission.qualification.motif`)

  return (
    <ModalWithBackdrop
      size="modal-md"
      show={Boolean(showModal)}
      close={onClose}
      draggable
      setGrabbing={setGrabbing}
    >
      <div className={classNames('modal-header', { grabbable: !grabbing, grabbing: grabbing })}>
        <h5 className="modal-title">{t(`mission.qualification.${showModal}Modal`)}</h5>
        <button type="button" className="close" onClick={closeModal}>
          <span>&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="row no-gutters form-group">
          <Label className="col-2 col-form-label" required>
            {choicesName} :
          </Label>
          <div className="col-10">
            <ResponsiveSelect
              options={options}
              value={motif}
              onChange={e => setValue(e.target.value)}
            />
          </div>
        </div>

        <div className="row no-gutters form-group">
          <Label className="col-2 col-form-label" required={false}>
            {t('mission.qualification.message')} :
          </Label>

          <div className="col-10">
            <TextArea
              className="form-control"
              value={message}
              onChange={e => setMessage(e.target.value)}
              required={false}
              disabled={messageDisabled}
            />
          </div>
        </div>
      </div>
      <div className="modal-footer pb-2 pt-2">
        <button type="button" className="btn btn-secondary" onClick={onClose}>
          {t('common.cancel')}
        </button>
        <Button className="btn btn-primary" onClick={validateCancel}>
          {t('mission.qualification.validate')}
        </Button>
      </div>
    </ModalWithBackdrop>
  )
}

export default compose(
  inject('CommonStore', 'MissionStore'),
  withRouter,
  observer,
)(ActionModal)
