import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'

import CartStore from 'stores/Mission/domain/CartStore'
import InputPrice from 'components/InputPrice/InputPrice'
import Unit from 'components/Unit/Unit'

const QualificationPrice = observer(
  ({ qualificationData, itemData, changeSelfRepairRate, changeHourPrice }) => {
    if (!qualificationData) return null

    if (qualificationData.cde === 'self-repair') {
      return (
        <InputPrice
          name={`qualification-${qualificationData.cde}`}
          price={CartStore.configuration.selfRepairRate}
          onChange={changeSelfRepairRate}
          disabled={!itemData.piActive}
          width={59}
        />
      )
    }

    return (
      <InputPrice
        name={`qualification-${qualificationData.cde}`}
        price={qualificationData.hourPrice}
        onChange={changeHourPrice.bind(this, qualificationData)}
        disabled={!itemData.piActive}
        width={59}
      />
    )
  },
)

class QualificationRow extends React.Component {
  onChange = e => this.props.itemData.setProperty(e.target.name, e.target.value)
  changeSelfRepairRate = e => CartStore.configuration.setProperty('selfRepairRate', e.target.value)
  changeHourPrice = (qualificationData, e) => qualificationData.changeHourPrice(e.target.value)

  render() {
    const { itemData, packageData, t } = this.props
    const qualification = CartStore.findQualification(itemData.qualification.cde)
    if (!qualification) return null

    if (itemData.qtUnitMinute === 0)
      return (
        <div className="row pl-2 no-gutters">
          <div className="col-12">
            <span className="font-weight-bold">
              {t('mission.calculation.detailPackage.qualificationLabel')}
            </span>{' '}
            <span className="font-italic">{itemData.qualification.label}</span>
          </div>
        </div>
      )

    return [
      <div className="row no-gutters pl-2" key={`item-qualification-${itemData.id}`}>
        <div className="col-12">
          <span className="font-weight-bold">
            {t('mission.calculation.detailPackage.qualificationLabel')}
          </span>
          {itemData.selfRepair ? (
            <span className="font-italic">
              {t('mission.rates.selfRepair')} ({packageData.difficulty} *) (
              <QualificationPrice
                qualificationData={{ cde: 'self-repair' }}
                itemData={itemData}
                changeSelfRepairRate={this.changeSelfRepairRate}
                changeHourPrice={this.changeHourPrice}
              />
              {' / H)'}
            </span>
          ) : (
            <span className="font-italic">
              {itemData.qualification.label} (
              <QualificationPrice
                qualificationData={qualification}
                itemData={itemData}
                changeSelfRepairRate={this.changeSelfRepairRate}
                changeHourPrice={this.changeHourPrice}
              />
              {' / H)'}
            </span>
          )}
        </div>
      </div>,
      <div className="row no-gutters pl-2" key={`item-workface-${itemData.id}`}>
        <div className="col-12 font-weight-bold">
          {t('mission.calculation.detailPackage.workforceLabel')}
        </div>
      </div>,
      <div className="row no-gutters pl-2" key={`item-workforce-input-${itemData.id}`}>
        <div className="col-8">
          <span className="font-italic item-padding">
            {t('mission.calculation.detailPackage.durationLabel')}
            <span>
              {itemData.qtUnit} min <Unit unit={itemData.unit} />
            </span>
          </span>
        </div>

        <div className="col-3 d-none d-md-block col-md-4 col-lg-3 text-right">
          <InputPrice
            name="overrideQualificationPrice"
            price={itemData.qualificationPrice}
            classNamesForPrice="font-italic"
            overrode={itemData.overrodeQualification}
            onChange={this.onChange}
            disabled={!itemData.piActive}
            showUnit={itemData.unit !== 'F'}
            unit={itemData.unit}
            overridable
            disableShowUnit={false}
            changed={itemData.overrideQualificationPrice !== null}
          />
        </div>
        <div className="col-1 d-none d-lg-block d-xl-block" />
      </div>,
    ]
  }
}

export default withTranslation()(observer(QualificationRow))
