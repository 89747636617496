import React from 'react'
import { inject, observer } from 'mobx-react'
import classNames from 'classnames'

import InputPrice from 'components/InputPrice/InputPrice'
import { euro } from 'utils/currency'

const threshold = total => {
  if (total < 0) return 0
  return total
}

const Franchise = ({
  onChange,
  totalColumnImmediate,
  totalColumnDeffered,
  totalColumnTotal,
  totalColumnPresented,
  CartStore: {
    compensatoryPackages,
    instructions: { franchise },
  },
  InstructionCtrl: { isFranchiseMustBeDeducted, franchiseMustBeDeducted },
}) => {
  if (isFranchiseMustBeDeducted) {
    totalColumnImmediate = totalColumnImmediate - franchise.immediate
    totalColumnTotal = totalColumnTotal - franchise.immediate
    totalColumnPresented = totalColumnPresented - franchise.immediate
  }

  return (
    <React.Fragment>
      <tr>
        <td className="text-center align-middle">Franchise à déduire</td>
        <td
          className={classNames('text-center align-middle', {
            'table-active': !isFranchiseMustBeDeducted,
          })}
        >
          {isFranchiseMustBeDeducted && (
            <span>
              -
              <InputPrice
                name="franchise.immediate"
                price={franchise.immediate}
                onChange={onChange}
                width={80}
              />
            </span>
          )}
        </td>
        <td className="text-center align-middle table-active"></td>
        <td className="text-center align-middle table-active"></td>
        <td
          className={classNames('text-center align-middle', {
            'table-active': !isFranchiseMustBeDeducted,
          })}
        >
          {isFranchiseMustBeDeducted && <span>-{euro(franchise.immediate)}</span>}
        </td>
        <td
          className={classNames('text-center align-middle', {
            'table-active':
              !isFranchiseMustBeDeducted || franchiseMustBeDeducted === 'NON_RECUPERABLE',
          })}
        >
          {isFranchiseMustBeDeducted && franchiseMustBeDeducted !== 'NON_RECUPERABLE' && (
            <span>
              {franchiseMustBeDeducted === 'TO_REPAY' ? '+' : '-'}
              {euro(franchise.immediate)}
            </span>
          )}
        </td>
      </tr>

      <tr>
        <td className="text-center align-middle font-weight-bold">Total TTC montant Garanti</td>
        <td className="text-center align-middle font-weight-bold">
          {euro(threshold(totalColumnImmediate))}
        </td>
        <td className="text-center align-middle font-weight-bold">{euro(totalColumnDeffered)}</td>
        <td className="text-center align-middle">{euro(compensatoryPackages.total)}</td>
        <td className="text-center align-middle font-weight-bold">
          {euro(threshold(totalColumnTotal))}
        </td>
        <td className="text-center align-middle font-weight-bold">
          {euro(threshold(totalColumnPresented))}
        </td>
      </tr>
    </React.Fragment>
  )
}

export default inject('CartStore', 'InstructionCtrl')(observer(Franchise))
