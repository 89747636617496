import React, { Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { NavLink, useParams } from 'react-router-dom'
import PendingModal from 'pages/ManagerClaim/pages/Pending/PendingModal'

import ButtonWithConfirmation from 'components/ButtonWithConfirmation/ButtonWithConfirmation'

const ManagerClaimDesktop = ({
  CartStore: { easyEstimation },
  ManagerClaimStore: { isClaimClosed, isPending, closeWithNoFurtherAction },
}) => {
  const { t } = useTranslation()
  const { id } = useParams()

  return (
    <Fragment>
      <div className="nav flex-column nav-pills">
        <NavLink className="nav-link" activeClassName="active" to={`/managerClaim/${id}/policy`}>
          {t('mission.sideNav.policy')}
        </NavLink>
      </div>
      <h5 className="mt-4 mb-4">{t('mission.sideNav.titleAnnexe')}</h5>
      <div className="nav flex-column nav-pills">
        <NavLink
          className="nav-link"
          activeClassName="active"
          to={`/managerClaim/${id}/partyInvolved`}
        >
          {t('mission.sideNav.partyInvolved')}
        </NavLink>

        <NavLink
          className="nav-link"
          activeClassName="active"
          to={`/managerClaim/${id}/supportingDocuments`}
        >
          {t('mission.sideNav.supportingDocument')}
        </NavLink>
      </div>

      <h5 className="mt-4 mb-4">{t('mission.sideNav.sinister')}</h5>
      <div className="nav flex-column nav-pills">
        <NavLink
          className="nav-link"
          activeClassName="active"
          to={`/managerClaim/${id}/declaration`}
        >
          {t('mission.sideNav.sinisterDeclaration')}
        </NavLink>
        <NavLink
          className="nav-link"
          activeClassName="active"
          to={`/managerClaim/${id}/calculation`}
        >
          {t('mission.sideNav.calculation')}
        </NavLink>
        {!easyEstimation && (
          <NavLink
            className="nav-link"
            activeClassName="active"
            to={`/managerClaim/${id}/ventilation`}
          >
            {t('mission.sideNav.ventilationREN')}
          </NavLink>
        )}
        <NavLink
          className="nav-link"
          activeClassName="active"
          to={`/managerClaim/${id}/instruction`}
        >
          {t('mission.sideNav.indemnisation')}
        </NavLink>
      </div>

      <h5 className="mt-4 mb-4">{t('managerClaim.sideNav.compensation')}</h5>
      <div className="nav flex-column nav-pills">
        <NavLink
          className="nav-link"
          activeClassName="active"
          to={`/managerClaim/${id}/orientation`}
        >
          {t('managerClaim.sideNav.orientation')}
        </NavLink>
        <NavLink className="nav-link" activeClassName="active" to={`/managerClaim/${id}/report`}>
          {t('managerClaim.sideNav.report')}
        </NavLink>
      </div>

      <h5 className="mt-4 mb-4">{t('mission.sideNav.titleCommunication')}</h5>

      <div className="nav flex-column nav-pills">
        <div className="container-notif">
          <NavLink
            className="nav-link"
            activeClassName="active"
            to={`/managerClaim/${id}/messaging`}
          >
            {t('messaging.title')}
          </NavLink>
          <NavLink className="nav-link" activeClassName="active" to={`/managerClaim/${id}/events`}>
            {t('events.title')}
          </NavLink>
        </div>
      </div>

      {!isClaimClosed && !isPending && <PendingModal />}

      {!isClaimClosed && (
        <div className="mt-4 d-flex justify-content-between">
          <ButtonWithConfirmation
            classNameWrapper="btn-block bg-white"
            className="btn btn-outline-primary btn-block"
            onClick={closeWithNoFurtherAction}
            confirmText={t('mission.report.confirmationCloseMission')}
            text={
              <span>
                Clôture du dossier <i className="fa fa-unlock-alt" />
              </span>
            }
          />
        </div>
      )}
    </Fragment>
  )
}

export default inject('CartStore', 'ManagerClaimStore')(observer(ManagerClaimDesktop))
