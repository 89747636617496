import React, { useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { useParams, useHistory } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

import CardLayout from 'components/CardLayout/CardLayout'
import Form from 'components/DynForm/Form'
import Loader from 'components/Loader/Loader'
import PdfReader from './PdfReader'
import ValidatorModal from 'pages/Mission/pages/Report/ValidatorModal.js'

const Pv = ({
  MissionStore: { isMissionClosed },
  PvCtrl: { loading, form, save, reportContent, generateReport },
}) => {
  const { id } = useParams()
  const history = useHistory()

  useEffect(() => {
    return () => form && form.resetForm()
  }, [form])

  useEffect(() => {
    generateReport(id)
  }, [generateReport, id])

  const nextStep = () => history.push(`/mission/${id}`)

  if (loading || !form) return <Loader />

  return (
    <CardLayout>
      <ValidatorModal />
      <div className="coverage-details-page">
        <Form
          formObj={form}
          nextStep={nextStep}
          saveService={save.bind(this, id)}
          forceEnable={true}
        />
      </div>
      <div className="report-content-wrapper">
        {isMissionClosed && <PdfReader />}
        {reportContent && !isMissionClosed && ReactHtmlParser(reportContent)}
        {!reportContent && <Loader />}
      </div>
    </CardLayout>
  )
}

export default inject('PvCtrl', 'MissionStore')(observer(Pv))
