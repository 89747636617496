import React from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import SmartLink from 'components/SmartLink/SmartLink'

const GenerateReportLink = ({ CartStore: { blockNavigation } }) => {
  const { t } = useTranslation()

  return (
    <SmartLink
      page="generateReport"
      disabled={blockNavigation}
      tooltipDisabled={t('mission.sideNav.tooltipReport')}
    >
      {t('mission.sideNav.generateReport')}
    </SmartLink>
  )
}

export default inject('CartStore')(observer(GenerateReportLink))
