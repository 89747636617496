import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { observer } from 'mobx-react'

import { euro } from 'utils/currency'
import { uid } from 'utils'
import FurnitureCatalog from 'stores/Mission/domain/FurnitureCatalog'
import classNames from 'classnames'

const noop = () => {}

class Feature extends React.Component {
  render() {
    const { feature } = this.props

    return (
      <React.Fragment>
        <dt className="col-6 col-lg-6 text-nowrap">{feature.name}</dt>
        <dd className="col-6 col-lg-4">{`${feature.value ? feature.value : '-'}${
          feature.value && feature.unit ? ` ${feature.unit}` : ''
        }`}</dd>
      </React.Fragment>
    )
  }
}

class FurnitureCatalogDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      displayInformations: false,
    }
    this.toggleMoreInformations = this.toggleMoreInformations.bind(this)
  }

  toggleMoreInformations = e => {
    e.preventDefault()
    e.stopPropagation()
    this.setState(state => {
      return {
        ...state,
        displayInformations: !state.displayInformations,
      }
    })
  }

  render() {
    const { t, className, furniture, isParentProduct, onClick } = this.props
    const { displayInformations } = this.state
    const hasMoreInformations = furniture.featuresByGroup.length > 0

    return (
      <div className={classNames(className, 'furniture row mr-0')} onClick={onClick}>
        <div className="col-4 col-lg-3 pl-0">
          <img src={furniture.image} className="img-fluid img-thumbnail" alt={furniture.toString} />
        </div>
        <div className="col-8 col-lg-9 pl-0 pr-0">
          <dl className="row">
            {isParentProduct && (
              <React.Fragment>
                <dt className="col-6 col-lg-6">{t('mission.calculation.furniture.acquisition')}</dt>
                <dd className="col-6 col-lg-6">
                  {furniture.modalityAcquisition
                    ? t(
                        `mission.calculation.furniture.modalityAcquisition.${furniture.modalityAcquisition}`,
                      )
                    : '-'}
                </dd>
                <dt className="col-6 col-lg-6">
                  {t('mission.calculation.furniture.dateOfPurchased')}
                </dt>
                <dd className="col-6 col-lg-6 text-nowrap">
                  {furniture.dateOfPurchased
                    ? format(furniture.dateOfPurchased, t('common.dateFormat'))
                    : '-'}
                </dd>
              </React.Fragment>
            )}
            <dt className="col-6 col-lg-6">{t('mission.calculation.furniture.brand')}</dt>
            <dd className="col-6 col-lg-6">{furniture.brand}</dd>
            <dt className="col-6 col-lg-6">{t('mission.calculation.furniture.model')}</dt>
            <dd className="col-6 col-lg-6">{furniture.model}</dd>
            <dt className="col-6 col-lg-6">{t('mission.calculation.furniture.releaseYear')}</dt>
            <dd className="col-6 col-lg-6">{furniture.releaseYear}</dd>
            <dt className="col-6 col-lg-6">{t('mission.calculation.furniture.releasePrice')}</dt>
            <dd className="col-6 col-lg-6 text-nowrap">
              {euro(furniture.releasePrice)}{' '}
              {furniture.releaseYear &&
                `${t('mission.calculation.furniture.relaseAt')} ${furniture.releaseYear}`}
            </dd>
            <dt className="col-6 col-lg-6 text-nowrap">
              {t('mission.calculation.furniture.group')}
            </dt>
            <dd className="col-6 col-lg-4">{furniture.group}</dd>
          </dl>
          {hasMoreInformations && (
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={this.toggleMoreInformations}
            >
              {t(
                `mission.calculation.furniture.${
                  displayInformations ? 'displayLess' : 'displayMore'
                }`,
              )}
              &nbsp;
              <i
                className={classNames('fa', {
                  'fa-caret-up': displayInformations,
                  'fa-caret-down': !displayInformations,
                })}
              />
            </button>
          )}
        </div>
        {hasMoreInformations && (
          <div className="row">
            <div className="col-4 col-lg-3 pl-0"></div>
            <div className="col-8 col-lg-9 pl-0 pr-0">
              <dl
                className={classNames('row', 'pt-3', {
                  'd-none': !displayInformations,
                  'd-flex': displayInformations,
                })}
              >
                {furniture.featuresByGroup.map(group => {
                  return (
                    <React.Fragment key={uid()}>
                      {group.features.map(feature => {
                        return <Feature key={uid()} feature={feature} />
                      })}
                    </React.Fragment>
                  )
                })}
              </dl>
            </div>
          </div>
        )}
        {furniture.storeProducts.length > 0 && (
          <div className="row col-12">
            <div className="col-12">{t('mission.calculation.furniture.stores')}</div>
            <div className="col-4 col-lg-3 pl-0"></div>
            <div className="col-8 col-lg-9 pl-4 pr-0">
              <dl className="row pt-3 d-flex">
                {furniture.storeProducts.map(store => {
                  return (
                    <div className="col-md-4 col-6 mb-3" key={uid(5)}>
                      <a href={store.storeUrl} target="_blank" rel="noopener noreferrer">
                        <img src={store.storeImageUrl} alt={store.storeName}></img>
                      </a>
                      {'  '}
                      {euro(store.price)}
                    </div>
                  )
                })}
              </dl>
            </div>
          </div>
        )}
      </div>
    )
  }
}

FurnitureCatalogDetails.defaultProps = {
  onClick: noop,
  isParentProduct: false,
}

FurnitureCatalogDetails.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  furniture: PropTypes.instanceOf(FurnitureCatalog).isRequired,
  isParentProduct: PropTypes.bool.isRequired,
}

export default withTranslation()(observer(FurnitureCatalogDetails))
