import React from 'react'
import { inject, observer } from 'mobx-react'
import { isNil, sum } from 'ramda'

import InputPrice from 'components/InputPrice/InputPrice'

import CompensatoryPackage from './CompensatoryPackage'
import Franchise from './Franchise'
import { euro } from 'utils/currency'
import { computeInstructionsRules } from 'utils/settlement'
import TotalPriceWarning from './TotalPriceWarning'

const verifyValue = (overrideValue, value) => (isNil(overrideValue) ? value : overrideValue)

const Settlements = ({
  CartStore: {
    packages,
    instructions: {
      ren,
      propertyAndEmbellishment,
      selfRepair,
      furniture,
      leak,
      relatedCost,
      negotiation,
    },
    compensatoryPackages,
    setInstructions,
    // SELF REPAIR
    totalSelfRepair,
    // REN
    totalPackagesWithRen,
    // FURNITURE
    totalFurniturePrice,
    // LEAK
    leakTotalPriceCatalog,
    // RC
    totalRelatedCostsAnnexPriceWithVAT,
  },
}) => {
  const onChange = e => {
    setInstructions(e.target.name, e.target.value === '' ? 0 : parseFloat(e.target.value), true)
  }

  // PROPERTY AND EMBELLISHMENT
  const totalPropertyAndEmbellishment = packages.reduce((acc, packageData) => {
    if (!packageData.ren && !packageData.selfRepair && packageData.packageType !== 'DAT_LEAK')
      return acc + packageData.finalPrice
    return acc
  }, 0)
  const totalWithoutObsWithoutVAT = packages.reduce((acc, packageData) => {
    if (!packageData.ren && !packageData.selfRepair)
      return acc + packageData.quantityOriginalTotalPriceCatalog
    return acc
  }, 0)
  const totalCatalogORWithoutVAT = packages.reduce((acc, packageData) => {
    if (!packageData.ren && !packageData.selfRepair)
      return acc + packageData.totalCatalogORWithoutVAT
    return acc
  }, 0)
  const totalWithObsWithoutVAT = totalWithoutObsWithoutVAT + totalCatalogORWithoutVAT

  const propertyAndEmbellishmentRules = computeInstructionsRules(
    totalPropertyAndEmbellishment,
    totalWithObsWithoutVAT,
  )

  // TOTALS
  // IMMEDIATE
  const selfRepairImmediate = verifyValue(selfRepair.immediate, totalSelfRepair)
  const propertyAndEmbellishmentImmediate = verifyValue(
    propertyAndEmbellishment.immediate,
    propertyAndEmbellishmentRules.immediate,
  )
  const furnitureImmediate = verifyValue(furniture.immediate, totalFurniturePrice)
  const relatedCostImmediate = verifyValue(
    relatedCost.immediate,
    totalRelatedCostsAnnexPriceWithVAT,
  )

  const totalColumnImmediate = sum([
    selfRepairImmediate,
    ren.immediate,
    propertyAndEmbellishmentImmediate,
    furnitureImmediate,
    leak.immediate,
    relatedCostImmediate,
    negotiation.immediate,
  ])

  // DEFFERED
  const renDeffered = verifyValue(ren.deffered, totalPackagesWithRen)
  const propertyAndEmbellishmentDeffered = verifyValue(
    propertyAndEmbellishment.deffered,
    propertyAndEmbellishmentRules.deffered,
  )
  const leakDeffered = verifyValue(leak.deffered, leakTotalPriceCatalog)

  const totalColumnDeffered = sum([
    selfRepair.deffered,
    renDeffered,
    propertyAndEmbellishmentDeffered,
    leakDeffered,
    relatedCost.deffered,
  ])

  // TOTAL
  const selfRepairTotal = selfRepairImmediate + selfRepair.deffered
  const renTotal = ren.immediate + renDeffered
  const furnitureTotal = furnitureImmediate
  const leakTotal = leak.immediate + leakDeffered
  const relatedCostTotal = relatedCostImmediate + relatedCost.deffered

  const totalColumnTotal = sum([
    selfRepairTotal,
    renTotal,
    totalPropertyAndEmbellishment,
    furnitureTotal,
    leakTotal,
    relatedCostTotal,
    negotiation.immediate,
    compensatoryPackages.total,
  ])

  // PRESENTED
  const totalColumnPresented = sum([
    ren.presented,
    propertyAndEmbellishment.presented,
    furniture.presented,
    leak.presented,
    relatedCost.presented,
  ])

  return (
    <React.Fragment>
      <table className="table table-bordered d-none d-md-table mt-5">
        <tbody>
          <tr>
            <th className="text-center align-middle">Modalité de Règlement</th>
            <th className="text-center align-middle">Réglement immédiat TTC</th>
            <th className="text-center align-middle">Réglement différé TTC</th>
            <th className="text-center align-middle">Forfait compensatoire TTC</th>
            <th className="text-center align-middle">Total réglement TTC</th>
            <th className="text-center align-middle">Montant TTC du recours à présenter</th>
          </tr>

          {/* SELF REPAIR */}
          <tr>
            <td className="text-center align-middle">GAG - Auto-réparation</td>
            <td className="text-center align-middle">
              <InputPrice
                name="selfRepair.immediate"
                price={selfRepairImmediate}
                onChange={onChange}
                width={80}
              />
            </td>
            <td className="text-center align-middle">
              <InputPrice
                name="selfRepair.deffered"
                price={selfRepair.deffered}
                onChange={onChange}
                width={80}
              />
            </td>
            <CompensatoryPackage type="selfRepair" trueTotal={totalSelfRepair} />
            <td className="text-center align-middle table-active"></td>
          </tr>

          {/* REN */}
          <tr>
            <td className="text-center align-middle">Réparation en Nature</td>
            <td className="text-center align-middle">
              <InputPrice
                name="ren.immediate"
                price={ren.immediate}
                onChange={onChange}
                width={80}
              />
            </td>
            <td className="text-center align-middle">
              <InputPrice name="ren.deffered" price={renDeffered} onChange={onChange} width={80} />
            </td>
            <CompensatoryPackage type="ren" trueTotal={totalPackagesWithRen} />
            <td className="text-center align-middle">
              <InputPrice
                name="ren.presented"
                price={ren.presented}
                onChange={onChange}
                width={80}
              />
            </td>
          </tr>

          {/* PROPERTY + EMBELLISHMENT */}
          <tr>
            <td className="text-center align-middle">Indémnités pécuniaires</td>
            <td className="text-center align-middle">
              <InputPrice
                name="propertyAndEmbellishment.immediate"
                price={propertyAndEmbellishmentImmediate}
                onChange={onChange}
                width={80}
              />
            </td>
            <td className="text-center align-middle">
              <InputPrice
                name="propertyAndEmbellishment.deffered"
                price={propertyAndEmbellishmentDeffered}
                onChange={onChange}
                width={80}
              />
            </td>
            <CompensatoryPackage
              type="propertyAndEmbellishment"
              trueTotal={totalPropertyAndEmbellishment}
            />
            <td className="text-center align-middle">
              <InputPrice
                name="propertyAndEmbellishment.presented"
                price={propertyAndEmbellishment.presented}
                onChange={onChange}
                width={80}
              />
            </td>
          </tr>

          {/* FURNITURE */}
          <tr>
            <td className="text-center align-middle">Mobilier</td>
            <td className="text-center align-middle">
              <InputPrice
                name="furniture.immediate"
                price={furnitureImmediate}
                onChange={onChange}
                width={80}
              />
            </td>
            <td className="text-center align-middle table-active"></td>
            <td className="text-center align-middle table-active"></td>
            <td className="text-center align-middle">
              <TotalPriceWarning total={furnitureImmediate} trueTotel={totalFurniturePrice} />
            </td>
            <td className="text-center align-middle">
              <InputPrice
                name="furniture.presented"
                price={furniture.presented}
                onChange={onChange}
                width={80}
              />
            </td>
          </tr>

          {/* LEAK */}
          <tr>
            <td className="text-center align-middle">Recherche de fuite</td>
            <td className="text-center align-middle">
              <InputPrice
                name="leak.immediate"
                price={leak.immediate}
                onChange={onChange}
                width={80}
              />
            </td>
            <td className="text-center align-middle">
              <InputPrice
                name="leak.deffered"
                price={leakDeffered}
                onChange={onChange}
                width={80}
              />
            </td>
            <td className="text-center align-middle table-active"></td>
            <td className="text-center align-middle">
              <TotalPriceWarning total={leakTotal} trueTotal={leakTotalPriceCatalog} />
            </td>
            <td className="text-center align-middle">
              <InputPrice
                name="leak.presented"
                price={leak.presented}
                onChange={onChange}
                width={80}
              />
            </td>
          </tr>

          {/* RELATED COST */}
          <tr>
            <td className="text-center align-middle">Frais annexes</td>
            <td className="text-center align-middle">
              <InputPrice
                name="relatedCost.immediate"
                price={relatedCostImmediate}
                onChange={onChange}
                width={80}
              />
            </td>
            <td className="text-center align-middle">
              <InputPrice
                name="relatedCost.deffered"
                price={relatedCost.deffered}
                onChange={onChange}
                width={80}
              />
            </td>
            <td className="text-center align-middle table-active"></td>
            <td className="text-center align-middle">
              <TotalPriceWarning
                total={relatedCostTotal}
                trueTotal={totalRelatedCostsAnnexPriceWithVAT}
              />
            </td>
            <td className="text-center align-middle">
              <InputPrice
                name="relatedCost.presented"
                price={relatedCost.presented}
                onChange={onChange}
                width={80}
              />
            </td>
          </tr>

          {/* NEGOTIATION */}
          <tr>
            <td className="text-center align-middle">Négociation</td>
            <td className="text-center align-middle">
              <InputPrice
                name="negotiation.immediate"
                price={negotiation.immediate}
                onChange={onChange}
                width={80}
              />
            </td>
            <td className="text-center align-middle table-active"></td>
            <td className="text-center align-middle table-active"></td>
            <td className="text-center align-middle">{euro(negotiation.immediate)}</td>
            <td className="text-center align-middle table-active"></td>
          </tr>

          <tr>
            <td className="text-center align-middle">Total TTC</td>
            <td className="text-center align-middle">{euro(totalColumnImmediate)}</td>
            <td className="text-center align-middle">{euro(totalColumnDeffered)}</td>
            <td className="text-center align-middle">{euro(compensatoryPackages.total)}</td>
            <td className="text-center align-middle">{euro(totalColumnTotal)}</td>
            <td className="text-center align-middle">{euro(totalColumnPresented)}</td>
          </tr>

          <Franchise
            onChange={onChange}
            totalColumnImmediate={totalColumnImmediate}
            totalColumnDeffered={totalColumnDeffered}
            totalColumnTotal={totalColumnTotal}
            totalColumnPresented={totalColumnPresented}
          />
        </tbody>
      </table>
    </React.Fragment>
  )
}

export default inject('CartStore', 'ManagerClaimStore')(observer(Settlements))
