import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import classNames from 'classnames'
import { compose } from 'recompose'
import { isNil } from 'ramda'

import './AddFurniture.css'
import CardLayout from 'components/CardLayout/CardLayout'
import AddFurnitureCtrl from 'stores/Mission/view/AddFurnitureCtrl'
import SearchFurnitureCtrl from 'stores/Mission/view/SearchFurnitureCtrl'
import Breadcrumb from 'pages/Mission/pages/Calculation/Breadcrumb/Breadcrumb'
import Input from 'components/Forms/Input'
import TextArea from 'components/Forms/TextArea'
import Select from 'components/Forms/Select'
import DatePicker from 'components/Forms/DatePicker'
import SupportingDocumentLite from 'pages/Mission/pages/Calculation/SupportingDocumentLite'
import LastUpdatedAt from 'pages/Mission/components/LastUpdatedAt'
import Recap from './Recap'
import FurnitureCatalogDetails from 'pages/Mission/pages/Calculation/FurnitureCatalogDetails'
import ReplacementProductsModal from 'pages/Mission/pages/Calculation/AddFurniture/ReplacementProductsModal'
import Label from 'components/Label/Label'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'
import SelectInvolvedParty from 'pages/Mission/pages/Calculation/SelectInvolvedParty'
import Toggle from 'components/Toggle/Toggle'

class AddFurniture extends React.Component {
  goToCart = () => {
    const {
      history,
      match: {
        params: { id, type },
      },
    } = this.props
    history.push(`/${type}/${id}/calculation/listPackage`)
  }

  onChange = e => {
    AddFurnitureCtrl.furniture.setProperty(e.target.name, e.target.value)
    if (e.target.name === 'category' || e.target.name === 'compensation') this.checkObs()
  }
  onChangeRoom = e => {
    const room = SearchFurnitureCtrl.rooms.find(room => room.cde === e.target.value)
    if (room) AddFurnitureCtrl.furniture.setProperty('roomType', room)
  }

  onChangeDate = date => {
    AddFurnitureCtrl.furniture.setProperty('dateOfPurchased', date)
    this.checkObs()
  }

  checkObs = () => {
    if (process.env.REACT_APP_SOLERA_ENV === 'PROD') return

    const { dateOfPurchased, category, compensation } = AddFurnitureCtrl.furniture
    if (!dateOfPurchased || !category) return

    const obs = AddFurnitureCtrl.checkObs(dateOfPurchased, category, compensation)
    if (!isNil(obs)) {
      AddFurnitureCtrl.furniture.setProperty('correction', obs)
      AddFurnitureCtrl.furniture.setProperty('obsRateFoundInDatabase', true)
    } else {
      AddFurnitureCtrl.furniture.setProperty('obsRateFoundInDatabase', false)
    }
  }

  addFurnitureToCart = furniture => {
    const { id, type } = this.props.match.params
    this.props.CartStore.addFurniture(furniture)
    this.props.history.push(`/${type}/${id}/calculation/listPackage`)
  }
  addSupportingDocument = id => AddFurnitureCtrl.furniture.addSupportingDocument(id)

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const { furniture, context } = AddFurnitureCtrl
    const {
      match: {
        params: { id, type: urlType },
        path,
      },
      CommonStore: { furnitureCategoriesOptions },
      CartStore: { isVentilationIPAvailable },
    } = this.props
    if (furniture === null) return <Redirect to={`/${urlType}/${id}/calculation/listPackage`} />

    const last = path.split('/').pop()
    const outdoorCondition = last === 'addOutdoorFurniture'

    const { t } = this.props
    const {
      type,
      description,
      model,
      brand,
      characteristics,
      modalityAcquisition,
      dateOfPurchased,
      validFurniture,
      supportingDocuments,
      parentProduct,
      location,
      roomType,
      category,
      hasNoSupportingDocuments,
      involvedParty,
    } = furniture
    const roomsForSelect = SearchFurnitureCtrl.roomsForSelect.filter(
      room => room.value !== 'noFilter',
    )

    const acquisitionOptions = {
      new: t('mission.calculation.furniture.modalityAcquisition.new'),
      used: t('mission.calculation.furniture.modalityAcquisition.used'),
    }

    return (
      <CardLayout className="add-furniture">
        <div className="container pl-0 pr-0">
          <div className="row no-gutters mb-3">
            <div className="col-10 col-sm-10 col-md-9">
              <Breadcrumb />
            </div>
            <div className="col-2 col-md-3 text-right">
              <button className="btn btn-outline-primary" onClick={this.goToCart}>
                <i className="fa fa-calculator" />
                <span className="d-none d-none-sm d-none-md d-lg-inline">
                  &nbsp;
                  {t('mission.calculation.detailPackage.backToCart')}
                </span>
              </button>
            </div>
          </div>

          <div className="row no-gutters mb-3">
            <div className="col-4">
              <Label required>{t('mission.calculation.furniture.category')}</Label>
              <ResponsiveSelect
                name="category"
                value={category}
                options={furnitureCategoriesOptions}
                onChange={this.onChange}
                placeholder={t('mission.calculation.furniture.selectCategory')}
                isSearchable={true}
              />
            </div>

            {outdoorCondition && (
              <div className="col-4 pl-2">
                <Label>{t('mission.calculation.searchPackage.room')}</Label>
                <ResponsiveSelect
                  name="roomType"
                  onChange={this.onChangeRoom}
                  value={roomType ? roomType.cde : 'selectFilter'}
                  options={roomsForSelect}
                />
              </div>
            )}

            <div
              className={classNames({ 'col-4': outdoorCondition, 'col-8': !outdoorCondition })}
            />
          </div>

          {isVentilationIPAvailable && (
            <div className="row no-gutters mb-3">
              <div className="col-4">
                <Label>{t('mission.calculation.involvedParty.concerned')}</Label>
                <SelectInvolvedParty onChange={this.onChange} value={involvedParty} />
              </div>
            </div>
          )}

          {type === 'catalog' && parentProduct && (
            <React.Fragment>
              <p className="mb-0">{t('mission.calculation.furniture.originalProduct')}</p>
              <FurnitureCatalogDetails furniture={parentProduct} isParentProduct={true} />
              <hr />
              <p className="mb-0">{t('mission.calculation.furniture.replacementProduct')}</p>
            </React.Fragment>
          )}
          {type === 'catalog' && <FurnitureCatalogDetails furniture={furniture} className="mb-3" />}

          {type === 'manual' && (
            <div className="row no-gutters mb-3">
              <div className="col-12">
                <label>
                  {t('mission.calculation.furniture.description')}
                  <span className="text-danger"> *</span>
                </label>
                <TextArea
                  className="form-control"
                  rows={2}
                  name="description"
                  value={description}
                  onChange={this.onChange}
                  errors={[]}
                  required
                />
              </div>
            </div>
          )}

          {type === 'manual' && (
            <div className="row no-gutters mb-3">
              <div className="col-6 pr-2">
                <label>{t('mission.calculation.furniture.brand')}</label>
                <Input
                  className="form-control"
                  name="brand"
                  value={brand}
                  onChange={this.onChange}
                />
              </div>

              <div className="col-6">
                <label>{t('mission.calculation.furniture.model')}</label>
                <Input
                  className="form-control"
                  name="model"
                  value={model}
                  onChange={this.onChange}
                />
              </div>
            </div>
          )}

          <div className="row no-gutters mb-3">
            <div className="col-12">
              <label>{t('mission.calculation.furniture.characteristics')}</label>
              <TextArea
                className="form-control"
                rows={2}
                name="characteristics"
                value={characteristics}
                onChange={this.onChange}
                errors={[]}
              />
            </div>
          </div>

          <div className="row no-gutters mb-3">
            <div className="col-6 pr-2">
              <label>{t('mission.calculation.furniture.acquisition')}</label>
              <Select
                className="form-control"
                name="modalityAcquisition"
                value={modalityAcquisition}
                options={acquisitionOptions}
                onChange={this.onChange}
              />
            </div>

            <div className="col-6">
              <label>{t('mission.calculation.furniture.dateOfPurchased')}</label>
              <DatePicker
                name="dateOfPurchased"
                className="form-control"
                onChange={this.onChangeDate}
                selected={dateOfPurchased}
                maxDate={new Date()}
              />
            </div>
          </div>

          <div className="row no-gutters mb-3">
            <div className="col-6 pr-2">
              <div className="row no-gutters mb-3">
                <div className="col-4">
                  <label>{t('mission.calculation.furniture.haNoSupportingDocument')}</label>
                </div>
                <div className="col-8">
                  <Toggle
                    name="hasNoSupportingDocuments"
                    checked={hasNoSupportingDocuments}
                    onChange={this.onChange}
                  />
                </div>
              </div>

              {!hasNoSupportingDocuments && (
                <div className="row no-gutters mb-3">
                  <div className="col-12">
                    <SupportingDocumentLite
                      supportingDocuments={supportingDocuments}
                      addSupportingDocument={this.addSupportingDocument}
                      btnTypes={['IINV', 'DOTH']}
                      uploadFor="furniture"
                    />
                  </div>
                </div>
              )}
            </div>

            <Recap packageData={furniture} onChange={this.onChange} parentProduct={parentProduct} />
          </div>

          {context === 'new' ? (
            <div className="row justify-content-center">
              <button
                className="btn btn-primary"
                onClick={this.addFurnitureToCart.bind(this, furniture)}
                disabled={!validFurniture}
              >
                {t('mission.calculation.furniture.addFurnitureBtn')}{' '}
                {t(`mission.calculation.furniture.${location}`)}
              </button>
            </div>
          ) : null}

          {context === 'new' ? null : <LastUpdatedAt />}
        </div>
        <ReplacementProductsModal />
      </CardLayout>
    )
  }
}

export default compose(
  inject('CartStore', 'CommonStore'),
  withTranslation(),
  observer,
)(AddFurniture)
