import React from 'react'
import { withTranslation } from 'react-i18next'
import { GoogleMap, withGoogleMap, DirectionsRenderer } from 'react-google-maps'

// import AlertCtrl from 'stores/Common/view/AlertCtrl'
import MapCtrl from 'stores/Mission/view/MapCtrl'

export class GoogleMapsWrapper extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      directions: null,
    }
  }

  createRouteDirection(origin, destination) {
    const DirectionsService = new window.google.maps.DirectionsService()

    DirectionsService.route(
      {
        origin: new window.google.maps.LatLng(origin.lat, origin.lng),
        destination: new window.google.maps.LatLng(destination.lat, destination.lng),
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          this.setState({
            directions: result,
          })
          MapCtrl.setItineraryInformations(result)
        } else {
          // AlertCtrl.alert('danger', 'mission.information.map.errorDirection')
          console.warn(this.props.t('mission.information.map.errorDirection'))
        }
      },
    )
  }

  setMapBounds = () => {
    const bounds = new window.google.maps.LatLngBounds()
    if (this.props.markers) {
      this.props.markers.forEach(marker => {
        const { lat, lng } = marker
        bounds.extend(new window.google.maps.LatLng(lat, lng))
      })

      const { lat, long } = this.props.destination
      bounds.extend(new window.google.maps.LatLng(lat, long))
      return bounds
    }
    return null
  }

  checkGeometry = geometry => geometry && geometry.lat && (geometry.long || geometry.lng)

  componentDidMount() {
    const { destination } = this.props

    if (this.props.latitude && this.props.longitude) {
      this.createRouteDirection(
        { lat: this.props.latitude, lng: this.props.longitude },
        { lat: destination.lat, lng: destination.long },
      )
    }
  }

  componentDidUpdate(prevProps) {
    const { assignee, destination } = this.props
    const prevAssignee = prevProps.assignee
    if (
      this.checkGeometry(assignee) &&
      this.checkGeometry(destination) &&
      (prevAssignee === null ||
        (assignee.lat !== prevAssignee.lat && prevAssignee.lng !== assignee.lng))
    ) {
      this.createRouteDirection(
        { lat: assignee.lat, lng: assignee.lng },
        { lat: destination.lat, lng: destination.long },
      )
    }
  }

  render() {
    const bounds = this.setMapBounds()

    return (
      <GoogleMap
        ref={c => {
          c && bounds && c.fitBounds(bounds)
        }}
        {...this.props}
      >
        {this.props.children}
        {this.state.directions && (
          <DirectionsRenderer
            directions={this.state.directions}
            options={{ suppressMarkers: true }}
          />
        )}
      </GoogleMap>
    )
  }
}

export default withTranslation()(withGoogleMap(GoogleMapsWrapper))
