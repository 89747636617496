import React from 'react'
import { withRouter } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'

import SupportingDocumentsCtrl from 'stores/Common/view/SupportingDocumentsCtrl'
import UploadCtrl from 'stores/Common/view/UploadCtrl'
import Filters from './Filters'
import List from './List'
import SliderModal from './SliderModal'
import UploadDocumentModal from 'components/UploadDocumentModal/UploadDocumentModal'
import DeleteModal from './DeleteModal'
import './SupportingDocuments.css'
import FiltersDropZone from './FiltersDropZone'
import FiltersDropZoneUpdateType from './FiltersDropZoneUpdateType'
import DrawModalMobile from './Draw/DrawModalMobile'
import DrawModal from './Draw/DrawModal'

class SupportingDocument extends React.Component {
  state = {
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    eventListener: false,
  }

  UNSAFE_componentWillMount() {
    UploadCtrl.setProperty('context', 'supportingDocument')
  }

  componentDidMount() {
    document.addEventListener('dragover', e => this.onDragOver(e), true)
    document.addEventListener('dragleave', e => this.onDragLeave(e), true)
  }

  componentWillUnmount() {
    document.removeEventListener('dragover', e => this.onDragOver(e), true)
    document.removeEventListener('dragleave', e => this.onDragLeave(e), true)
    SupportingDocumentsCtrl.resetDatas()
    SupportingDocumentsCtrl.resetToDefault()
  }

  onDragOver = e => {
    if (this.props.MissionStore.isMissionClosed || this.props.ClaimStore.isClaimClosed) return
    const { dropZoneFiltered, dropZoneFilteredUpdateType } = SupportingDocumentsCtrl
    if (!dropZoneFiltered && !dropZoneFilteredUpdateType)
      SupportingDocumentsCtrl.setDropZoneFiltered(true)
    e.preventDefault()
  }

  onDragLeave = e => {
    if (this.props.MissionStore.isMissionClosed || this.props.ClaimStore.isClaimClosed) return
    const isLeaveDragInsidePage = (width, height) => {
      return (
        (width < 10 || width > this.state.windowWidth - 10) &&
        (height < 10 || height > this.state.windowHeight - 10)
      )
    }
    if (SupportingDocumentsCtrl.dropZoneFiltered && isLeaveDragInsidePage(e.clientX, e.clientY)) {
      SupportingDocumentsCtrl.setDropZoneFiltered(false)
    }
  }

  render() {
    const { id } = this.props.match.params
    const { UiCtrl, noWrapBorder, withFilters } = this.props
    const { isCreateMissionSelector } = SupportingDocumentsCtrl

    return (
      <div
        className={classNames('supporting-documents container', {
          'supporting-documents-select': isCreateMissionSelector === true,
          'supporting-documents-no-border': noWrapBorder,
        })}
      >
        <Filters withFilters={withFilters} />
        <FiltersDropZone />
        <FiltersDropZoneUpdateType />
        {!SupportingDocumentsCtrl.dropZoneFiltered &&
          !SupportingDocumentsCtrl.dropZoneFilteredUpdateType && <List />}
        <SliderModal />
        <UploadDocumentModal wan={id} />
        <DeleteModal />
        {UiCtrl.deviceType === 'mobile' ? <DrawModalMobile /> : <DrawModal />}
      </div>
    )
  }
}

SupportingDocument.defaultProps = { noWrapBorder: false, withFilters: true }

export default inject('MissionStore', 'ClaimStore', 'UiCtrl')(
  withRouter(withTranslation()(observer(SupportingDocument))),
)
