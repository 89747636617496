import request from './httpRequest'

export const startNewSession = async (wan, phoneNumber, email, wanType = 'cfa') => {
  const res = await request({
    method: 'POST',
    url: `/conference/v1/${wan}/new-session-${wanType}`,
    data: {
      data: {
        type: 'conference',
        attributes: {
          phoneNumber,
          email,
        },
      },
    },
  })

  return res.data.attributes
}
