import React, { useState } from 'react'
import classNames from 'classnames'
import { matchPath } from 'react-router'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { observer, inject } from 'mobx-react'
import { compose } from 'recompose'
import { add, isNil } from 'ramda'

import './CalculationBar.css'
import { setDataLocal, getDataLocal } from 'stores/localStorage'
import { euro } from 'utils/currency'
import { packagesPrice, furnituresPrice, outdoorPackagesPrice, relatedCostsPrice } from 'utils/cart'

const CalculationBar = ({
  t,
  location: { pathname },
  MissionStore: { isIRSI, selectedIRSICart, insuredInformationId },
  CartStore: {
    // FOR TOTAL
    packages,
    furnitures,
    relatedCostsAnnex,
    noGuarantees,
    overflows,
    // FOR TOTAL BY SELECTED PACKAGES
    propertyPackagesByRoom,
    embellishmentPackagesByRoom,
    regularFurnitures,
    leakPackagesByRoom,
    outdoorPackagesByRoom,
    relatedCostPackages,
    overflowPackages,
    noGuaranteePackages,
  },
  PartyInvolvedStore: { findName },
}) => {
  const [collapsed, setCollapsed] = useState(Boolean(getDataLocal('calculationBarCollapsed')))

  const matchMission = matchPath(pathname, {
    path: '/mission/:id',
    exact: false,
    strict: false,
  })

  const matchCFM = matchPath(pathname, {
    path: '/managerClaim/:id',
    exact: false,
    strict: false,
  })

  if (isNil(matchMission) && isNil(matchCFM)) return null

  // COMPUTATION FOR TOTAL BY SELECTED PACKAGES
  const filterIRSI = data => {
    if (isIRSI) return data.involvedParty === selectedIRSICart
    return true
  }

  // COMPUTATION FOR TOTAL
  // FOR CATALOG
  const totalPackageCatalog = packages
    .filter(filterIRSI)
    .reduce((acc, data) => add(acc, data.finalPriceCatalog), 0)
  const totalFurnitureCatalog = furnitures
    .filter(filterIRSI)
    .reduce((acc, data) => add(acc, data.finalPrice), 0)
  const totalRCCatalog = relatedCostsAnnex
    .filter(filterIRSI)
    .reduce((acc, data) => add(acc, data.priceWithVAT), 0)
  const totalOverflowCatalog = overflows
    .filter(filterIRSI)
    .reduce((acc, data) => add(acc, data.priceWithVAT), 0)
  const totalNoGuaranteeCatalog = noGuarantees
    .filter(filterIRSI)
    .reduce((acc, data) => add(acc, data.priceWithVAT), 0)
  const totalCatalog =
    totalPackageCatalog +
    totalFurnitureCatalog +
    totalRCCatalog +
    totalOverflowCatalog +
    totalNoGuaranteeCatalog

  // FOR SELF REPAIR
  const totalPackageSelfRepair = packages.filter(filterIRSI).reduce((acc, data) => {
    if (data.packageType === 'DAT_LEAK') return acc
    return add(acc, data.finalPriceSelfRepair)
  }, 0)

  // CATALOG
  const totalProperty = packagesPrice(propertyPackagesByRoom)
  const totalEmbellishement = packagesPrice(embellishmentPackagesByRoom)
  const totalFurniture = furnituresPrice(regularFurnitures.filter(filterIRSI))
  const totalLeak = packagesPrice(leakPackagesByRoom)
  const totalOutdoor = outdoorPackagesPrice(outdoorPackagesByRoom)
  const totalRC = relatedCostsPrice(relatedCostPackages.filter(filterIRSI), 'relatedCost')
  let totalOverflow = relatedCostsPrice(overflowPackages)
  if (isIRSI && selectedIRSICart === insuredInformationId) {
    totalOverflow = relatedCostsPrice(overflowPackages.filter(filterIRSI), 'overflow')
  } else if (isIRSI) {
    totalOverflow = 0
  }
  let totalNoGuarantee = relatedCostsPrice(noGuaranteePackages)
  if (isIRSI && selectedIRSICart === insuredInformationId) {
    totalNoGuarantee = relatedCostsPrice(noGuaranteePackages.filter(filterIRSI), 'noGuarantee')
  } else if (isIRSI) {
    totalNoGuarantee = 0
  }

  const total =
    totalProperty +
    totalEmbellishement +
    totalFurniture +
    totalLeak +
    totalOutdoor +
    totalRC +
    totalOverflow +
    totalNoGuarantee

  // SELF REPAIR
  const totalPropertySelfRepair = packagesPrice(propertyPackagesByRoom, true)
  const totalEmbellishementSelfRepair = packagesPrice(embellishmentPackagesByRoom, true)
  const totalOutdoorSelfRepair = outdoorPackagesPrice(outdoorPackagesByRoom, true)

  const totalSelfRepair =
    totalPropertySelfRepair + totalEmbellishementSelfRepair + totalOutdoorSelfRepair

  return (
    <footer
      className={classNames('calculation-bar', {
        'container ': collapsed,
      })}
    >
      <div className="container container-toggle">
        <span
          className="toggle position-absolute"
          onClick={() => {
            setDataLocal('calculationBarCollapsed', !collapsed)
            setDataLocal('firstTimeCalculationBar', false)
            setCollapsed(!collapsed)
          }}
        >
          <i
            className={classNames('fa', {
              'fa-caret-up': collapsed,
              'fa-caret-down': !collapsed,
            })}
          />
        </span>
      </div>
      <nav className={classNames({ hidden: collapsed })}>
        <div className="container p-0">
          <div className="row">
            <div className="col-12 col-md-9 offset-md-3">
              <div className="container calculation-bar-table">
                <div className="row no-gutters">
                  <div className="container">
                    <div className="row no-gutters">
                      <div className="col-3 offset-5 col-lg-2 offset-lg-7 text-right font-weight-bold">
                        {t('mission.calculation.cart.priceCatalog')}
                      </div>
                      <div className="col-3 col-lg-2 text-right font-weight-bold">
                        {t('mission.calculation.cart.selfRepair')}
                      </div>
                    </div>

                    <div className="row no-gutters text-smaller">
                      <div className="col-5 col-lg-7 font-weight-bold">
                        {isIRSI && findName(selectedIRSICart)}
                      </div>
                      <div className="col-3 col-lg-2 text-right text-muted">
                        {euro(totalCatalog)}
                      </div>
                      <div className="col-3 col-lg-2 text-right text-muted">
                        {euro(totalPackageSelfRepair)}
                      </div>
                    </div>

                    <div className="row no-gutters">
                      <div className="col-5 col-lg-7 text-primary font-weight-bold">
                        {t('mission.calculation.cart.finalTotal')}
                      </div>
                      <div className="col-3 col-lg-2 text-right text-primary font-weight-bold">
                        {euro(total)}
                      </div>
                      <div className="col-3 col-lg-2 text-right text-primary font-weight-bold">
                        {euro(totalSelfRepair)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </footer>
  )
}

export default compose(
  inject('MissionStore', 'CartStore', 'PartyInvolvedStore'),
  withTranslation(),
  withRouter,
  observer,
)(CalculationBar)
