import React from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import classNames from 'classnames'

import { noop } from 'utils'
import ReadAt from './ReadAt'
import Attachments from './Attachments'
import Tooltip from 'components/Tooltip/Tooltip'

function Message({
  t,
  match: {
    params: { id: wan },
  },
  data: {
    isSelf,
    text,
    formatCreatedDate,
    formatReadAt,
    markAsRead,
    markingAsRead,
    canMarkAsRead,
    from,
    at,
    type,
    documents,
    isUrgent,
    saveAttachments,
    savingAttachments,
    senderUser,
  },
}) {
  const save = wan => {
    saveAttachments(wan)
  }

  return (
    <div
      className={classNames('message d-inline-block text-left mb-3 border rounded p-2', {
        'bg-other': !isSelf,
        'bg-self': isSelf,
      })}
    >
      <div className="info mb-2">
        {from}{' '}
        {isUrgent && (
          <Tooltip className="d-inline" text={t('messaging.urgentTooltip')}>
            <i className="fa fa-warning text-primary" />
          </Tooltip>
        )}
        {at ? (
          <React.Fragment>
            <br />
            {at}
            <br />
          </React.Fragment>
        ) : (
          <br />
        )}
        {t('messaging.message.date')} : {formatCreatedDate}
      </div>

      <p className="text">{text}</p>

      {documents.length > 0 && (
        <Attachments
          documents={documents}
          onSelectItem={noop}
          canDelete={false}
          showInfo={false}
          thumbnailSize="small"
        />
      )}

      {!isSelf && documents.length > 0 && (
        <div className="d-flex justify-content-end info">
          {savingAttachments ? (
            <i className="fa fa-spinner fa-spin text-primary" />
          ) : (
            <button className="btn btn-link p-0" onClick={save.bind(this, wan)}>
              {t('messaging.message.saveDocument', { count: documents.length })}
            </button>
          )}
        </div>
      )}

      <ReadAt
        isSelf={isSelf}
        type={type}
        from={from}
        formatReadAt={formatReadAt}
        markAsRead={markAsRead}
        markingAsRead={markingAsRead}
        canMarkAsRead={canMarkAsRead}
        senderUser={senderUser}
      />
    </div>
  )
}

export default compose(
  inject('MessagingStore'),
  withRouter,
  withTranslation(),
  observer,
)(Message)
