import React from 'react'
import { inject, observer } from 'mobx-react'
import { compose } from 'recompose'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import MobileDetect from 'mobile-detect'
import { path, isNil } from 'ramda'
import $ from 'jquery'

import { noop } from 'utils'
import windowResize from 'hooks/windowResize'

const localizer = momentLocalizer(moment)
const CalendarDnd = withDragAndDrop(Calendar)

function CalendarWithDnd({
  max,
  min,
  defaultView,
  views,
  view,
  day,
  expert,
  events,
  components,
  toolbar,
  CalendarExpertStore: { viewType },
  AppointmentCtrl: { showNewModal, showEditModal },
  UserStore: { id, isInsurer },
}) {
  const md = new MobileDetect(window.navigator.userAgent)
  const saveEvent = ({ event, start, end }) => {
    event.obj.setProperty('start', start)
    event.obj.setProperty('end', end)
    event.obj.save()
  }
  const selectSlot = slot => {
    if (!isNil(expert)) {
      const isMultiExpert = expert === 'multiExpert'
      showNewModal(
        {
          start: slot.start,
          end: slot.end,
          assignee: isMultiExpert ? null : expert.id,
          assigner: id,
        },
        isMultiExpert ? 'multiExpert' : expert,
      )
    } else console.warn('No expert selected to show new appointmentModal')
  }
  const showColorByExpert = isInsurer && viewType === 'week'
  const isTablet = md.tablet() !== null
  const height = windowResize()
  const responsiveTimeSlot = height >= 720 ? { step: 30, timeslots: 1 } : { step: 30, timeslots: 2 }

  return (
    <CalendarDnd
      tooltipAccessor={null}
      min={min}
      max={max}
      step={responsiveTimeSlot.step}
      timeslots={responsiveTimeSlot.timeslots}
      date={day}
      localizer={localizer}
      events={events}
      defaultView={defaultView}
      views={views}
      view={view}
      onView={noop}
      selectable={true}
      resizable
      toolbar={toolbar}
      onNavigate={noop}
      onEventResize={isTablet ? noop : saveEvent}
      onEventDrop={isTablet ? noop : saveEvent}
      onSelectSlot={selectSlot}
      onSelecting={() => {
        $('.rbc-event.no-color-event.rbc-selected.rbc-addons-dnd-dragged-event').removeClass(
          'rbc-selected rbc-addons-dnd-dragged-event',
        )
        $('.rbc-addons-dnd.rbc-addons-dnd-is-dragging').removeClass(
          'rbc-addons-dnd rbc-addons-dnd-is-dragging',
        )
      }}
      onDoubleClickEvent={
        isTablet
          ? noop
          : event => {
              showEditModal(event.obj, expert)
            }
      }
      onSelectEvent={
        isTablet
          ? event => {
              showEditModal(event.obj, expert)
            }
          : noop
      }
      eventPropGetter={event => {
        return {
          className: showColorByExpert ? 'no-color-event' : event.obj.backgroundColor,
          style: showColorByExpert
            ? {
                color: path(['obj', 'expertColor', 'fontColor'], event) || '',
                background: path(['obj', 'expertColor', 'background'], event) || '',
              }
            : {},
        }
      }}
      components={components}
    />
  )
}

export default compose(
  inject('UserStore', 'CalendarExpertStore', 'AppointmentCtrl'),
  observer,
)(CalendarWithDnd)
