import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { isNil, path } from 'ramda'

import Dropdown from './components/Dropdown'
import SearchLeakCtrl from 'stores/Mission/view/SearchLeakCtrl'
import { euro } from 'utils/currency'
import { calculTotalPackages } from 'utils/cart'
import LineSelectEdit from './components/LineSelectEdit'
import LineSelectShow from './components/LineSelectShow'

const LeakSearch = ({
  className,
  CartStore: { leakPackages, addPackage, removePackage, replacePackage },
  MissionStore: { isMissionClosed },
  ManagerClaimStore: { isClaimClosed },
}) => {
  const { t } = useTranslation()
  const { type } = useParams()
  const [showSelectAdd, setShowSelectAdd] = useState(false)
  const isClosed = type === 'mission' ? isMissionClosed : isClaimClosed

  const { leakSearchType, leakPackage, setLeakPackage, editionId } = SearchLeakCtrl
  const closeSelectAdd = () => setShowSelectAdd(false)
  const onChangeCde = value => {
    setLeakPackage(value)
  }

  return (
    <div className={className}>
      <Dropdown
        title={t('mission.calculation.cart.titleLeak')}
        showDropdown={true}
        total={calculTotalPackages({
          packages: leakPackages,
          key: 'finalPriceCatalog',
          selfRepair: false,
        })}
        priceType={`${t('mission.calculation.cart.totalWithVAT')} :`}
        addAction={() => {
          SearchLeakCtrl.setProperty('editionId', null)
          SearchLeakCtrl.setNewLeakPackage()
          setShowSelectAdd(true)
        }}
        showAdd={!isClosed}
      >
        {leakPackages.map(lk => {
          return (
            <div key={`leakSearch-${lk.id}`}>
              {lk.id === editionId && (
                <LineSelectEdit
                  label={t('mission.calculation.leakSearch.type')}
                  amountLabel={t('mission.calculation.cart.totalWithoutVAT')}
                  packageCde={path(['cde'], leakPackage)}
                  onChangeCde={e => onChangeCde(e.target.value)}
                  packageType={leakSearchType}
                  price={path(['finalPriceCatalogWithoutVAT'], leakPackage) || 0}
                  targetPrice={'overridePrice'}
                  onChangePrice={e => leakPackage.setProperty(e.target.name, e.target.value)}
                  onCancel={() => {
                    SearchLeakCtrl.setProperty('editionId', null)
                  }}
                  onConfirm={() => {
                    if (!isNil(leakPackage.id)) {
                      replacePackage(editionId, leakPackage)
                      SearchLeakCtrl.setProperty('editionId', null)
                    }
                  }}
                />
              )}

              {lk.id !== editionId && (
                <LineSelectShow
                  label={lk.label}
                  price={euro(lk.finalPriceCatalogWithoutVAT)}
                  isMissionClosed={isClosed}
                  onEdit={() => {
                    SearchLeakCtrl.setProperty('editionId', lk.id)
                    SearchLeakCtrl.setProperty('leakPackage', lk)
                  }}
                  onDelete={() => removePackage(lk.id)}
                />
              )}
            </div>
          )
        })}
      </Dropdown>

      {showSelectAdd && editionId === null && (
        <LineSelectEdit
          label={t('mission.calculation.leakSearch.type')}
          amountLabel={t('mission.calculation.cart.totalWithoutVAT')}
          packageCde={path(['cde'], leakPackage)}
          onChangeCde={e => onChangeCde(e.target.value)}
          packageType={leakSearchType}
          price={path(['finalPriceCatalogWithoutVAT'], leakPackage) || 0}
          targetPrice={'overridePrice'}
          onChangePrice={e => leakPackage.setProperty(e.target.name, e.target.value)}
          onCancel={closeSelectAdd}
          onConfirm={() => {
            if (!isNil(leakPackage.id)) {
              addPackage(leakPackage)
              setShowSelectAdd(false)
            }
          }}
        />
      )}
    </div>
  )
}

export default inject('CartStore', 'MissionStore', 'ManagerClaimStore')(observer(LeakSearch))
