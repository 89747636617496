import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { isNil } from 'ramda'
import classNames from 'classnames'

import PropertyEmbellishmentCtrl from 'stores/Mission/EasyEstimation/PropertyEmbellishmentCtrl'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import Button from 'components/Button/Button'

const WallModal = ({ show, setShow }) => {
  const [selectedWall, setSelectedWall] = useState(null)
  const { t } = useTranslation()
  const walls = [
    { label: 1, value: 25 },
    { label: 2, value: 50 },
    { label: 3, value: 75 },
    { label: 4, value: 100 },
  ]
  const { setProperty, computeAreaForPackages } = PropertyEmbellishmentCtrl

  return (
    <ModalWithBackdrop show={show} size="modal-md" centered onClose={() => {}}>
      <div className="modal-header d-flex justify-content-between">
        <h5 className="modal-title">Nombre de murs endommagés</h5>
      </div>

      <div className="modal-body container">
        <div className="btn-group d-flex">
          {walls.map(wall => (
            <button
              key={`wall-${wall.label}`}
              className={classNames('btn', {
                'btn-primary': selectedWall === wall.value,
                'btn-outline-primary': selectedWall !== wall.value,
              })}
              onClick={() => {
                setSelectedWall(wall.value)
              }}
            >
              {wall.label}
            </button>
          ))}
        </div>
      </div>

      <div className="modal-footer pb-2 pt-2">
        <Button
          className="btn btn-success"
          disabled={isNil(selectedWall)}
          onClick={() => {
            setProperty('percentageDammaged', selectedWall)
            setProperty('firstTimeWall', false)
            computeAreaForPackages()
            setShow(false)
            setSelectedWall(null)
          }}
        >
          {t('common.select2')}
        </Button>
      </div>
    </ModalWithBackdrop>
  )
}

export default observer(WallModal)
