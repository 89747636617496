import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { isEmpty } from 'ramda'
import { useTranslation } from 'react-i18next'

import FurnitureCtrl from 'stores/Mission/EasyEstimation/FurnitureCtrl'
import FurnitureAdd from './FunitureAdd'
import FurnitureShow from './FurnitureShow'
import SearchModal from './SearchModal'

const CurrentCategory = ({
  CartStore: { furnitures, addFurniture, removeFurniture },
  UserStore: { customer },
}) => {
  const { t } = useTranslation()
  const [showAdd, setShowAdd] = useState(false)
  const [showSearchModal, setShowSearchModal] = useState(false)

  const { furniture, editionId, selectedCategory, setNewFurniture } = FurnitureCtrl
  const addAction = () => {
    setNewFurniture()
    setShowAdd(true)
  }

  if (isEmpty(selectedCategory)) return null

  return (
    <div className="cart">
      {showSearchModal && (
        <SearchModal
          showSearchModal
          selectedCategory={selectedCategory}
          closeSearchModal={() => {
            setShowAdd(false)
            setShowSearchModal(false)
            FurnitureCtrl.setProperty('editionId', null)
          }}
        />
      )}

      <div className="mb-3">
        <button className="btn btn-primary" onClick={addAction}>
          {`${t('mission.calculation.furniture.breadcrumbNewFurniture')} ${t(
            'mission.calculation.furniture.regular',
          )}`}
        </button>{' '}
        {customer.hasContract('RP') && (
          <button className="btn btn-primary" onClick={() => setShowSearchModal(true)}>
            {`${t('mission.calculation.searchFurniture.search')} ${t(
              'mission.calculation.furniture.regular',
            )}`}
          </button>
        )}
      </div>
      {furnitures.map(f => {
        if (f.category !== selectedCategory) return null
        return (
          <div key={`furniture-${f.id}`}>
            {f.id === editionId && (
              <FurnitureAdd
                furniture={f}
                onCancel={() => {
                  FurnitureCtrl.setProperty('editionId', null)
                }}
                onConfirm={() => {
                  FurnitureCtrl.setProperty('editionId', null)
                }}
              />
            )}

            {f.id !== editionId && (
              <FurnitureShow
                furniture={f}
                onEdit={() => {
                  FurnitureCtrl.setProperty('editionId', f.id)
                }}
                onDelete={() => {
                  removeFurniture(f.id)
                  FurnitureCtrl.setProperty('editionId', null)
                }}
              />
            )}
          </div>
        )
      })}

      {showAdd && !showSearchModal && (
        <FurnitureAdd
          furniture={furniture}
          onCancel={() => setShowAdd(false)}
          onConfirm={() => {
            addFurniture(furniture)
            setShowAdd(false)
          }}
        />
      )}
    </div>
  )
}

export default inject('CartStore', 'UserStore')(observer(CurrentCategory))
