import React from 'react'
import PropTypes from 'prop-types'

const InputErrors = ({ errors }) => {
  if (!errors.length) return null

  return (
    <div key={'error-block'} className="row">
      <div className="error-message col-sm-12">
        {errors.map(errorDescription => (
          <div key={errorDescription}>{errorDescription}</div>
        ))}
      </div>
    </div>
  )
}

InputErrors.defaultProps = {
  errors: [],
}

InputErrors.propTypes = {
  errors: PropTypes.array,
}

export default InputErrors
