import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'

import PropertyEmbellishmentCtrl from 'stores/Mission/EasyEstimation/PropertyEmbellishmentCtrl'
import InputNumber from 'components/InputNumber/InputNumber'

const Calculator = () => {
  const [length, setLength] = useState(0)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const { showCalculator, selectedPart, setProperty } = PropertyEmbellishmentCtrl

  useEffect(() => {
    if (selectedPart === 'W' && width > 0) setWidth(0)
  }, [selectedPart, width])

  if (!showCalculator) return null

  return (
    <div className="calculator px-3 py-2 border">
      <div className="d-flex justify-content-end">
        <i className="fa fa-close clickable" onClick={() => setProperty('showCalculator', false)} />
      </div>
      <label>Longueur :</label>{' '}
      <InputNumber
        className="form-control"
        value={length}
        onChange={e => setLength(e.target.value)}
        disabled={selectedPart === 'W' && width > 0}
      />
      <label>Largeur :</label>{' '}
      <InputNumber
        className="form-control"
        value={width}
        onChange={e => setWidth(e.target.value)}
        disabled={selectedPart === 'W'}
      />
      <label>Hauteur :</label>{' '}
      <InputNumber
        className="form-control"
        value={height}
        onChange={e => setHeight(e.target.value)}
        disabled={selectedPart === 'C' || selectedPart === 'F'}
      />
      <div className="d-flex justify-content-end mt-2">
        <button
          className="btn btn-primary"
          onClick={() => {
            if (selectedPart === 'C' || selectedPart === 'F') setProperty('area', width * length)
            else if (selectedPart === 'W') {
              const area = length > 0 ? length : height
              setProperty('area', height * area)
            }

            setProperty('showCalculator', false)
          }}
        >
          Calculer
        </button>
      </div>
    </div>
  )
}

export default observer(Calculator)
