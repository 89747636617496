import React from 'react'
import { observer, inject } from 'mobx-react'
import { NavLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'

import ProtectedLink from 'components/ProtectedLink/ProtectedLink'

const ParametersMobile = ({
  t,
  UiCtrl: { toggleSideNav },
  UserStore: {
    isAllowedToPartyInvolvedDB,
    isAllowedToUserManagement,
    isAllowedToCoverageExecutionTime,
    isAllowedToTourConfiguration,
  },
}) => {
  const onClick = () => toggleSideNav(false)

  return (
    <div className="side-nav">
      <h5 className="pt-3">{t('parameters.sideNav.title')}</h5>
      <NavLink
        className="nav-link"
        activeClassName="active"
        to="/parameters/informations"
        onClick={onClick}
      >
        {t('parameters.sideNav.informations')}
      </NavLink>
      <ProtectedLink
        to="/parameters/partyInvolvedDB"
        condition={!isAllowedToPartyInvolvedDB}
        onClick={onClick}
      >
        {t('mission.sideNav.partyInvolved')}
      </ProtectedLink>
      <ProtectedLink
        to="/parameters/userManagement"
        condition={!isAllowedToUserManagement}
        onClick={onClick}
      >
        {t('parameters.sideNav.userManagement')}
      </ProtectedLink>
      <ProtectedLink
        to="/parameters/tourConfiguration"
        condition={!isAllowedToTourConfiguration}
        onClick={onClick}
      >
        {t('parameters.sideNav.tourConfiguration')}
      </ProtectedLink>

      {isAllowedToCoverageExecutionTime && (
        <React.Fragment>
          <h5 className="border-top pt-3 mt-2">{t('parameters.sideNav.calendarTitle')}</h5>
          <ProtectedLink
            to="/parameters/coverageExecutionTime"
            condition={!isAllowedToCoverageExecutionTime}
            onClick={onClick}
          >
            {t('parameters.sideNav.coverageExecutionTime')}
          </ProtectedLink>
        </React.Fragment>
      )}

      <h5 className="border-top pt-3 mt-2">{t('parameters.sideNav.networkTitle')}</h5>
      <div className="nav-link protected">{t('parameters.sideNav.networkManager')}</div>

      <h5 className="border-top pt-3 mt-2">{t('parameters.sideNav.application')}</h5>
      <NavLink
        className="nav-link"
        activeClassName="active"
        to="/parameters/version"
        onClick={onClick}
      >
        {t('common.applicationVersion')}
      </NavLink>
    </div>
  )
}

export default compose(
  inject('UiCtrl', 'UserStore'),
  withTranslation(),
  observer,
)(ParametersMobile)
