import { isNil } from 'ramda'

import request from 'services/httpRequest'
import { formatValidator } from 'utils/report'

export const generateReport = async (wan, isMissionClosed, reportType) => {
  let url = `/claimFiles/v1/claimFileAssessor/${wan}/report-pdf`

  if (!isNil(reportType)) {
    url += `/${reportType}`
  }

  return await request({
    method: 'get',
    responseType: isMissionClosed ? 'blob' : null,
    url,
  })
}

export const sendEmail = (wan, mail, reportType) => {
  let url = `/claimFiles/v1/claimFileAssessor/${wan}/report-pdf-send`

  if (!isNil(reportType)) {
    url += `/${reportType}`
  }

  return request({
    method: 'post',
    url,
    data: {
      data: {
        type: 'report_pdf_send',
        attributes: { email: mail },
      },
    },
  })
}

export const sendEmailCfm = (wan, mail) => {
  let url = `/claimFiles/v1/claimFileManager/${wan}/report-pdf-send`

  return request({
    method: 'post',
    url,
    data: {
      data: {
        type: 'report_pdf_send',
        attributes: { email: mail },
      },
    },
  })
}

export const downloadReportPdf = async (wan, reportType) => {
  let url = `/claimFiles/v1/claimFileAssessor/${wan}/download-pdf`

  if (!isNil(reportType)) {
    url += `/${reportType}`
  }

  return await request({
    method: 'get',
    responseType: 'blob',
    url,
  })
}
export const retreiveIrsiReportFromDarva = async wan => {
  let url = `/darva/v1/report/${wan}/retreive/rec`

  return await request({
    method: 'get',
    url,
  })
}

export const syncDarva = async wan => {
  return await request({
    method: 'get',
    url: `/claimFiles/v1/claimFileAssessor/${wan}/sync-darva`,
  })
}

export const getTransparentLink = async (wan, wanType) => {
  return await request({
    method: 'get',
    url: `darva/v1/report/${wan}/transparent-link-${wanType}`,
  })
}

export const sendDarva = (wan, darvaReportType) => {
  let url = `/darva/v1/report/${wan}`

  if (!isNil(darvaReportType)) {
    url += `/${darvaReportType}`
  }

  return request({
    method: 'post',
    url,
  })
}

export const sendRectifReportReason = (wan, attributes, darvaReportType) => {
  let url = `/darva/v1/report/${wan}`

  if (!isNil(darvaReportType)) {
    url += `/${darvaReportType}`
  }

  return request({
    method: 'post',
    url,
    data: {
      data: {
        type: 'rectificativeReportReason',
        attributes,
      },
    },
  })
}

export const setPredictableDelay = async (wan, attributes) => {
  try {
    await request({
      method: 'post',
      url: `/darva/v1/report/${wan}/delay`,
      data: {
        data: {
          type: 'trackingPredictableDelay',
          attributes,
        },
      },
    })
    return true
  } catch (err) {
    return false
  }
}

export const getReportType = async wan => {
  try {
    const { data } = await request({
      method: 'get',
      url: `/claimFiles/v1/claimFileAssessor/${wan}/report-type`,
    })

    return data.attributes
  } catch (err) {
    console.warn(`WARNING Error fetching report-type of wan: ${wan}`)
    return null
  }
}

export const getReportValidator = async (wan, reportType) => {
  let url = `/claimFiles/v1/claimFileAssessor/${wan}/validator-for-darva-report`

  if (!isNil(reportType)) {
    url += `/${reportType}`
  }

  const res = await request(
    {
      method: 'GET',
      url,
    },
    true,
  )

  if (res.status === 204) {
    return 204
  }

  return formatValidator(res.data.data.attributes)
}

export const fetchSDDetail = async ({ wan, sdId }) => {
  try {
    const res = await request({
      method: 'GET',
      url: `/claimFiles/v1/claimFileAssessor/${wan}/documents/${sdId}`,
    })
    return res.data.attributes
  } catch (error) {
    console.log(error)
    return null
  }
}

export const retrieveDarva = async wan => {
  try {
    const res = await request({
      method: 'GET',
      url: `claimFiles/v1/claimFileAssessor/${wan}/retrieve-report`,
    })
    return res
  } catch (error) {
    throw error
  }
}
