import { observable, computed, action, decorate, runInAction } from 'mobx'
import { path, type, isNil } from 'ramda'
import moment from 'moment'

import {
  fetchClaim,
  changeCurrentMissionAssignee,
  changeToAggravation,
  sendConfirmationBySms,
} from 'services/claim'
import PartyInvolvedStore from 'stores/Common/domain/PartyInvolvedStore'
import SupportingDocumentsCtrl from 'stores/Common/view/SupportingDocumentsCtrl'
import CreateMissionCtrl from 'stores/Claim/view/CreateMissionCtrl'
import MessagingStore from 'stores/Messaging/MessagingStore'
import CommonStore, { HORS_DARVA_TAGS } from 'stores/Common/domain/CommonStore'
import AlertCtrl from 'stores/Common/view/AlertCtrl'

export const OVERRAN_STEPS = Object.freeze(['CIMR', 'CIMQ', 'CIMO'])

class ClaimStore {
  loading = false
  aggravating = false
  reassigning = false
  claim = null
  missionsWan = []

  confirmSmsDate = null
  sendingSms = false
  showSmsModal = false
  phoneNumber = ''
  smsSelectedIP = null

  id = null

  get isClaimClosed() {
    const status = path(['status', 'key'], this.claim)
    return status === 'CICC'
  }

  get isClaimTerminated() {
    const status = path(['status', 'key'], this.claim)
    return status === 'CICT'
  }

  get isCancelButtonAvailable() {
    const sd13Available = path(['sd13Available'], this.claim)
    const sd70Available = path(['sd70Available'], this.claim)
    const status = path(['status', 'key'], this.claim)
    return (sd13Available || sd70Available) && (status !== 'CICT' && status !== 'CICC')
  }

  get isClaimOverran() {
    return path(['status', 'key'], this.claim) === 'CIMO'
  }

  get isToQualify() {
    const status = path(['status', 'key'], this.claim)
    return status === 'CIMQ' || status === 'CIMR'
  }

  get isQualified() {
    return path(['status', 'key'], this.claim) === 'CIMQ'
  }

  get isPlanned() {
    return path(['status', 'key'], this.claim) === 'CIAF'
  }

  get isMissionSent() {
    return path(['status', 'key'], this.claim) === 'CIMS'
  }

  get missions() {
    const missions = path(['missions'], this.claim)
    if (missions) return missions
    return []
  }

  get mission() {
    return this.claim && this.claim.mission
  }

  get isMissionClosed() {
    const status = path(['mission', 'status', 'key'], this.claim)
    return status === 'CACC'
  }

  get lastMission() {
    if (this.claim && this.claim.mission) return this.claim && this.claim.mission
    if (this.claim && this.claim.missions.length > 0)
      return this.claim.missions[this.claim.missions.length - 1]
    return null
  }

  get lastMissionID() {
    const missions = path(['missions'], this.claim)
    if (missions && missions.length > 0) {
      return path([missions.length - 1, 'id'], missions)
    }
    return null
  }

  get validMissions() {
    if (this.isClaimOverran) return []
    else if (this.claim) return this.claim.missions.filter(mission => mission.cfaWan !== null)
    return []
  }

  get missionDuration() {
    const mission = path(['mission'], this.claim)
    if (!mission) return 0

    const missionDuration =
      path(['missionInsurerInformation', 'coverageExecutionTime'], mission) || 0

    return type(missionDuration) === 'String' ? parseInt(missionDuration) : missionDuration
  }

  get isIME() {
    const gta = path(['gta'], this.claim)
    return gta === '1311' || gta === '418E'
  }

  get isMPJ() {
    const gta = path(['gta'], this.claim)
    return gta === 'J01' || gta === 'J02'
  }

  get isCoverageFire() {
    return path(['claimInformation', 'coverage', 'key'], this.claim) === 'FIRE'
  }

  get isUrgent() {
    return path(['mission', 'missionInsurerInformation', 'missionNatureKey'], this.claim) === 'M'
  }

  get position() {
    const position = path(
      ['mission', 'missionInsurerInformation', 'address', 'geometry'],
      this.claim,
    )
    if (position && position.lat && position.long) return { lat: position.lat, lng: position.long }

    return null
  }

  get isHorsDarva() {
    const tags = path(['tags'], this.cfa)

    if (!isNil(tags) && type(tags) === 'Array') {
      return tags.some(tag => HORS_DARVA_TAGS.includes(tag))
    }

    return false
  }

  get isSocle() {
    const gta = path(['gta'], this.claim)
    const isSocle = path(['contract', 'isSocle'], this.claim)
    return isSocle === true && gta === '418'
  }

  get status() {
    return path(['status', 'key'], this.claim)
  }

  getMission = missionId => {
    const currentMission = this.validMissions.find(mission => mission.cfaWan === missionId)

    if (isNil(currentMission)) {
      console.warn(`Current mission ${missionId} is not found in missions.`)
    }

    return currentMission
  }

  reloadClaimStatus = async wan => {
    try {
      const res = await fetchClaim(wan)
      runInAction(() => {
        this.claim = res
        MessagingStore.fetchContexts(wan)
      })
    } catch (err) {
      console.log(err)
    }
  }

  changeAssignee = async (wan, userId) => {
    const mission = path(['mission'], this.claim)
    this.reassigning = true
    try {
      await changeCurrentMissionAssignee(wan, mission.cfaWan, userId)
      await this.loadData(wan)
      return true
    } catch (err) {
      return false
    } finally {
      runInAction(() => (this.reassigning = false))
    }
  }

  async loadData(wan, withLoading = true) {
    if (withLoading) this.loading = true

    try {
      const claim = await fetchClaim(wan)
      if (
        process.env.REACT_APP_SOLERA_ENV === 'TEST' ||
        process.env.REACT_APP_SOLERA_ENV === 'DEV'
      ) {
        console.log('claim data : ', claim)
      }

      runInAction(() => {
        this.id = wan
        const addressOfLossGeometry = path(['claimInformation', 'addressOfLoss', 'geometry'], claim)
        if (!isNil(addressOfLossGeometry)) {
          claim.claimInformation.addressOfLoss.geometry.lat = parseFloat(addressOfLossGeometry.lat)
          claim.claimInformation.addressOfLoss.geometry.long = parseFloat(
            addressOfLossGeometry.long,
          )
        }

        this.claim = claim
        this.confirmSmsDate = claim.mission.confirmSmsDate
        this.loading = false
        PartyInvolvedStore.loadData(claim.involvedParties)
        SupportingDocumentsCtrl.fetchSupportingDocuments(wan)
        MessagingStore.loadData(wan, wan)
        if (!this.isClaimClosed) CreateMissionCtrl.loadData(wan)
        CommonStore.loadSupportingDocumentsTypesData(wan, 'claim')
        if (!this.isClaimClosed && !this.isToQualify && !this.isClaimTerminated)
          CommonStore.loadExperts(wan)
      })
    } catch (err) {
      runInAction(() => {
        this.loading = true
      })
    }
  }

  async aggravateMission(wan, cfaWan) {
    this.aggravating = true

    try {
      await changeToAggravation(cfaWan)
      await this.loadData(wan, false)

      runInAction(() => {
        this.aggravating = false
      })

      return path(['cfaWan'], this.lastMission)
    } catch (error) {
      AlertCtrl.alert('danger', 'httpCode.500')
      throw error
    }
  }

  get canSendConfirmationBySms() {
    if (this.isClaimClosed || this.isMissionClosed) return false

    const appointmentDate = path(['mission', 'appointmentDate'], this.claim)
    if (!appointmentDate || moment(appointmentDate).diff(moment(new Date()), 'days') < 0)
      return false

    return true
  }

  sendConfirmationBySms = async missionId => {
    const cfaWan = path(['mission', 'cfaWan'], this.claim)
    this.sendingSms = true
    try {
      const confirmSmsDate = await sendConfirmationBySms(cfaWan, this.phoneNumber)
      AlertCtrl.alert('success', 'mission.report.confirmSmsSent')
      runInAction(() => {
        this.confirmSmsDate = confirmSmsDate
        this.showSmsModal = false
        const mission = this.getMission(missionId)
        mission.smsSent = true
      })
    } catch (error) {
      AlertCtrl.alert('danger', 'mission.report.unableToconfirmSms')
    } finally {
      runInAction(() => {
        this.sendingSms = false
      })
    }
  }

  setProperty = (key, value) => {
    this[key] = value
  }
}

const DecoratedClaimStore = decorate(ClaimStore, {
  loading: observable,
  claim: observable,
  missionsWan: observable,
  reassigning: observable,
  aggravating: observable,
  confirmSmsDate: observable,
  sendingSms: observable,
  showSmsModal: observable,
  phoneNumber: observable,
  smsSelectedIP: observable,

  isClaimClosed: computed,
  isClaimTerminated: computed,
  isClaimOverran: computed,
  isQualified: computed,
  isToQualify: computed,
  missions: computed,
  mission: computed,
  isMissionClosed: computed,
  lastMission: computed,
  lastMissionID: computed,
  missionDuration: computed,
  isHorsDarva: computed,
  isCancelButtonAvailable: computed,
  isPlanned: computed,
  isIME: computed,
  isMPJ: computed,
  isCoverageFire: computed,
  isUrgent: computed,
  position: computed,
  isSocle: computed,
  status: computed,
  canSendConfirmationBySms: computed,

  reloadClaimStatus: action,
  sendConfirmationBySms: action,
  loadData: action.bound,
  loadMissions: action,
  changeAssignee: action,
  aggravateMission: action.bound,
  setProperty: action,
})

export default new DecoratedClaimStore()
