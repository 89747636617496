import { observable, action, decorate, runInAction } from 'mobx'

import { fetchPv, savePv } from 'services/missionForm'
import Form from 'utils/dynform/Form'
import { generateReport } from 'services/report'
import AlertCtrl from 'stores/Common/view/AlertCtrl'
import ReportCtrl from 'stores/Mission/view/ReportCtrl'
import MissionStore from 'stores/Mission/domain/MissionStore'
import SupportingDocumentsCtrl from 'stores/Common/view/SupportingDocumentsCtrl'
import SDExpertCtrl from 'stores/Common/view/SDExpertCtrl'

class PvCtrl {
  loading = false
  form = null
  loaded = false
  reportContent = ''
  numPages = 0

  loadData = async (wan, pvData) => {
    this.loading = true

    try {
      const form = await fetchPv(wan)
      form.name = 'pv'
      runInAction(() => {
        this.form = new Form({ form, data: pvData })
        this.loaded = true
      })
    } catch (err) {
      console.error(err)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  save = async (wan, formData) => {
    try {
      let claim = await savePv(wan, formData)
      const isValid = await ReportCtrl.pvValidator(wan)
      if (isValid === 204) {
        let reportSupportingDocuments = []
        if (claim.reports && claim.reports[0] && claim.reports[0].supportingDocuments) {
          reportSupportingDocuments = Object.values(claim.reports[0].supportingDocuments)
        }

        SDExpertCtrl.loadSDReport(reportSupportingDocuments)
        SupportingDocumentsCtrl.fetchSupportingDocuments(wan)
      }
      this.generateReport(wan)
    } catch (error) {
      console.error(error)
    }
  }

  setReportContent = content => {
    this.reportContent = content
  }

  generateReport = async wan => {
    this.setReportContent('')

    try {
      const res = await generateReport(wan, MissionStore.isMissionClosed, 'PV_EXPERTISE_REPORT')

      runInAction(() => {
        if (res.body) {
          this.setReportContent(res.body)
        } else {
          this.setReportContent(res)
        }
      })
    } catch (error) {
      console.warn(error)
      AlertCtrl.alert('danger', 'mission.report.contentFailure')
    }
  }

  setProperty = (key, value) => {
    this[key] = value
  }
}

const DecoratedPv = decorate(PvCtrl, {
  loading: observable,
  form: observable,
  loaded: observable,
  numPages: observable,
  reportContent: observable,

  loadData: action,
  save: action,
  generateReport: action,
  setProperty: action,
})

export default new DecoratedPv()
