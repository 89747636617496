import { observable, computed, action, decorate } from 'mobx'
import { isValid, format, setHours } from 'date-fns'

import AbstractField from 'utils/dynform/AbstractField'

class InputDate extends AbstractField {
  value = ''
  errors = []
  show = true
  originalValue = null

  type = 'datetime'
  multiple = false
  expanded = false

  children = null

  constructor(data) {
    super(data)

    this.type = data.type || 'datetime'

    if (data.value) this.value = data.value
    else if (data.default) this.value = data.default
    else this.value = ''

    this.show = data.show
    this.originalValue = this.value
  }

  get changed() {
    return this.value !== this.originalValue
  }

  get ISOString() {
    const dateValue = setHours(new Date(this.value), 10)

    if (isValid(dateValue)) {
      return format(dateValue, "yyyy-MM-dd'T'HH:mm:ssxxx")
    }

    console.warn('Date not valid : ', this.value)
    return null
  }

  setOriginalData() {
    this.value = this.originalValue
  }
}

const DecoratedInputDate = decorate(InputDate, {
  value: observable,
  originalValue: observable,
  errors: observable,
  show: observable,

  changed: computed,
  ISOString: computed,

  setOriginalData: action,
})

export default DecoratedInputDate
