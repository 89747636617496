import React from 'react'
import classNames from 'classnames'

export default ({ onChangeLanguage, language }) => {
  return (
    <ul className="navbar-nav ml-auto">
      <li className={classNames('nav-item', { active: language === 'fr-FR' })}>
        <span className="nav-link text-primary" onClick={onChangeLanguage.bind(this, 'fr-FR')}>
          FR
        </span>
      </li>
      <li className={classNames('nav-item', { active: language === 'en-US' })}>
        <span className="nav-link text-primary" onClick={onChangeLanguage.bind(this, 'en-US')}>
          EN
        </span>
      </li>
      <li className={classNames('nav-item', { active: language === 'nl-NL' })}>
        <span className="nav-link text-primary" onClick={onChangeLanguage.bind(this, 'nl-NL')}>
          NL
        </span>
      </li>
    </ul>
  )
}
