import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import { compose } from 'recompose'
import classNames from 'classnames'

import SearchPackageCtrl from 'stores/Mission/view/SearchPackageCtrl'
import DetailPackageCtrl from 'stores/Mission/view/DetailPackageCtrl'

const Spinner = styled.i`
  font-size: 22px;
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const Package = withRouter(
  observer(
    class Package extends React.Component {
      showPackageDetail = packageData => {
        const { id, type } = this.props.match.params
        DetailPackageCtrl.setPackage(packageData)
        this.props.history.push(`/${type}/${id}/calculation/detailPackage`)
      }

      toggleFavoritePackages = async packageDataId => {
        try {
          await SearchPackageCtrl.toggleFavoritePackages(packageDataId)
        } catch (error) {
          console.log(error)
        }
      }

      render() {
        const { toggleFavoriteInProgress } = SearchPackageCtrl
        const { packageData } = this.props
        const packageId = packageData.id

        return (
          <div className="package row">
            <div className="col-11 pl-0 pr-0">
              <p className="p-3 mb-0">
                <span onClick={this.showPackageDetail.bind(this, packageData)}>
                  {packageData.label}{' '}
                  {process.env.REACT_APP_SOLERA_ENV === 'DEV'
                    ? `(Score: ${packageData.score})`
                    : null}
                </span>
              </p>
            </div>
            <div className="col-1">
              {toggleFavoriteInProgress.indexOf(packageId) !== -1 && (
                <i className="pt-2 fz-2 text-primary fa fa-spinner fa-spin"></i>
              )}
              {toggleFavoriteInProgress.indexOf(packageId) === -1 && (
                <i
                  className={classNames({
                    'pt-2 fz-2 fa fa-star-o': !SearchPackageCtrl.packageIsFavorite(packageId),
                    'pt-2 fz-2 fa fa-star favorite': SearchPackageCtrl.packageIsFavorite(packageId),
                  })}
                  onClick={() => this.toggleFavoritePackages(packageId)}
                ></i>
              )}
            </div>
          </div>
        )
      }
    },
  ),
)

const SearchResults = observer(({ t }) => {
  const { searching, totalSearchPackage, selectRoomError } = SearchPackageCtrl

  if (selectRoomError)
    return <h6 className="text-danger">{t('mission.calculation.searchPackage.selectRoomError')}</h6>
  if (searching)
    return (
      <h6 className="text-primary">
        <Spinner className="fa fa-spinner" />
      </h6>
    )

  return <h6>{t('mission.calculation.searchPackage.result', { count: totalSearchPackage })}</h6>
})

const ShowMore = withTranslation()(
  observer(({ t, fetchMore }) => {
    const { searching, totalSearchPackage, list, roomType } = SearchPackageCtrl
    if (roomType === 'selectFilter') return null
    if (searching || totalSearchPackage === 0 || totalSearchPackage === list.length) return null

    return (
      <div className="show-more mt-3 text-right">
        <button className="btn btn-primary" onClick={fetchMore}>
          {t('mission.calculation.searchPackage.showMorePackages')}
        </button>
      </div>
    )
  }),
)

class ListSearch extends React.Component {
  fetchMore = () => {
    SearchPackageCtrl.fetchSearchPackage(this.props.match.params.id, true)
  }

  render() {
    const { t } = this.props
    const { list, activeSearch } = SearchPackageCtrl

    if (!activeSearch) return null

    return (
      <div className="search-result container pl-0 pr-0">
        <SearchResults t={t} />

        <div className="list container">
          {list.map(packageData => {
            return <Package key={`package-${packageData.id}`} packageData={packageData} />
          })}
        </div>

        <ShowMore fetchMore={this.fetchMore} />
      </div>
    )
  }
}

export default compose(
  withTranslation(),
  withRouter,
  observer,
)(ListSearch)
