import React, { useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { compose } from 'recompose'

import { baseUrl } from 'services/utils'
import './Dashboard.css'
import PageLayout from 'components/PageLayout/PageLayout'
import Filters from 'pages/Dashboard/components/Filters'
import List from 'pages/Dashboard/components/List'
import Pagination from 'pages/Dashboard/components/Pagination'
import Header from './components/Header'
import ListCards from './components/ListCards'
import Loader from 'components/Loader/Loader'
import Button from 'components/Button/Button'

const DashboardPage = ({
  DashboardCtrl: {
    fetchMissions,
    resetMissionsToPrint,
    missionsView,
    setProperty,
    missionsToPrint,
    formatMissionsPrint,
    missions,
  },
  UserStore: { isExpert, token },
  DashboardFilterStore: { init: initDashFilter },
  UiCtrl: { isMobile },
  CommonStore: { claimStatus },
}) => {
  const { t } = useTranslation()
  useEffect(() => {
    fetchMissions()
    initDashFilter()
    resetMissionsToPrint()
  }, [resetMissionsToPrint, initDashFilter, fetchMissions])

  if (!claimStatus.length) return <Loader />

  if (isMobile && missionsView === 'listTable') setProperty('missionsView', 'listCard')

  return (
    <div className="container dashboard-page">
      <Header />
      <Filters />
      <ListCards />
      <List />
      <div className="d-flex justify-content-end">
        <div className="d-flex justify-content-center w-50">
          <Pagination />
        </div>
        {!!missions.length && (
          <div className="d-flex align-items-center" disabled>
            {!!missionsToPrint.length ? (
              <div>
                <a
                  className="btn btn-primary btn-sm px-5k"
                  href={`${baseUrl()}/claimFiles/v1/${
                    isExpert ? 'claimFileAssessor/' : ''
                  }print-etiquette-pdf?${formatMissionsPrint}&access_token=${token}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('common.downloadEtiquette')}
                </a>{' '}
                <a
                  className="btn btn-primary btn-sm px-5k"
                  href={`${baseUrl()}/claimFiles/v1/${
                    isExpert ? 'claimFileAssessor/' : ''
                  }print-pdf?${formatMissionsPrint}&access_token=${token}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('common.downloadMission')}
                </a>
              </div>
            ) : (
              <div>
                <Button className="btn btn-primary btn-sm px-5k" disabled={true}>
                  {t('common.downloadEtiquette')}
                </Button>{' '}
                <Button className="btn btn-primary btn-sm px-5k" disabled={true}>
                  {t('common.downloadMission')}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default compose(
  PageLayout,
  inject('UiCtrl', 'DashboardCtrl', 'UserStore', 'DashboardFilterStore', 'CommonStore'),
  observer,
)(DashboardPage)
