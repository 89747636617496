import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'

import NavigationButtons from './NavigationButtons'

function CalendarToolbar({
  t,
  CalendarExpertStore: { calendarNavigation, currentWeek, weekNumber },
  AppointmentCtrl: { showModal },
}) {
  return (
    <Fragment>
      <button
        className="btn-week-today btn btn-success mr-3"
        onClick={() => calendarNavigation('today', true)}
      >
        {t('calendar.filters.today')}
      </button>

      <div className="row no-gutters mb-3">
        <div className="col-8 col-md-11">
          <div className="current-date-title text-capitalize font-weight-bold">
            {currentWeek} ({t('calendar.info.week')} {weekNumber})
          </div>
        </div>

        <div className="custom-navigation col-4 col-md-1 d-flex align-items-center justify-content-center">
          {!showModal && <NavigationButtons />}
        </div>
      </div>
    </Fragment>
  )
}

export default compose(
  inject('CalendarExpertStore', 'AppointmentCtrl'),
  withTranslation(),
  observer,
)(CalendarToolbar)
