import React, { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import Input from 'components/Forms/Input'
import Button from 'components/Button/Button'

const ConfirmPassword = ({ show, onClose }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState([])

  const confirmPassword = () => {
    if (password === 'Qlik69') {
      history.push('qlik')
    } else {
      setErrors(['Mot de passe érroné'])
    }
  }

  useEffect(() => {
    if (!show) {
      setPassword('')
      setErrors([])
    }

    return () => {
      setPassword('')
      setErrors([])
    }
  }, [show, setPassword, setErrors])

  return (
    <ModalWithBackdrop show={show} size="modal-sm" close={onClose}>
      <div className="modal-header">
        <h5 className="modal-title text-primary">
          Le module statistique est actuellement en cours de développement
        </h5>
      </div>

      <div className="modal-body">
        <Input
          name="qlik-password"
          className="form-control error"
          value={password}
          type="password"
          placeholder={t('loginPage.password') + '*'}
          onChange={e => setPassword(e.target.value)}
          autoComplete="off"
          required
          errors={errors}
        />
      </div>

      <div className="modal-footer d-flex justify-content-center pb-2 pt-2">
        <Button className="btn btn-secondary" onClick={onClose}>
          {t('common.close')}
        </Button>

        <Button className="btn btn-primary" onClick={confirmPassword}>
          {t('common.confirm')}
        </Button>
      </div>
    </ModalWithBackdrop>
  )
}

const QlickLink = ({ UiCtrl: { deviceType }, UserStore: { customer } }) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)

  const onClick = () => setShowModal(true)
  const onClose = () => setShowModal(false)

  if (!customer.hasContract('QLIK')) return null

  if (deviceType === 'mobile') {
    return (
      <React.Fragment>
        <ConfirmPassword show={showModal} onClose={onClose} />
        <li className="nav-item">
          <span className="nav-link text-primary" onClick={onClick}>
            {t('header.navbar.links.qlik')}
          </span>
        </li>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <ConfirmPassword show={showModal} onClose={onClose} />
      <span className="divider" />
      <li className="nav-item">
        <span className="nav-link text-primary" onClick={onClick}>
          {t('header.navbar.links.qlik')}
        </span>
      </li>
    </React.Fragment>
  )
}

export default inject('UiCtrl', 'UserStore')(observer(QlickLink))
