import React from 'react'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import ReportCtrl from 'stores/Mission/view/ReportCtrl'
import Input from 'components/Forms/Input'
import Button from 'components/Button/Button'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'

class EmailModal extends React.Component {
  toggleModal = () => ReportCtrl.toggleModal()

  render() {
    const { t, claimType } = this.props
    const { id } = this.props.match.params

    return (
      <ModalWithBackdrop size="modal-sm" show={ReportCtrl.emailModal} close={ReportCtrl.closeModal}>
        <div className="modal-header">
          <h5 className="modal-title">{t('mission.report.sendMailTitle')}</h5>
          <button type="button" className="close" onClick={this.toggleModal}>
            <span>&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form>
            <div className="form-group">
              <Input
                className="form-control"
                name="emailAddress"
                value={ReportCtrl.email}
                onChange={e => ReportCtrl.setEmail(e.target.value)}
                placeholder={t('partyInvolved.modal.email')}
                errors={toJS(ReportCtrl.fieldErrors)}
              />
            </div>
          </form>
        </div>
        <div className="modal-footer pb-2 pt-2">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => ReportCtrl.toggleModal()}
          >
            {t('partyInvolved.modal.close')}
          </button>
          <Button
            className="btn btn-primary"
            onClick={() => ReportCtrl.validateEmail(id, claimType)}
            disabled={ReportCtrl.loading}
          >
            {t('mission.report.sendAction')}
          </Button>
        </div>
      </ModalWithBackdrop>
    )
  }
}

EmailModal.defaultProps = {
  claimType: 'cfa',
}

export default withRouter(withTranslation()(observer(EmailModal)))
