import { format, addDays, startOfWeek } from 'date-fns'

export const positionColors = Object.freeze([
  {
    background: '#b6b231',
    fontColor: 'white',
  },
  {
    background: '#247460',
    fontColor: 'white',
  },
  {
    background: '#a3283e',
    fontColor: 'white',
  },

  {
    background: '#95d996',
    fontColor: 'white',
  },
  {
    background: '#184e9d',
    fontColor: 'white',
  },
  {
    background: '#28debd',
    fontColor: 'white',
  },
  {
    background: '#afbfd6',
    fontColor: 'white',
  },
  {
    background: '#efca0b',
    fontColor: 'white',
  },
  {
    background: '#6e9452',
    fontColor: 'white',
  },
  {
    background: '#4d68d4',
    fontColor: 'white',
  },
  {
    background: '#38cef8',
    fontColor: 'white',
  },
  {
    background: '#e28a7c',
    fontColor: 'white',
  },
])

export const getRange = (date, view) => {
  let dateStart = null
  let days = 0
  if (view === 'day') {
    dateStart = date
    days = 1
  } else if (view === 'week' || view === 'work_week') {
    dateStart = startOfWeek(date, { weekStartsOn: 1 })
    days = view === 'work_week' ? 4 : 6
  } else {
    throw new Error(`View type ${view} is not compatible with getRange function.`)
  }

  const rangeStart = format(dateStart, 'yyyy-MM-dd')
  const rangeEnd = format(addDays(dateStart, days), 'yyyy-MM-dd')

  return [rangeStart, rangeEnd]
}
