import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import fr from 'date-fns/locale/fr'
import en from 'date-fns/locale/en-US'

import { setPredictableDelay } from 'services/report'
import Label from 'components/Label/Label'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import Button from 'components/Button/Button'
import InputNumber from 'components/InputNumber/InputNumber'

const MissionModal = ({
  showModal,
  setShowModal,
  AlertCtrl: { alert },
  UiCtrl: { language },
  UserStore: { isExpert },
}) => {
  const { id: expertMissionId, missionId: managerMissionId } = useParams()
  const { t } = useTranslation()
  const [dateReportEstimate, setDateReportEstimate] = useState(new Date())
  const [evaluationRetardPredictable, setEvaluationRetardPredictable] = useState(0)
  const [sending, setSending] = useState(false)

  const onChangeDelay = e => {
    setEvaluationRetardPredictable(e.target.value)
    setDateReportEstimate(
      moment()
        .add(Number(e.target.value), 'days')
        .toDate(),
    )
  }

  const onChangeDate = selecteDdate => {
    setEvaluationRetardPredictable(
      moment(selecteDdate).diff(moment(new Date()).format('YYYY/MM/DD'), 'days'),
    )
    setDateReportEstimate(selecteDdate)
  }

  const onClose = () => setShowModal(false)

  const onValid = async () => {
    try {
      setSending(true)
      const wan = isExpert ? expertMissionId : managerMissionId
      const isSent = await setPredictableDelay(wan, {
        dateReportEstimate: moment(dateReportEstimate).format(),
        evaluationRetardPredictable: evaluationRetardPredictable,
        comment: t('mission.infoMission.modalDelay.commentDefaultText'),
        motiveRetard: '99',
        objectRetard: '1',
      })

      alert(
        isSent ? 'success' : 'danger',
        t(`mission.infoMission.modalDelay.${isSent ? 'success' : 'failed'}`),
      )
    } catch (error) {
      console.warn(error)
    } finally {
      setShowModal(false)
      setSending(false)
    }
  }

  return (
    <ModalWithBackdrop size="modal-md" show={showModal} close={onClose}>
      <div className="modal-header">
        <h5 className="modal-title">{t('mission.infoMission.modalDelay.header')}</h5>
        <button type="button" className="close" onClick={onClose}>
          <span>&times;</span>
        </button>
      </div>

      <div className="modal-body">
        <div className="d-flex flex-column justify-content-around form-group">
          <div className="form-group row">
            <Label htmlFor="appointmentDate" className="col-5 col-form-label" required>
              {t('mission.infoMission.modalDelay.date')}
            </Label>
            <div className="col date-picker-modal">
              <DatePicker
                id="appointmentDate"
                className="form-control flex-fill w-100"
                onChange={onChangeDate}
                selected={dateReportEstimate}
                minDate={new Date()}
                dateFormat="dd MMMM yyyy"
                locale={language === 'fr-FR' ? fr : en}
                required
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
          </div>
          <div className="form-group row">
            <Label htmlFor="delayDays" className="col-5 col-form-label" required>
              {t('mission.infoMission.modalDelay.delayDays')}
            </Label>
            <div className="col">
              <InputNumber
                className="form-control"
                id="delayDays"
                value={evaluationRetardPredictable}
                onChange={onChangeDelay}
                minValue={0}
                required
              />
            </div>
          </div>
        </div>
      </div>

      <div className="modal-footer pb-2 pt-2">
        <button type="button" className="btn btn-secondary" onClick={onClose}>
          {t('common.cancel')}
        </button>
        <Button
          className="btn btn-primary"
          onClick={onValid}
          loading={sending}
          disabled={!evaluationRetardPredictable || !dateReportEstimate || sending}
        >
          {t('common.validate')}
        </Button>
      </div>
    </ModalWithBackdrop>
  )
}

export default inject('AlertCtrl', 'UiCtrl', 'CommonStore', 'UserStore')(observer(MissionModal))
