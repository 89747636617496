import React from 'react'
import { observer } from 'mobx-react'
import { isNil } from 'ramda'

import Label from 'components/Forms/Label'

const FormLabel = ({ input }) => {
  if (input.type === 'table' || input.type === 'embeddedCollection' || input.type === 'label') {
    return null
  } else if (!isNil(input.labelUrl) && !isNil(input.url)) {
    return (
      <Label className="col-sm-4" required={input.required} label={input.label}>
        (
        <a href={input.url} target="_blank" rel="noopener noreferrer">
          {input.labelUrl}
        </a>
        )
      </Label>
    )
  }

  return <Label className="col-sm-4" required={input.required} label={input.label} />
}

export default observer(FormLabel)
