import { observable, action, runInAction, computed, decorate } from 'mobx'
import { isNil, path } from 'ramda'
import moment from 'moment'

import { checkPrice, furnitureDataTransformer, loadObsolescenceRate } from 'services/calculation'
import UserStore from 'stores/Common/domain/UserStore'
import FurnitureCatalog from 'stores/Mission/domain/FurnitureCatalog'
import MissionStore from 'stores/Mission/domain/MissionStore'
import ManagerClaimStore from 'stores/ManagerClaim/domain/ManagerClaimStore'

class AddFurnitureCtrl {
  furniture = null
  context = 'new'
  showObsolescenceCalculator = false
  checkingPrice = false
  showModalReplacementProducts = false
  replacementProducts = []
  obsolescenceRates = {}
  obsolescenceRatesAllContract = {}
  obsolescenceRatesOp5Y = {}
  obsolescenceRatesOpIllimite = {}

  loadObsolescenceRate = async (customerId, formulaContractKey) => {
    try {
      let data = null
      const obsolescenceRates = {}
      const obsolescenceRatesAllContract = {}
      const obsolescenceRatesOp5Y = {}
      const obsolescenceRatesOpIllimite = {}

      if (process.env.REACT_APP_SOLERA_ENV !== 'PROD') {
        data = await loadObsolescenceRate(customerId, MissionStore.gta, [
          formulaContractKey,
          'ALL_CONTRACT',
          'opNew5Y',
          'opNewUnlimit',
        ])
      }

      if (data) {
        data.forEach(customerObsRate => {
          const categoryRates = {}
          customerObsRate.rates.forEach(
            ({ year, rate }) => (categoryRates[`year${year}`] = rate * 100),
          )
          switch (customerObsRate.formulaContractKey) {
            case formulaContractKey:
              obsolescenceRates[customerObsRate.categoryKey] = categoryRates
              break
            case 'ALL_CONTRACT':
              obsolescenceRatesAllContract[customerObsRate.categoryKey] = categoryRates
              break
            case 'opNew5Y':
              obsolescenceRatesOp5Y[customerObsRate.categoryKey] = categoryRates
              break
            case 'opNewUnlimit':
              obsolescenceRatesOpIllimite[customerObsRate.categoryKey] = categoryRates
              break
            default:
              console.log(`unknown formulaContract ${customerObsRate.formulaContractKey}`)
          }
        })
      }
      this.setProperty('obsolescenceRates', obsolescenceRates)
      this.setProperty('obsolescenceRatesAllContract', obsolescenceRatesAllContract)
      this.setProperty('obsolescenceRatesOp5Y', obsolescenceRatesOp5Y)
      this.setProperty('obsolescenceRatesOpIllimite', obsolescenceRatesOpIllimite)
    } catch (err) {
      console.log(err)
    }
  }

  checkObs(dateOfPurchase, category, compensation) {
    let yearOld = Math.ceil(moment(new Date()).diff(moment(dateOfPurchase), 'years', true))
    if (yearOld > 9) yearOld = 10

    const pathIndex = [category, `year${yearOld}`]

    switch (compensation) {
      case 'opNew5Y':
        return path(pathIndex, this.obsolescenceRatesOp5Y)
      case 'opNewUnlimit':
        return path(pathIndex, this.obsolescenceRatesOpIllimite)
      default:
        return path(pathIndex, this.obsolescenceRates) !== undefined
          ? path(pathIndex, this.obsolescenceRates)
          : path(pathIndex, this.obsolescenceRatesAllContract)
    }
  }

  get checkPriceEnabled() {
    return (
      !this.checkingPrice &&
      this.furniture &&
      this.furniture.price !== 0 &&
      this.furniture.type === 'catalog' &&
      (this.furniture.dateOfPurchased ||
        (this.furniture.parentProduct && this.furniture.parentProduct.dateOfPurchased))
    )
  }

  setFurniture(furniture, context) {
    if (furniture.description === '' && (furniture.brand !== '' || furniture.model !== '')) {
      furniture.description = furniture.model
    }
    this.furniture = furniture
    this.context = context
  }

  setReplacementProduct(product) {
    product.setParentProduct(this.furniture)
    product.setProperty('dateOfPurchased', this.furniture.dateOfPurchased)
    product.setProperty('description', product.model)
    this.furniture = product
    this.showModalReplacementProducts = false
  }

  setProperty(key, value) {
    this[key] = value
  }

  async checkPrice() {
    this.checkingPrice = true
    try {
      const { furniture } = await checkPrice({
        wan: UserStore.isExpert ? MissionStore.id : ManagerClaimStore.id,
        productId: this.furniture.productId,
        dateOfPurchase: this.furniture.dateOfPurchased,
      })
      runInAction(() => {
        this.checkingPrice = false
        // Not commercialised anymore, have replacement product
        if (furniture.replacementProducts && furniture.replacementProducts.length) {
          this.showModalReplacementProducts = true
          this.replacementProducts = furniture.replacementProducts.map(
            (replacementProduct, index) => {
              let furnitureCatalog = new FurnitureCatalog(
                furnitureDataTransformer(replacementProduct),
              )
              if (!isNil(furniture.storeProducts[index])) {
                furnitureCatalog.setProperty('storeProducts', [furniture.storeProducts[index]])
              }

              return furnitureCatalog
            },
          )
        } else {
          // Still commercialised, modify price
          this.furniture.setProperty('priceWithVat', furniture.price) // furniture.price already have computed with VAT
          this.furniture.setProperty('storeProducts', furniture.storeProducts)
        }
      })
    } catch (err) {
      runInAction(() => {
        this.checkingPrice = false
      })
    }
  }
}

const DecoratedAddFurnitureCtrl = decorate(AddFurnitureCtrl, {
  furniture: observable,
  context: observable,
  showObsolescenceCalculator: observable,
  checkingPrice: observable,
  showModalReplacementProducts: observable,
  replacementProducts: observable,
  obsolescenceRates: observable,
  obsolescenceRatesAllContract: observable,
  obsolescenceRatesOp5Y: observable,
  obsolescenceRatesOpIllimite: observable,

  loadObsolescenceRate: action,
  setFurniture: action,
  setReplacementProduct: action.bound,
  setProperty: action,
  checkPrice: action.bound,

  checkPriceEnabled: computed,
})

export default new DecoratedAddFurnitureCtrl()
