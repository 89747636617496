import React from 'react'
import { observer, inject } from 'mobx-react'
import { compose } from 'recompose'

import FormLabel from './FormLabel'
import InputComponent from './InputComponent'
import AlertMessageInputPrice from './AlertMessageInputPrice'

const FormFields = ({
  groupId,
  formObj,
  focus,
  formObj: { inputs, withAccordion, saving, saved },
  MissionStore: { isMissionClosed, cfa },
}) => {
  return inputs
    .filter(input => input.groupId === groupId || !withAccordion)
    .map((input, index) => {
      if (!input.show) return null

      if (
        focus === input.name ||
        (index === 0 && saving === false && saved === false && !withAccordion)
      ) {
        input.autoFocus = true
      } else {
        input.autoFocus = false
      }

      if (isMissionClosed) input.disabled = true

      return (
        <div key={input.id} className="form-group row no-gutters">
          <FormLabel input={input} />

          <div className="col">
            <InputComponent input={input} formObj={formObj} />
          </div>

          <AlertMessageInputPrice input={input} cfa={cfa} />
        </div>
      )
    })
}

export default compose(
  inject('MissionStore'),
  observer,
)(FormFields)
