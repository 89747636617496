import { observable, action, runInAction, decorate } from 'mobx'
import { path } from 'ramda'
import i18next from 'i18next'
import { format } from 'date-fns'

import { fetchInboxMessages } from 'services/messaging'
import { concatNames } from 'utils'
import UserStore from 'stores/Common/domain/UserStore'

class InboxStore {
  messages = []
  loading = false
  currentPage = 1
  notifications = 0
  totalPages = 1
  insurerClaimId = ''
  insuredName = ''
  assigneeUserName = ''
  sender = ''
  sendAt = null
  wan = ''

  pageSize = 10

  get filters() {
    const filters = {
      'page[size]': this.pageSize,
      'filter[readAt]': null,
    }

    if (UserStore.modes.length > 1) {
      const mode = UserStore.mode
      if (mode === 'expert') {
        filters['filter[wanType]'] = 'CFA'
      } else if (mode === 'insurer') {
        filters['filter[wanType]'] = 'CFI'
      }
    }

    if (this.insurerClaimId.length > 0) {
      filters['filter[insurerClaimId][operation]'] = 'like'
      filters['filter[insurerClaimId][value]'] = this.insurerClaimId
    }

    if (this.insuredName.length > 0) {
      filters['filter[insuredName][operation]'] = 'like'
      filters['filter[insuredName][value]'] = this.insuredName
    }

    if (this.assigneeUserName.length > 0) {
      filters['filter[assigneeUserName][operation]'] = 'like'
      filters['filter[assigneeUserName][value]'] = this.assigneeUserName
    }

    if (this.sender.length > 0) {
      filters['filter[senderUserName][operation]'] = 'like'
      filters['filter[senderUserName][value]'] = this.sender
    }

    if (this.sendAt) {
      filters['filter[createdAt][operation]'] = 'between'
      filters['filter[createdAt][min]'] = format(this.sendAt, 'y-MM-dd 00:00')
      filters['filter[createdAt][max]'] = format(this.sendAt, 'y-MM-dd 23:59:59')
    }

    if (this.wan.length > 0) {
      filters['filter[wanDest][operation]'] = 'like'
      filters['filter[wanDest][value]'] = this.wan
    }

    return filters
  }

  fetchInbox = async (page = 1) => {
    this.loading = true

    try {
      const { meta, messages } = await fetchInboxMessages(this.filters, page)

      runInAction(() => {
        this.notifications = meta['total-count']
        this.totalPages = meta['total-pages']
        this.messages = messages.map(message => {
          const sender =
            concatNames(
              path(['senderUser', 'firstName'], message),
              path(['senderUser', 'lastName'], message),
            ) || i18next.t('messaging.message.darva')

          return {
            id: message.id,
            text: message.text,
            sender,
            sendAt: new Date(message.createdAt),
            wanDest: message.wanDest,
            wanSource: message.wanSource,
            attachment: path(['documents', 'length'], message),
            type: message.type,
            wanType: message.wanType,
            isUrgent: message.isUrgent,
            insurerClaimId: message.insurerClaimId,
            insuredName: message.insuredName,
            assigneeUserName: message.assigneeUserName,
            isUrgentMission: message.isUrgentMission,
          }
        })
      })
    } catch (err) {
      console.log(err)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  setProperty = (key, value) => {
    this[key] = value
  }

  markAsReadNotification() {
    this.notifications = this.notifications - 1
  }

  goToPage = page => {
    this.currentPage = page
  }
}

const DecoratedInboxStore = decorate(InboxStore, {
  messages: observable,
  loading: observable,
  currentPage: observable,
  notifications: observable,
  totalPages: observable,

  // FILTERS
  insurerClaimId: observable,
  insuredName: observable,
  assigneeUserName: observable,
  sender: observable,
  sendAt: observable,
  wan: observable,

  goToPage: action,
  fetchInbox: action,
  setProperty: action.bound,
  markAsReadNotification: action,
})

export default new DecoratedInboxStore()
