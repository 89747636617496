import React, { Fragment, useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { compose } from 'recompose'

import { useParams } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import ManagerClaimStore from 'stores/ManagerClaim/domain/ManagerClaimStore'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import Loader from 'components/Loader/Loader'
import Form from 'components/DynForm/Form'
import Button from 'components/Button/Button'

const PendingModal = ({
  ManagerClaimStore: {
    loadPendingForm,
    pendingForm,
    savePendingForm,
    showPendingModal,
    setProperty,
    isClaimClosed,
  },
  t,
}) => {
  const { id } = useParams()

  useEffect(() => {
    if (showPendingModal) {
      loadPendingForm(id)
    }
  }, [loadPendingForm, id, showPendingModal])

  const closeModal = () => {
    setProperty('showPendingModal', false)
    setProperty('pendingForm', null)
  }
  if (isClaimClosed) return null
  return (
    <Fragment>
      <ModalWithBackdrop show={showPendingModal} size="modal-xl" close={closeModal}>
        <div className="modal-header">
          <h5 className="modal-title">{t('managerClaim.sideNav.pending')}</h5>
          <button type="button" className="close" onClick={closeModal}>
            <span>&times;</span>
          </button>
        </div>

        <div className="modal-body">
          {!pendingForm && <Loader />}
          {pendingForm && (
            <Form formObj={pendingForm} saveService={savePendingForm.bind(this, id)} />
          )}
        </div>
      </ModalWithBackdrop>

      <div className="mt-4 d-flex justify-content-between bg-white">
        <Button
          className="btn btn-outline-primary btn-block"
          onClick={() => ManagerClaimStore.setProperty('showPendingModal', true)}
        >
          {t('managerClaim.sideNav.pending')} <i className="fa fa-pause" />
        </Button>
      </div>
    </Fragment>
  )
}

export default compose(
  inject('ManagerClaimStore'),
  withTranslation(),
  observer,
)(PendingModal)
