import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { prop, path } from 'ramda'

// import './Information.css'
import CardLayout from 'components/CardLayout/CardLayout'
import { formatWan, formatDate, concatAddress } from 'utils'
import Loader from 'components/Loader/Loader'

class InformationWorkCFA extends React.Component {
  render() {
    const {
      t,
      MissionStore: {
        cfa,
        claim,
        information,
        insuredInformation,
        claimantInformation,
        loading,
        assignerCustomer,
        externalId,
      },
    } = this.props

    if (loading) return <Loader />

    let lossAddress = ''
    if (claim.addressOfLoss) {
      lossAddress += concatAddress({
        streetNumber: claim.addressOfLoss.streetNumber,
        addressLine1: claim.addressOfLoss.addressLine1,
        addressLine2: claim.addressOfLoss.addressLine2,
        addressLine3: null,
        zipCode: claim.addressOfLoss.zipCode,
        city: claim.addressOfLoss.city,
      })
    }

    const riskType = path(['cfi', 'claimInformation', 'riskType', 'value'], cfa)

    return (
      <CardLayout className="information">
        <div className="row">
          <div className="col-4 col-sm-3 col-md-3 col-lg-3">
            {t('mission.information.expertiseType')}
          </div>
          <div className="col-8 col-sm-9 col-md-9 col-lg-9">
            {cfa.expertiseType ? cfa.expertiseType.value : '-'}
          </div>
        </div>
        <div className="row">
          <div className="col-4 col-sm-3 col-md-3 col-lg-3">Sinistré</div>
          <div className="col-8 col-sm-9 col-md-9 col-lg-9">
            {claimantInformation.claimant.firstName} {claimantInformation.claimant.lastName}
          </div>
        </div>
        <div className="row">
          <div className="col-4 col-sm-3 col-md-3 col-lg-3">{t('mission.claim.coverage')}</div>
          <div className="col-8 col-sm-9 col-md-9 col-lg-9">
            {claim.coverage && claim.coverage.value ? claim.coverage.value : '-'}
          </div>
        </div>
        <div className="row">
          <div className="col-4 col-sm-3 col-md-3 col-lg-3">{t('mission.claim.lossDate')}</div>
          <div className="col-8 col-sm-9 col-md-9 col-lg-9">{formatDate(claim.dateOfLoss)}</div>
        </div>
        <div className="row">
          <div className="col-4 col-sm-3 col-md-3 col-lg-3">{t('mission.information.address')}</div>
          <div className="col-8 col-sm-9 col-md-9 col-lg-9">{lossAddress}</div>
        </div>
        <div className="divider" />
        <div className="row">
          <div className="col-4 col-sm-3 col-md-3 col-lg-3">
            {t('mission.information.missionCreatedAt')}
          </div>
          <div className="col-8 col-sm-9 col-md-9 col-lg-9">
            {formatDate(information.createdAt)}
          </div>
        </div>
        <div className="row">
          <div className="col-4 col-sm-3 col-md-3 col-lg-3">
            {t('mission.information.missionId')}
          </div>
          <div className="col-8 col-sm-9 col-md-9 col-lg-9">{formatWan(information.missionId)}</div>
        </div>
        <div className="row">
          <div className="col-4 col-sm-3 col-md-3 col-lg-3">
            {t('mission.information.externalId')}
          </div>
          <div className="col-8 col-sm-9 col-md-9 col-lg-9">{externalId}</div>
        </div>
        <div className="row">
          <div className="col-4 col-sm-3 col-md-3 col-lg-3">
            {t('mission.information.missionStatus')}
          </div>
          <div className="col-8 col-sm-9 col-md-9 col-lg-9">{information.missionStatus}</div>
        </div>
        <div className="row">
          <div className="col-4 col-sm-3 col-md-3 col-lg-3">
            {t('mission.information.missionType')}
          </div>
          <div className="col-8 col-sm-9 col-md-9 col-lg-9">
            {path(['missionInsurerInformation', 'missionType', 'value'], information) || '-'}
          </div>
        </div>

        {riskType && (
          <div className="row">
            <div className="col-4 col-sm-3 col-md-3 col-lg-3">
              {t('mission.information.riskType')}
            </div>
            <div className="col-8 col-sm-9 col-md-9 col-lg-9">{riskType}</div>
          </div>
        )}

        <div className="divider" />
        <div className="row">
          <div className="col-4 col-sm-3 col-md-3 col-lg-3">
            {t('mission.information.insuredName')}
          </div>
          <div className="col-8 col-sm-9 col-md-9 col-lg-9">
            {insuredInformation.insured.firstName} {insuredInformation.insured.lastName}
          </div>
        </div>
        <div className="row">
          <div className="col-4 col-sm-3 col-md-3 col-lg-3">
            {t('mission.information.insurerName')}
          </div>
          <div className="col-8 col-sm-9 col-md-9 col-lg-9">
            {prop('name', assignerCustomer) || '-'}
          </div>
        </div>
      </CardLayout>
    )
  }
}

export default inject('MissionStore')(withTranslation()(observer(InformationWorkCFA)))
