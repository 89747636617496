import { observable, computed, action, decorate, runInAction } from 'mobx'
import { isNil, path } from 'ramda'
import i18next from 'i18next'

import { fetchConclusion, saveConclusion } from 'services/missionForm'
import { getApplicableConvention } from 'services/claim'
import Form from 'utils/dynform/Form'
import CartStore from 'stores/Mission/domain/CartStore'
import MissionStore from 'stores/Mission/domain/MissionStore'

class ConclusionCtrl {
  loading = true
  applicableConventionLoading = false
  form = null

  get isFranchiseMustBeDeducted() {
    const inputs = path(['inputs'], this.form)
    if (inputs) {
      const isFranchiseMustBeDeducted = inputs.find(
        input => input.name === 'isFranchiseMustBeDeducted',
      )

      return isNil(path(['value'], isFranchiseMustBeDeducted))
        ? false
        : path(['value'], isFranchiseMustBeDeducted)
    }

    return false
  }

  get franchiseMustBeDeducted() {
    const inputs = path(['inputs'], this.form)
    if (inputs) {
      const franchiseMustBeDeducted = inputs.find(input => input.name === 'franchiseMustBeDeducted')

      if (franchiseMustBeDeducted && franchiseMustBeDeducted.value.length > 0) {
        return franchiseMustBeDeducted.value
      }
    }

    return null
  }

  get franchiseMustNotBeDeducted() {
    const inputs = path(['inputs'], this.form)
    if (inputs) {
      const franchiseMustNotBeDeducted = inputs.find(
        input => input.name === 'franchiseMustNotBeDeducted',
      )

      if (franchiseMustNotBeDeducted && franchiseMustNotBeDeducted.value.length > 0) {
        return franchiseMustNotBeDeducted.value
      }
    }

    return null
  }

  async loadData(wan, data) {
    this.loading = true
    try {
      const form = await fetchConclusion(wan)
      form.name = 'conclusion'
      runInAction(() => {
        this.form = new Form({ form, data })
        this.form.setProperty('saved', true)
        this.loading = false
      })
    } catch (error) {
      console.error(error)
    }
  }

  async save(wan, formData) {
    try {
      await saveConclusion(wan, formData)
      if (CartStore.settlementsChanged || CartStore.precisionsChanged) {
        await CartStore.save()
        runInAction(() => {
          if (CartStore.settlementsChanged)
            CartStore.setOriginalData('originalSettlements', 'exposeSettlements')
          if (CartStore.precisionsChanged)
            CartStore.setOriginalData('originalPrecisions', 'exposePrecisions')
        })
      }
    } catch (error) {
      throw error
    }
  }

  async getApplicableConvention() {
    try {
      this.applicableConventionLoading = true
      const result = await getApplicableConvention(MissionStore.cfa.wan)
      const applicableConventionData = {
        header: `${i18next.t('convention.applicableConvention')}`,
        messages: [
          `${i18next.t('convention.applicableConvention')}: ${
            result.data.attributes.conventionKey
          }`,
          `${i18next.t('convention.insurerHandler')}: ${
            result.data.attributes.insurerHandler ? i18next.t('common.yes') : i18next.t('common.no')
          }`,
        ],
        success: true,
        openModal: true,
      }
      MissionStore.setProperty('applicableConvention', applicableConventionData)
    } catch (error) {
      console.error(error)
    } finally {
      runInAction(() => {
        this.applicableConventionLoading = false
      })
    }
  }
}

const DecoratedConclusionCtrl = decorate(ConclusionCtrl, {
  loading: observable,
  applicableConventionLoading: observable,
  form: observable,

  isFranchiseMustBeDeducted: computed,
  franchiseMustBeDeducted: computed,
  franchiseMustNotBeDeducted: computed,

  loadData: action,
  save: action,
  getApplicableConvention: action.bound,
})

export default new DecoratedConclusionCtrl()
