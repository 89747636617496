import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import PropertyEmbellishmentCtrl from 'stores/Mission/EasyEstimation/PropertyEmbellishmentCtrl'
import SearchPackageCtrl from 'stores/Mission/view/SearchPackageCtrl'
import Button from 'components/Button/Button'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'

const RoomModal = ({ showNewForm, setShowNewForm }) => {
  const { t } = useTranslation()
  const [roomType, setRoomType] = useState('I')
  const [room, setRoom] = useState('')
  const { showRoomModal, addNewRoom, setProperty } = PropertyEmbellishmentCtrl
  const { missionRoomsOptionsWithLocation } = SearchPackageCtrl
  const options = missionRoomsOptionsWithLocation.filter(room => room.location === roomType)

  const closeModal = () => {
    setProperty('showRoomModal', !showRoomModal)
  }

  return (
    <ModalWithBackdrop show={showRoomModal} size="modal-md" onClose={closeModal}>
      <div className="modal-header d-flex justify-content-between">
        <h5 className="modal-title">Ajouter une localisation</h5>

        <i className="fa fa-close clickable" onClick={closeModal} />
      </div>

      <div className="modal-body container">
        <div className="btn-group mb-3">
          <button
            type="button"
            className={classNames('btn', {
              'btn-primary': roomType === 'I',
              'btn-light': roomType !== 'I',
            })}
            onClick={() => {
              setRoomType('I')
              setRoom('')
            }}
          >
            Intérieur
          </button>
          <button
            type="button"
            className={classNames('btn', {
              'btn-primary': roomType === 'O',
              'btn-light': roomType !== 'O',
            })}
            onClick={() => {
              setRoomType('O')
              setRoom('')
            }}
          >
            Extérieur
          </button>
        </div>

        <ResponsiveSelect
          name="room"
          isSearchable
          value={room}
          onChange={e => setRoom(e.target.value)}
          options={options}
        />
      </div>

      <div className="modal-footer pb-2 pt-2">
        <button className="btn btn-secondary" onClick={closeModal}>
          {t('common.cancel')}
        </button>

        <Button
          className="btn btn-success"
          disabled={room.length === 0}
          onClick={() => {
            const roomData = SearchPackageCtrl.missionRooms.find(roomData => roomData.cde === room)
            addNewRoom(roomData)
            closeModal()
            if (!showNewForm) setShowNewForm(true)
          }}
        >
          {t('common.add')}
        </Button>
      </div>
    </ModalWithBackdrop>
  )
}

export default observer(RoomModal)
