import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import './Maintenance.css'
import logo from 'assets/sps-logo.svg'

const Maintenance = () => {
  const { t } = useTranslation()
  useEffect(() => {
    document.body.classList.add('maintenance')
    return () => document.body.classList.remove('maintenance')
  }, [])

  return (
    <div className="maintenance">
      <img height="50" width="120" src={logo} alt="Logo SPS" className="mt-3 ml-2" />
      <h1 className="ml-3">{t('maintenance.title')}</h1>
    </div>
  )
}

export default Maintenance
