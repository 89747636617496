import { observable, computed, action, decorate, runInAction } from 'mobx'
import { path } from 'ramda'
import i18next from 'i18next'

import UiCtrl from 'stores/Common/view/UiCtrl'
import Message from 'stores/Messaging/Message'
import UserStore from 'stores/Common/domain/UserStore'
import ClaimStore from 'stores/Claim/domain/ClaimStore'
import MissionStore from 'stores/Mission/domain/MissionStore'
import AlertCtrl from 'stores/Common/view/AlertCtrl'
import { fetchMessages, fetchContexts, postMessage, fetchNotifications } from 'services/messaging'

class MessagingStore {
  loading = false
  sendingMessage = false
  messages = []
  contexts = []
  currentMessage = new Message()
  currentContext = ''
  showSDModal = false
  loadingMessages = false
  wan = null
  notifications = 0

  get contextsAsOptions() {
    const { language } = UiCtrl

    return this.contexts.map(context => ({
      value: context.id,
      label: context[language[0] + language[1]],
    }))
  }

  get validMessage() {
    return !!(this.currentContext && this.currentMessage.text)
  }

  get sendFromExpert() {
    switch (this.currentContext) {
      case 'sendToPole':
        return {
          ...this.currentMessage.asJson,
          context: this.currentContext,
          wanSource: path(['cfa', 'wan'], MissionStore),
          wanDest: path(['cfa', 'cfi', 'wan'], MissionStore),
          customerIdDest: path(['cfa', 'cfi', 'assigneeCustomerId'], MissionStore),
        }
      case 'sendToManagement':
        return {
          ...this.currentMessage.asJson,
          context: this.currentContext,
          wanSource: path(['cfa', 'wan'], MissionStore),
          wanDest: path(['cfa', 'wan'], MissionStore),
          customerIdDest: path(['customer', 'id'], UserStore),
        }
      default:
        return null
    }
  }

  get sendFromManager() {
    switch (this.currentContext) {
      case 'sendToExpert':
        return {
          ...this.currentMessage.asJson,
          context: this.currentContext,
          receiverUser: path(['claim', 'mission', 'assigneeUser', 'id'], ClaimStore),
          wanSource: path(['claim', 'wan'], ClaimStore),
          wanDest: path(['claim', 'mission', 'cfaWan'], ClaimStore)
            ? path(['claim', 'mission', 'cfaWan'], ClaimStore)
            : path(['claim', 'wan'], ClaimStore),
          customerIdDest: path(['claim', 'mission', 'assigneeCustomerId'], ClaimStore),
        }
      case 'sendToSecretary':
        return {
          ...this.currentMessage.asJson,
          context: this.currentContext,
          wanSource: path(['claim', 'wan'], ClaimStore),
          wanDest: path(['claim', 'wan'], ClaimStore),
          customerIdDest: path(['claim', 'mission', 'assigneeCustomerId'], ClaimStore)
            ? path(['claim', 'mission', 'assigneeCustomerId'], ClaimStore)
            : path(['claim', 'assigneeCustomerId'], ClaimStore),
        }
      case 'sendToManagement':
        return {
          ...this.currentMessage.asJson,
          context: this.currentContext,
          wanSource: path(['claim', 'wan'], ClaimStore),
          wanDest: path(['claim', 'wan'], ClaimStore),
          customerIdDest: path(['customer', 'id'], UserStore),
        }
      default:
        return null
    }
  }

  get sendFromSecretary() {
    switch (this.currentContext) {
      case 'sendToExpert':
        return {
          ...this.currentMessage.asJson,
          context: this.currentContext,
          receiverUser: path(['claim', 'mission', 'assigneeUser', 'id'], ClaimStore),
          wanSource: path(['claim', 'wan'], ClaimStore),
          wanDest: path(['claim', 'mission', 'cfaWan'], ClaimStore),
          customerIdDest: path(['claim', 'mission', 'assigneeCustomerId'], ClaimStore),
        }
      case 'sendToManager':
        return {
          ...this.currentMessage.asJson,
          context: this.currentContext,
          wanSource: path(['claim', 'wan'], ClaimStore),
          wanDest: path(['claim', 'wan'], ClaimStore),
          customerIdDest: path(['claim', 'mission', 'assigneeCustomerId'], ClaimStore)
            ? path(['claim', 'mission', 'assigneeCustomerId'], ClaimStore)
            : path(['claim', 'assigneeCustomerId'], ClaimStore),
        }
      case 'sendToManagement':
        return {
          ...this.currentMessage.asJson,
          context: this.currentContext,
          wanSource: path(['claim', 'wan'], ClaimStore),
          wanDest: path(['claim', 'wan'], ClaimStore),
          customerIdDest: path(['customer', 'id'], UserStore),
        }
      default:
        return null
    }
  }

  async loadData(wan, cfiWan = null, isInspectionMission = false) {
    this.loading = true
    try {
      await Promise.all([
        this.fetchContexts(cfiWan, isInspectionMission),
        this.fetchNotifications(wan),
        this.fetchMessages(wan),
      ])
    } catch (err) {
      console.error(err)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  async fetchContexts(cfiWan = null, isInspectionMission = false) {
    try {
      const res = await fetchContexts(cfiWan, isInspectionMission)
      runInAction(() => {
        this.contexts = res
      })
    } catch (err) {
      console.error(err)
    }
  }

  async fetchMessages(wan) {
    this.loadingMessages = true
    this.wan = wan
    try {
      const res = await fetchMessages({ wan, filter: null })
      runInAction(() => {
        this.loadingMessages = false
        this.messages = res.data.map(data => new Message(data))
      })
    } catch (err) {
      console.error(err)
    }
  }

  async fetchNotifications(wan) {
    this.wan = wan
    try {
      const res = await fetchNotifications({ wan })
      runInAction(() => {
        this.notifications = res.meta['total-count']
      })
    } catch (err) {
      console.error(err)
    }
  }

  sendMessage = async () => {
    this.sendingMessage = true
    try {
      const body =
        UserStore.mode === 'expert'
          ? this.sendFromExpert
          : UserStore.isManager
          ? this.sendFromManager
          : this.sendFromSecretary
      if (body) {
        await postMessage(this.currentContext, body)
        this.fetchMessages(this.wan)
        runInAction(() => (this.currentMessage = new Message()))
      } else {
        console.warn(`Something went wrong with your context ${this.currentContext}`)
        AlertCtrl.alert(
          'warning',
          i18next.t('messaging.form.contextError', { context: this.currentContext }),
        )
      }
    } catch (err) {
      console.error(err)
    } finally {
      runInAction(() => (this.sendingMessage = false))
    }
  }

  setProperty(key, value) {
    this[key] = value
  }

  markAsReadNotification() {
    this.notifications = this.notifications - 1
  }

  closeModal() {
    this.showSDModal = !this.showSDModal
    this.currentMessage.setProperty('selectingDocuments', [])
  }

  confirmSelection() {
    this.showSDModal = !this.showSDModal
    this.currentMessage.setProperty('documents', this.currentMessage.selectingDocuments)
    this.currentMessage.setProperty('selectingDocuments', [])
  }

  resetCurrentMessage() {
    this.currentMessage = new Message()
    this.currentContext = ''
  }
}

const decoratedMessagingStore = decorate(MessagingStore, {
  loading: observable,
  sendingMessage: observable,
  loadingMessages: observable,
  messages: observable,
  contexts: observable,
  currentContext: observable,
  showSDModal: observable,
  wan: observable,
  notifications: observable,
  currentMessage: observable,

  contextsAsOptions: computed,
  validMessage: computed,
  sendFromExpert: computed,
  sendFromManager: computed,
  sendFromSecretary: computed,

  loadData: action,
  fetchContexts: action.bound,
  fetchMessages: action.bound,
  fetchNotifications: action.bound,
  sendMessage: action.bound,
  setProperty: action.bound,
  closeModal: action.bound,
  confirmSelection: action.bound,
  resetCurrentMessage: action.bound,
  markAsReadNotification: action,
})

export default new decoratedMessagingStore()
