import { observable, computed, action, runInAction, decorate } from 'mobx'
// import i18next from 'i18next'

import { searchPackage, fetchRoomList, toggleFavoritePackages } from 'services/calculation'
import UiCtrl from 'stores/Common/view/UiCtrl'
import Package from 'stores/Mission/domain/Package'
import UserStore from 'stores/Common/domain/UserStore'
import MissionStore from 'stores/Mission/domain/MissionStore'
import ManagerClaimStore from 'stores/ManagerClaim/domain/ManagerClaimStore'
import CartStore from 'stores/Mission/domain/CartStore'

class SearchPackageCtrl {
  priceType = 'catalog'
  packageType = 'DAT_PROP'
  roomType = 'selectFilter'
  search = ''
  list = []
  activeSearch = false
  customRoom = ''
  customRoomIndex = 0
  searching = false
  missionRooms = []
  selectRoomError = false
  totalSearchPackage = 0
  coverage = ''
  favoritePackages = []
  toggleFavoriteInProgress = []

  get missionRoomsOptions() {
    const missionRooms = this.missionRooms.map(room => ({ value: room.cde, label: room.label }))

    CartStore.overrideRoomsLabel.forEach(overrideRoomLabel =>
      missionRooms.push({
        value: overrideRoomLabel.cartRoomType.override.key,
        label: overrideRoomLabel.cartRoomType.override.display,
      }),
    )

    missionRooms.push({
      value: 'noFilter',
      label: UiCtrl.language === 'fr-FR' ? 'Sans filtre' : 'No filter',
    })

    missionRooms.unshift({
      value: 'selectFilter',
      label: UiCtrl.language === 'fr-FR' ? 'Selectionnez...' : 'Select...',
    })

    return missionRooms
  }

  get missionRoomsOptionsWithAreaSize() {
    return this.missionRooms.map(room => ({
      value: room.cde,
      label: room.label,
      areaSize: room.areaSize,
    }))
  }

  get missionRoomsOptionsWithLocation() {
    return this.missionRooms.map(room => ({
      value: room.cde,
      label: room.label,
      location: room.location,
    }))
  }

  setProperty(key, value) {
    if (this.selectRoomError && key === 'roomType') this.selectRoomError = false
    this[key] = value
    if (key !== 'search' && this.search !== '' && value !== '') {
      const id = UserStore.isExpert ? MissionStore.id : ManagerClaimStore.id
      this.fetchSearchPackage(id)
    }
  }

  resetSearchContext(packageType) {
    this.packageType = packageType
    this.search = ''
    this.searching = false
    this.activeSearch = false
    this.roomType = 'noFilter'
    this.list = []
  }

  async loadMissionRooms(wan) {
    try {
      const res = await fetchRoomList({ wan })
      runInAction(() => {
        this.missionRooms = res.map(room => {
          room.areaSize =
            room.roomSizes && room.roomSizes.length > 0 && room.roomSizes[0].area
              ? parseFloat(room.roomSizes[0].area)
              : 10
          return room
        })
      })
    } catch (error) {
      console.log(error)
    }
  }

  fetchSearchPackage(wan, showMore = false, location = 'regular') {
    this.searching = true
    this.activeSearch = true
    this.list = []

    const searchData = {
      coverage: this.coverage === 'noFilter' ? null : this.coverage,
      keyword: this.search,
      priceList: this.priceType === 'catalog' ? null : this.priceType,
      damageType: this.packageType === 'noFilter' ? null : this.packageType,
      showMore,
    }

    if (this.roomType === 'selectFilter' || this.roomType === null) {
      this.selectRoomError = true
      this.searching = false
      this.activeSearch = true
      return
    } else if (this.roomType !== 'default' && this.roomType !== 'customRoom') {
      let searchRoomType = this.roomType.split('_')[0]
      if (searchRoomType === 'noFilter') {
        searchData.room = null
      } else {
        searchData.room = searchRoomType
      }
    }

    searchPackage(wan, searchData)
      .then(
        action(res => {
          this.list = res.packages.map(packageData => {
            const roomType = {}
            if (this.roomType === '' || this.roomType === null) {
              const room = this.missionRooms[0]
              roomType.key = room.cde
              roomType.label = room.label
              roomType.areaSize = room.areaSize
            } else if (this.roomType.split('_')[0] === 'noFilter') {
              roomType.key = 'noFilter'
              roomType.label = UiCtrl.language === 'fr-FR' ? 'Autre' : 'Other'
              roomType.areaSize = 10
            } else {
              const room = this.missionRooms.find(room => room.cde === this.roomType.split('_')[0])

              if (room) {
                roomType.key = room.cde
                roomType.label = room.label
                roomType.areaSize = room.areaSize
              } else {
                const existingPackage = CartStore.packages.find(
                  packageData => packageData.roomType.key === this.roomType.split('_')[0],
                )
                if (existingPackage) {
                  roomType.key = existingPackage.roomType.key
                  roomType.label = existingPackage.roomType.label
                  roomType.areaSize = existingPackage.roomType.areaSize
                } else console.warn(`Room id ${this.roomType} do not exist`)
              }
            }

            // ADD DEFAULT INVOLVED IF IRSI
            if (MissionStore.isIRSI) {
              packageData.involvedParty = MissionStore.selectedIRSICart
            }

            const newPackage = new Package({
              data: { ...packageData, location },
              fromList: 'listSearch',
              packageType:
                this.packageType === 'noFilter' || this.packageType === null
                  ? 'DAT_EMB'
                  : this.packageType,
              roomType,
              priceType: this.priceType,
            })

            if (this.roomType.split('_').length > 1) {
              //This split is use to get only separate the first cde then keep the rest of the string as a big one even with other '_'
              newPackage.setRoomTypeLabel(this.roomType.split(/_(.+)/)[1])
            }

            return newPackage
          })
          this.searching = false
          this.totalSearchPackage = res.totalSearchPackage
        }),
      )
      .catch(
        action(err => {
          console.error(err)
          this.list = []
          this.searching = false
        }),
      )
  }

  toggleFavoritePackages(packageId) {
    this.toggleFavoriteInProgress.push(packageId)
    toggleFavoritePackages(packageId)
      .then(
        action(data => {
          CartStore.setProperty('favoritePackages', data)
          this.toggleFavoriteInProgress = this.toggleFavoriteInProgress.filter(
            id => id !== packageId,
          )
        }),
      )
      .catch(
        action(err => {
          console.error(err)
          this.toggleFavoriteInProgress = this.toggleFavoriteInProgress.filter(
            id => id !== packageId,
          )
        }),
      )
  }

  packageIsFavorite(packageId) {
    return CartStore.favoritePackages.indexOf(packageId) !== -1
  }
}

const DecoratedSearchPackageCtrl = decorate(SearchPackageCtrl, {
  priceType: observable,
  packageType: observable,
  roomType: observable,
  search: observable,
  list: observable,
  activeSearch: observable,
  customRoom: observable,
  customRoomIndex: observable,
  searching: observable,
  missionRooms: observable,
  selectRoomError: observable,
  totalSearchPackage: observable,
  coverage: observable,
  toggleFavoriteInProgress: observable,

  setProperty: action,
  resetSearchContext: action,
  loadMissionRooms: action,
  fetchSearchPackage: action,
  toggleFavoritePackages: action,

  missionRoomsOptions: computed,
  missionRoomsOptionsWithAreaSize: computed,
  missionRoomsOptionsWithLocation: computed,
})

export default new DecoratedSearchPackageCtrl()
