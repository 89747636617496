import React from 'react'
import { inject, observer } from 'mobx-react'

import { euro } from 'utils/currency'
import TotalPriceWarning from './TotalPriceWarning'

const CompensatoryPackage = ({ type, trueTotal, CartStore: { compensatoryPackages } }) => {
  return (
    <React.Fragment>
      <td className="text-center align-middle">{euro(compensatoryPackages[type].value)}</td>

      <td className="text-center align-middle">
        <TotalPriceWarning total={compensatoryPackages[type].total} trueTotal={trueTotal} />
      </td>
    </React.Fragment>
  )
}

export default inject('CartStore')(observer(CompensatoryPackage))
