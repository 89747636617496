import React, { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { isNil } from 'ramda'
import classNames from 'classnames'

import PropertyEmbellishmentCtrl from 'stores/Mission/EasyEstimation/PropertyEmbellishmentCtrl'
import Button from 'components/Button/Button'
import CoveringForm from './CoveringForm'
import Covering from './Covering'
import WallModal from './WallModal'

const CurrentRoom = ({ showNewForm, setShowNewForm, store, CartStore: { packages } }) => {
  const [showWallModal, setShowWallModal] = useState(false)
  const {
    currentRoom,
    currentRCP,
    editCoveringForm,
    initRoomForm,
    selectedPart,
    firstTimeWall,
  } = PropertyEmbellishmentCtrl

  useEffect(() => {
    if (selectedPart === 'W' && firstTimeWall) {
      setShowWallModal(true)
    }
  }, [selectedPart, firstTimeWall])

  return (
    <React.Fragment>
      <WallModal show={showWallModal} setShow={setShowWallModal} />

      <div className={classNames('cart', { 'd-none': isNil(currentRoom) })}>
        {currentRCP.length > 0 && (
          <div className="mb-3">
            {currentRCP.map(rcpData => {
              const rcpPackages = packages.filter(data => data.groupId === rcpData.groupId)

              return (
                <Covering
                  key={`covering-${rcpData.groupId}`}
                  store={store}
                  rcpData={rcpData}
                  rcpPackages={rcpPackages}
                  packageData={rcpPackages[0]}
                  closeNewForm={() => setShowNewForm(false)}
                />
              )
            })}
          </div>
        )}

        <CoveringForm
          mode="new"
          store={store}
          show={showNewForm}
          closeForm={() => setShowNewForm(false)}
        />

        {!showNewForm && (
          <Button
            className="btn btn-primary"
            disabled={editCoveringForm !== null}
            onClick={() => {
              initRoomForm()
              setShowNewForm(true)
            }}
          >
            Ajouter une surface endommagée
          </Button>
        )}
      </div>
    </React.Fragment>
  )
}

export default inject('CartStore')(observer(CurrentRoom))
