import { observable, computed, action, decorate } from 'mobx'

import AbstractField from 'utils/dynform/AbstractField'

class InvolvedPartyType extends AbstractField {
  errors = []

  expanded = false

  constructor(data) {
    super(data)

    if (data.value) this.value = data.value
    else if (data.default) this.value = data.default
    else this.value = ''

    this.show = !(data.show === false)
    this.originalValue = this.value

    this.involvedPartyFilter = data.involvedPartyFilter
  }

  get changed() {
    return this.value !== this.originalValue
  }

  setOriginalData = () => {
    this.value = this.originalValue
  }
}

const DecoratedInvolvedPartyType = decorate(InvolvedPartyType, {
  value: observable,
  originalValue: observable,
  errors: observable,
  show: observable,

  setOriginalData: action,
  setPartyInvolved: action,

  changed: computed,
})

export default DecoratedInvolvedPartyType
