import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withTranslation } from 'react-i18next'

import './Forms.css'

const noop = value => value

class Select extends React.Component {
  renderPlaceholder() {
    const { placeholder } = this.props
    if (placeholder) {
      return (
        <option key="placeholder" value="">
          {placeholder}
        </option>
      )
    }
  }

  generateOptions(options) {
    return Object.keys(options).map(option => ({
      label: options[option],
      value: option,
    }))
  }

  generateGroups(groups) {
    return Object.keys(groups).map(group => ({
      label: groups[group].label,
      options: groups[group].options,
      disabled: groups[group].disabled,
    }))
  }

  renderOptions(options) {
    const myoptions = this.generateOptions(options)
    return myoptions.map(({ label, value }) => (
      <option key={value} value={value}>
        {label}
      </option>
    ))
  }

  renderGroups(groups) {
    let result = []
    result.push(this.renderPlaceholder())

    groups &&
      typeof groups[Object.keys(groups)[0]] === 'object' &&
      this.generateGroups(groups).forEach(({ label, options, disabled }) =>
        result.push(
          <optgroup key={label} disabled={disabled} label={label}>
            {this.renderOptions(options)}
          </optgroup>,
        ),
      )
    groups &&
      typeof groups[Object.keys(groups)[0]] === 'string' &&
      result.push(this.renderOptions(groups))
    return result
  }

  // pattern option { value: 'label'}
  render() {
    const {
      autoComplete,
      className,
      disabled,
      errors,
      name,
      options,
      onBlur,
      onChange,
      onFocus,
      placeholder,
      required,
      value,
      t,
    } = this.props

    return [
      <select
        key={`select-${name}`}
        autoComplete={autoComplete}
        className={classNames(className, { error: errors && !!errors.length, required })}
        disabled={disabled}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        required={required}
        value={value || ''}
      >
        {placeholder && !options && this.renderPlaceholder()}
        {options && this.renderGroups(options)}
      </select>,
      errors && !!errors.length && (
        <div key={'error-block'} className="row">
          <div className="error-message col-sm-12">
            {errors.map(errorDescription => (
              <div key={`${errorDescription}`}>{t(errorDescription)}</div>
            ))}
          </div>
        </div>
      ),
    ]
  }
}

Select.defaultProps = {
  name: 'selectName',
  onChange: noop,
}

Select.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  options: PropTypes.object,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
}

export default withTranslation()(Select)
