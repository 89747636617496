import React from 'react'
import { inject, observer } from 'mobx-react'

import UserDropdown from './UserDropdown'
import LanguageSection from './LanguageSection'
import MissionLinks from './MissionLinks'
import ClaimLinks from './ClaimLinks'

class DesktopMenu extends React.Component {
  render() {
    const {
      onChangeLanguage,
      UserStore: { fullName, isExpert, mode, modes },
      UiCtrl: { language },
    } = this.props

    return (
      <div>
        {fullName ? (
          <ul className="navbar-nav navigation ml-auto">
            {isExpert ? <MissionLinks /> : <ClaimLinks />}
            <span className="divider" />

            <li className="nav-item dropdown">
              <UserDropdown {...{ fullName, onChangeLanguage, mode, modes }} />
            </li>
          </ul>
        ) : (
          <LanguageSection onChangeLanguage={onChangeLanguage} language={language} />
        )}
      </div>
    )
  }
}

export default inject('UiCtrl', 'UserStore')(observer(DesktopMenu))
