import request from 'services/httpRequest'
import UploadCtrl from 'stores/Common/view/UploadCtrl'
import { slugify } from 'utils'
import { isImage, compressImage } from 'utils/compressImage'

export const fetchSupportingDocuments = ({ wan, url }) => {
  return request({
    method: 'get',
    url: `${url}/${wan}/documents`,
    params: {
      'page[size]': 'all',
    },
  })
    .then(res => {
      return res
    })
    .catch(err => {
      if (err) return Promise.reject(err)
    })
}

export const fetchSupportingDocumentsTypes = (wan, type) => {
  let url = ''
  if (type === 'claim') {
    url = `/claimFiles/v1/${wan}/documentTypes`
  } else if (type === 'managerClaim') {
    url = `/claimFiles/v1/claimFileManager/${wan}/documentTypes`
  } else {
    url = `/claimFiles/v1/claimFileAssessor/${wan}/documentTypes`
  }

  return request({
    method: 'get',
    url,
    params: {
      'page[size]': 50,
    },
  })
    .then(res => {
      return res
    })
    .catch(err => {
      if (err) return Promise.reject(err)
    })
}

export const createSupportingDocuments = async ({
  url,
  name,
  type,
  file,
  index,
  extraData,
  noProgress = false,
}) => {
  try {
    let invoiceContext = !!extraData
    const data = new FormData()

    data.append('document[name]', name)
    data.append('document[type]', type.key)

    if (isImage(file.type)) {
      const compressedImage = await compressImage(file)
      data.append('document[file]', compressedImage, slugify(name))
    } else {
      data.append('document[file]', file, slugify(name))
    }

    if (extraData) {
      data.append('document[extraData]', JSON.stringify(extraData))
    }

    const res = await request({
      method: 'post',
      url,
      data,

      onUploadProgress: !noProgress
        ? progressEvent => {
            const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
            UploadCtrl.setProgressBar(index, percentCompleted, invoiceContext)
          }
        : () => {},
    })
    return res
  } catch (error) {
    throw error
  }
}

export const createSchema = ({ url, name, type, file }) => {
  const data = new FormData()
  data.append('document[name]', name)
  data.append('document[type]', type)
  data.append('document[file]', file, slugify(name))

  return request({
    method: 'post',
    url,
    data,
  })
}

export const updateSupportingDocuments = (docID, name, type, extraData) => {
  return request({
    method: 'post',
    url: `/supportingDocuments/v1/${docID}/edit`,
    data: {
      data: {
        type: 'document',
        attributes: {
          name,
          type,
          extraData,
        },
      },
    },
  })
    .then(res => {
      return res
    })
    .catch(err => {
      if (err) return Promise.reject(err)
    })
}

export const deleteSupportingDocuments = (isInsurer, wan, docID) => {
  const url = isInsurer
    ? `/claimFiles/v1/${wan}/documents/${docID}`
    : `/claimFiles/v1/claimFileAssessor/${wan}/documents/${docID}`

  return request({
    method: 'delete',
    url,
  })
    .then(res => {
      return res
    })
    .catch(err => {
      if (err) return Promise.reject(err)
    })
}

export const addSdToTheReport = (cfaWan, sdIds) => {
  return request({
    method: 'post',
    url: `claimFiles/v1/claimFileAssessor/report/${cfaWan}/supporting-document/add`,
    data: {
      data: {
        type: 'supporting_documents',
        attributes: { ids: sdIds },
      },
    },
  })
}
