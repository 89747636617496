import { observable, action, computed, toJS, decorate } from 'mobx'
import { keys, path } from 'ramda'

import SupportingDocumentsCtrl, {
  SupportingDocTypes,
} from 'stores/Common/view/SupportingDocumentsCtrl'
import CommonStore from 'stores/Common/domain/CommonStore'

const typeColors = [
  '#e6194b',
  '#3cb44b',
  '#ffe119',
  '#0082c8',
  '#f58231',
  '#911eb4',
  '#46f0f0',
  '#f032e6',
  '#d2f53c',
  '#fabebe',
  '#008080',
  '#e6beff',
  '#aa6e28',
  '#fffac8',
  '#800000',
  '#aaffc3',
  '#808000',
  '#ffd8b1',
  '#000080',
  '#808080',
  '#000000',
  '#0000FF',
  '#00FF00',
  '#FFFF00',
]

class SupportingDocumentClass {
  // DATA
  id = null
  canDelete = null
  mimeType = null

  name = null
  type = null
  file = null
  extraData = null

  constructor(data) {
    if (data) Object.assign(this, data)
  }

  setProperty(key, value) {
    this[key] = value
  }

  setType(value) {
    this['type']['key'] = value
  }

  setExtraData(typeOfExtraData, key, value) {
    this.extraData[typeOfExtraData][key] = value
  }

  selectSupportingDocument() {
    this.selected = !this.selected
  }
}

export const SupportingDocument = decorate(SupportingDocumentClass, {
  name: observable,
  type: observable,
  file: observable,
  extraData: observable,

  setProperty: action,
  setType: action,
  setExtraData: action,
  selectSupportingDocument: action,
})

class SupportingDocumentStore {
  supportingDocuments = []

  get totalSd() {
    return this.supportingDocuments.length
  }

  get sdByPhotos() {
    return this.supportingDocuments.filter(
      sd => path(['type', 'category'], sd) === SupportingDocTypes.PHOTO,
    )
  }

  get sdByDocuments() {
    return this.supportingDocuments.filter(
      sd => path(['type', 'category'], sd) === SupportingDocTypes.DOCUMENT,
    )
  }

  get sdBySchemas() {
    return this.supportingDocuments.filter(
      sd => path(['type', 'category'], sd) === SupportingDocTypes.SCHEMA,
    )
  }

  get sdByInvoices() {
    return this.supportingDocuments.filter(
      sd => path(['type', 'category'], sd) === SupportingDocTypes.INVOICE,
    )
  }

  get sdByTypes() {
    return this.supportingDocuments.filter(
      sd => path(['type', 'key'], sd) === SupportingDocumentsCtrl.filter,
    )
  }

  get sdBySelected() {
    return this.supportingDocuments.filter(
      sd => path(['type', 'key'], sd) === SupportingDocumentsCtrl.filter,
    )
  }

  get sdForPackage() {
    // OLD FILTER FOR SD PACKAGES DON'T REMEMBER WHY
    // return this.supportingDocuments.filter(sd =>
    //   ['IINV', 'IQUO', 'PDMO'].includes(path(['type', 'key'], sd)),
    // )
    return this.supportingDocuments.map(sd => sd)
  }

  get typeKeysFilterList() {
    const typeFilterColors = this.setFilterColors()

    const sdByCurrentCategory = this.supportingDocuments.filter(
      sd => path(['type', 'category'], sd) === SupportingDocumentsCtrl.sdCategory,
    )
    return toJS(sdByCurrentCategory).reduce((obj, current, index) => {
      if (obj[current.type.key]) {
        obj[current.type.key]['count'].push(current.id)
      } else {
        obj[current.type.key] = {
          name: current.type.name,
          count: [current.id],
          color: typeFilterColors[current.type.key],
        }
      }
      return obj
    }, {})
  }

  // Object returned {typeKey: {name: 'autre', count : ['id1', 'id2'] }}
  get sdByTypeKeys() {
    return toJS(this.sdFiltered).reduce((obj, current, index) => {
      obj[current.type.key]
        ? obj[current.type.key]['count'].push(current.id)
        : (obj[current.type.key] = { name: current.type.name, count: [current.id] })
      return obj
    }, {})
  }

  get sdFiltered() {
    if (
      [
        SupportingDocTypes.PHOTO,
        SupportingDocTypes.DOCUMENT,
        SupportingDocTypes.SCHEMA,
        SupportingDocTypes.INVOICE,
      ].indexOf(SupportingDocumentsCtrl.filter) === -1
    )
      return this.sdByTypes
    const filter = {
      CPHO: this.sdByPhotos,
      CDOC: this.sdByDocuments,
      CINV: this.sdByInvoices,
      CSCH: this.sdBySchemas,
    }
    return filter[SupportingDocumentsCtrl.filter]
  }

  setFilterColors() {
    const sdTypes = keys(CommonStore.supportingDocumentsTypes)
    let index = 0
    return sdTypes.reduce((obj, mainType) => {
      Object.keys(CommonStore.supportingDocumentsTypes[mainType]).forEach(type => {
        obj[type] = typeColors[index++]
      })
      return obj
    }, {})
  }
}

const DecoratedSupportingDocumentStore = decorate(SupportingDocumentStore, {
  supportingDocuments: observable,

  sdFiltered: computed,
  sdByTypeKeys: computed,
  typeKeysFilterList: computed,
  totalSd: computed,
  sdByPhotos: computed,
  sdByDocuments: computed,
  sdBySchemas: computed,
  sdByInvoices: computed,
  sdByTypes: computed,
  sdBySelected: computed,
  sdForPackage: computed,
})

export default new DecoratedSupportingDocumentStore()
