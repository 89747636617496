import React from 'react'
import { withTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { Line } from 'rc-progress'
import classNames from 'classnames'

import './UploadInvoiceModal.css'
import { isPDFMimeType, SupportingDocTypes } from 'stores/Common/view/SupportingDocumentsCtrl'
import UploadCtrl from 'stores/Common/view/UploadCtrl'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import Input from 'components/Forms/Input'
import Button from 'components/Button/Button'
import DatePicker from 'components/Forms/DatePicker'

class UploadInvoiceModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = { grabbing: false }
  }
  setTranslatedError(field) {
    const { t } = this.props
    let errors = UploadCtrl.invoiceError
    if (errors[field]) {
      return errors[field].map(error => {
        return t(error)
      })
    }
    return []
  }

  renderUploadForm() {
    const { t } = this.props
    const type = SupportingDocTypes.INVOICE
    return (
      <div>
        {UploadCtrl.invoiceAcceptedFiles.map((acceptedFile, index) => {
          return (
            <div key="invoice-file" className="row">
              <div className="preview col-12">
                {/image/.test(acceptedFile.file.type) ? (
                  <img src={acceptedFile.file.preview} alt={`preview-${acceptedFile.name}`} />
                ) : (
                  <div className="no-preview">
                    {isPDFMimeType(acceptedFile.file.type) ? (
                      <i className="fa fa-file-pdf-o" />
                    ) : (
                      <i className="fa fa-file" />
                    )}
                  </div>
                )}
              </div>
              <Line
                percent={UploadCtrl.invoiceAcceptedFiles[index]['progressBar']}
                strokeWidth={4}
                strokeColor={'#f27e20'}
                trailWidth={8}
                className="progress-bar-inner"
              />
              <form>
                <div className="row">
                  <div className="col-sm-6">
                    <div key="invoice-number" className="form-group">
                      <label>{t('mission.supportingDocuments.invoice.number')}</label>
                      <Input
                        type="text"
                        name="invoice-number"
                        className="form-control"
                        maxLength={128}
                        value={UploadCtrl.invoiceAcceptedFiles[index]['extraData'][type]['number']}
                        onChange={e =>
                          UploadCtrl.invoiceAcceptedFiles[index].setExtraData(
                            type,
                            'number',
                            e.target.value,
                          )
                        }
                        placeholder={t('mission.supportingDocuments.invoice.number')}
                        errors={this.setTranslatedError('number')}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div key="invoice-date" className="form-group">
                      <label>{t('mission.supportingDocuments.invoice.date')}</label>
                      <DatePicker
                        name="invoice-date"
                        className="form-control"
                        selected={UploadCtrl.invoiceAcceptedFiles[index]['extraData'][type]['date']}
                        onChange={value =>
                          UploadCtrl.invoiceAcceptedFiles[index].setExtraData(type, 'date', value)
                        }
                        isClearable={true}
                        errors={this.setTranslatedError('date')}
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )
        })}
      </div>
    )
  }

  setInvoiceModalDisplay = () => UploadCtrl.setInvoiceModalDisplay(false)
  addInvoice = wan => UploadCtrl.invoiceUploadFormValidation(wan)
  noop = () => {}
  setGrabbing = value => {
    this.setState({ grabbing: value })
  }

  modalTitle(type) {
    switch (type) {
      case 'IINV':
        return 'mission.upload.modalInvoiceTitle'
      case 'INVM':
        return 'mission.upload.modalInvoiceTitleMobilier'
      case 'DOTH':
        return 'mission.upload.modalOther'
      default:
        return 'mission.upload.modalQuotationTitle'
    }
  }

  render() {
    const { t, wan } = this.props
    const { grabbing } = this.state

    return (
      <ModalWithBackdrop
        show={UploadCtrl.invoiceModalDisplay}
        close={this.noop}
        size="modal-lg"
        className="upload-invoice-modal"
        scrollable
        draggable
        setGrabbing={this.setGrabbing.bind(this)}
      >
        <div
          className={classNames('modal-header d-flex justify-content-between', {
            grabbable: !grabbing,
            grabbing: grabbing,
          })}
        >
          <h5 className="modal-title">{t(this.modalTitle(UploadCtrl.defaultType))}</h5>
          {!UploadCtrl.loading && (
            <button type="button" className="close" onClick={this.setInvoiceModalDisplay}>
              <span>&times;</span>
            </button>
          )}
        </div>
        <div className="modal-body overflow-x-hidden">
          {!!UploadCtrl.invoiceAcceptedFiles.length && this.renderUploadForm()}
        </div>
        <div className="modal-footer pb-2 pt-2">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={this.setInvoiceModalDisplay}
            disabled={UploadCtrl.loading}
          >
            {t('common.close')}
          </button>
          <Button
            className="btn btn-primary"
            onClick={this.addInvoice.bind(this, wan)}
            loading={UploadCtrl.loading}
          >
            {t('mission.upload.add')}
          </Button>
        </div>
      </ModalWithBackdrop>
    )
  }
}

export default withTranslation()(observer(UploadInvoiceModal))
