import React, { useState, useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import Form from 'components/DynForm/Form'
import Loader from 'components/Loader/Loader'
import Button from 'components/Button/Button'
import CardLayout from 'components/CardLayout/CardLayout'
import ActionModal from './components/ActionModal'
import SupportingDocuments from 'pages/SupportingDocuments/SupportingDocuments'
import PartyInvolved from 'pages/PartyInvolved/PartyInvolved'

const Qualification = ({
  match: {
    params: { id },
  },
  CreateMissionFormCtrl: { form, loading },
  CreateMissionCtrl: { saveMissionForm, submitting },
  ClaimStore: { status, isToQualify },
}) => {
  const [showModal, setShowModal] = useState('')
  const { t } = useTranslation()

  useEffect(() => {
    return () => form && form.resetForm()
  }, [form])

  if (loading || !form) return <Loader />
  else if (!isToQualify) return <Redirect to="information" />

  return (
    <CardLayout>
      <ActionModal showModal={showModal} closeModal={() => setShowModal(null)} />

      <div className="qualification">
        <div className="d-flex justify-content-end px-2">
          <button className="btn btn-primary px-5" onClick={() => setShowModal('reassign')}>
            {t('mission.qualification.reassign')}
          </button>
        </div>

        <h4 className="px-2 mb-3">{t('mission.qualification.qualification')}</h4>

        <Form formObj={form} noSubmitBtn />

        <div className="my-4 px-2">
          <h5>{t('mission.qualification.partyInvolved')}</h5>
          <PartyInvolved useCardLayout={false} forceUseList />
        </div>

        <div className="my-5">
          <h5 className="px-2">{t('mission.qualification.supportingDocument')}</h5>
          <SupportingDocuments noWrapBorder withFilters={false} />
        </div>

        <div className="d-flex justify-content-center">
          <Button
            className="btn btn-success px-5"
            disabled={submitting}
            onClick={() => saveMissionForm({ wan: id })}
          >
            {status === 'CIMR'
              ? t('mission.qualification.schedule')
              : t('mission.qualification.reschedule')}
          </Button>
        </div>
      </div>
    </CardLayout>
  )
}

export default inject('CreateMissionFormCtrl', 'CreateMissionCtrl', 'ClaimStore')(
  observer(Qualification),
)
