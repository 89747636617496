import React from 'react'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { path, isNil } from 'ramda'

import { euro } from 'utils/currency'
import { furnitureSkipComputationRules } from 'utils/cart'

const Data = ({ data, totals, column }) => {
  const skip = path([data.id], furnitureSkipComputationRules)
  if (skip && skip.indexOf(column) > -1) return <td className="text-center">-</td>
  return <td>{euro(totals[data.id][column])}</td>
}

const Row = ({ data, totals }) => {
  const rowFinalPrice = path([data.id, 'finalPriceWithVAT'], totals)
  if (isNil(rowFinalPrice) || rowFinalPrice === 0) return null

  return (
    <tr>
      <th scope="row">{data.trans_default}</th>
      <Data data={data} totals={totals} column="totalWithVAT" />
      <Data data={data} totals={totals} column="totalOptionWithVAT" />
      <Data data={data} totals={totals} column="totalObsWithVAT" />
      <Data data={data} totals={totals} column="totalUsedWithVAT" />
      <Data data={data} totals={totals} column="finalPriceWithVAT" />
    </tr>
  )
}

const DescriptionFurnitures = ({
  t,
  furnituresPerCategory,
  involvedPartyId,
  CommonStore: { furnitureCategories },
  CartStore: { furnituresWithoutIP },
}) => {
  if (involvedPartyId === 'notVentilated' && furnituresWithoutIP.length === 0) return null

  if (furnituresPerCategory.furnituresLength === 0) {
    return (
      <React.Fragment>
        <div className="p-4 border d-block d-md-none">
          {t('mission.conclusion.tableResponsive')}
        </div>

        <table className="table table-bordered d-none d-md-table">
          <tbody>
            <tr>
              <th>{t('mission.conclusion.data.furniture')}</th>
              <th className="text-center">{t('mission.conclusion.computation.vnrWithVAT')}</th>
              <th className="text-center">
                {t('mission.conclusion.computation.optionVrnWithVAT')}
              </th>
              <th className="text-center">
                {t('mission.conclusion.computation.vrnWithVATWithObs')}
              </th>
              <th className="text-center">{t('mission.conclusion.computation.usedWithVAT')}</th>
              <th className="text-center">
                {t('mission.conclusion.computation.totalCompensable')}
              </th>
            </tr>
            <tr>
              <td className="text-center" colSpan="6">
                {t('mission.conclusion.data.empty')}
              </td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <div className="p-4 border d-block d-md-none">{t('mission.conclusion.tableResponsive')}</div>

      <table className="table table-bordered d-none d-md-table">
        <tbody>
          <tr>
            <th>{t('mission.conclusion.data.furniture')}</th>
            <th className="text-center">{t('mission.conclusion.computation.vnrWithVAT')}</th>
            <th className="text-center">{t('mission.conclusion.computation.optionVrnWithVAT')}</th>
            <th className="text-center">{t('mission.conclusion.computation.vrnWithVATWithObs')}</th>
            <th className="text-center">{t('mission.conclusion.computation.usedWithVAT')}</th>
            <th className="text-center">{t('mission.conclusion.computation.totalCompensable')}</th>
          </tr>

          {furnitureCategories.map(category => {
            return (
              <Row key={`category-${category.id}`} data={category} totals={furnituresPerCategory} />
            )
          })}
          <Row
            data={{ trans_default: 'Sans catégorie', id: 'noCategory' }}
            totals={furnituresPerCategory}
          />

          <tr>
            <th scope="row">Total</th>
            <td>{euro(furnituresPerCategory.totals.totalWithVAT)}</td>
            <td>{euro(furnituresPerCategory.totals.totalOptionWithVAT)}</td>
            <td>{euro(furnituresPerCategory.totals.totalObsWithVAT)}</td>
            <td>{euro(furnituresPerCategory.totals.totalUsedWithVAT)}</td>
            <td>{euro(furnituresPerCategory.totals.finalPriceWithVAT)}</td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  )
}

export default compose(
  inject('CommonStore', 'CartStore'),
  withTranslation(),
  observer,
)(DescriptionFurnitures)
