import React from 'react'
import { observer, inject } from 'mobx-react'
import { NavLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'

import ProtectedLink from 'components/ProtectedLink/ProtectedLink'

class ParametersDesktop extends React.Component {
  render() {
    const {
      t,
      UserStore: {
        isAllowedToPartyInvolvedDB,
        isAllowedToUserManagement,
        isAllowedToCoverageExecutionTime,
        isAllowedToTourConfiguration,
        isAllowedToImpersonate,
      },
    } = this.props

    return (
      <div className="side-nav">
        <h5 className="mt-0 mb-4">{t('parameters.sideNav.title')}</h5>

        <div className="nav flex-column nav-pills">
          <NavLink className="nav-link" activeClassName="active" to="/parameters/informations">
            {t('parameters.sideNav.informations')}
          </NavLink>

          <ProtectedLink to="/parameters/partyInvolvedDB" condition={!isAllowedToPartyInvolvedDB}>
            {t('mission.sideNav.partyInvolved')}
          </ProtectedLink>

          <ProtectedLink to="/parameters/userManagement" condition={!isAllowedToUserManagement}>
            {t('parameters.sideNav.userManagement')}
          </ProtectedLink>

          <ProtectedLink
            to="/parameters/tourConfiguration"
            condition={!isAllowedToTourConfiguration}
          >
            {t('parameters.sideNav.tourConfiguration')}
          </ProtectedLink>
        </div>

        {isAllowedToCoverageExecutionTime && (
          <React.Fragment>
            <h5 className="mt-4 mb-4">{t('parameters.sideNav.calendarTitle')}</h5>

            <div className="nav flex-column nav-pills">
              <ProtectedLink
                to="/parameters/coverageExecutionTime"
                condition={!isAllowedToCoverageExecutionTime}
              >
                {t('parameters.sideNav.coverageExecutionTime')}
              </ProtectedLink>
            </div>
          </React.Fragment>
        )}

        <h5 className="mt-4 mb-4">{t('parameters.sideNav.networkTitle')}</h5>

        <div className="nav flex-column nav-pills">
          <div className="nav-link protected">{t('parameters.sideNav.networkManager')}</div>
        </div>

        <h5 className="mt-4 mb-4">{t('parameters.sideNav.application')}</h5>

        <div className="nav flex-column nav-pills">
          <NavLink className="nav-link" activeClassName="active" to="/parameters/version">
            {t('common.applicationVersion')}
          </NavLink>
          {isAllowedToImpersonate && (
            <ProtectedLink to="/parameters/impersonate" condition={!isAllowedToImpersonate}>
              {t('common.impersonate')}
            </ProtectedLink>
          )}
        </div>
        <h5 className="mt-4 mb-4">{t('parameters.sideNav.help')}</h5>

        <div className="nav flex-column nav-pills">
          <NavLink className="nav-link" activeClassName="active" to="/parameters/help">
            {t('common.teamViewerDownload')}
          </NavLink>
        </div>
      </div>
    )
  }
}

export default compose(
  inject('UserStore'),
  withTranslation(),
  observer,
)(ParametersDesktop)
