import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Select from 'react-select'
import { withTranslation } from 'react-i18next'
import { type, isNil } from 'ramda'

import './Select.css'

class SelectWithSearch extends React.PureComponent {
  onChange = selectedOption => {
    const { isMulti } = this.props

    this.props.onChange({
      target: { name: this.props.name, value: isMulti ? selectedOption : selectedOption.value },
    })
  }

  render() {
    const {
      t,
      name,
      id,
      value,
      className,
      options,
      isSearchable,
      required,
      placeholder,
      isClearable,
      isDisabled,
      isMulti,
      nullable,
      errors,
      autoFocus,
      defaultMenuIsOpen,
      defaultValue,
      colourStyles,
    } = this.props
    let valueObj = null
    const componentOptions = [...options]
    const customStyles = {
      ...colourStyles,
      control: provided => ({
        ...provided,
        background:
          required &&
          ((type(value) === 'Array' && value.length === 0) || value === '' || isNil(value))
            ? '#fffcd9'
            : null,
      }),
    }
    const styles = {
      control: (css, { isDisabled }) => ({ ...css, cursor: isDisabled ? 'default' : 'pointer' }),
      option: (css, { isDisabled }) => ({ ...css, cursor: isDisabled ? 'default' : 'pointer' }),
    }

    if (isMulti && type(value) !== 'Array') {
      console.warn(`Your select ${name} input is a multi. Your value must be an array.`)
      return null
    } else if (isMulti && type(value) === 'Array') {
      valueObj = value
    } else {
      if (value && !nullable) {
        valueObj = options.find(option => option.value === value)
        if (valueObj === undefined) valueObj = options[0]
      }

      if (nullable) {
        componentOptions.unshift({
          value: '',
          label: t('common.select'),
        })
        valueObj = valueObj
          ? componentOptions.shift()
          : componentOptions.find(option => option.value === value)
      }
    }

    return [
      <Select
        name={name}
        id={id}
        className={classNames(
          className,
          { error: errors && !!errors.length, required },
          { 'bg-required': required && !value },
        )}
        value={valueObj}
        options={componentOptions}
        placeholder={placeholder}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isMulti={isMulti}
        onChange={this.onChange}
        required={required}
        autoFocus={autoFocus}
        defaultValue={defaultValue}
        defaultMenuIsOpen={defaultMenuIsOpen}
        noOptionsMessage={() => t('common.noOption')}
        key={`select-${name}`}
        ref={ref => (this.input = ref)}
        styles={styles}
        {...customStyles}
      />,
      errors && !!errors.length && (
        <div key={'error-block'} className="row">
          <div className="error-message col-sm-12">
            {errors.map(errorDescription => (
              <div key={`${errorDescription}`}>{t(errorDescription)}</div>
            ))}
          </div>
        </div>
      ),
    ]
  }
}

SelectWithSearch.defaultProps = {
  name: 'input-select',
  options: [],
  placeholder: '',
  className: 'sps-select',
  isSearchable: false,
  isClearable: false,
  required: false,
  nullable: false,
  autoFocus: false,
  isMulti: false,
  defaultMenuIsOpen: false,
  defaultValue: null,
  onChange: () => {},
  colourStyles: {},
}

SelectWithSearch.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number, PropTypes.bool]),
  onChange: PropTypes.func,
  className: PropTypes.string,
  options: PropTypes.array,
  errors: PropTypes.array,
  placeholder: PropTypes.string,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  required: PropTypes.bool,
  nullable: PropTypes.bool,
  autoFocus: PropTypes.bool,
  defaultMenuIsOpen: PropTypes.bool,
  colourStyles: PropTypes.object,
}

export default withTranslation()(SelectWithSearch)
