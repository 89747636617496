import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { setDataLocal } from 'stores/localStorage'
import { inject } from 'mobx-react'

const LoginTransparent = ({ UserStore: { logout } }) => {
  const { customerId, token, refreshToken, expiresIn, userMode, wan } = useParams()

  useEffect(() => {
    logout(false)
    setDataLocal('customerId', customerId)
    setDataLocal('userInfo', { token, refreshToken, expiresIn, passwordChangedAt: true })
    let link = '/'
    if (userMode && wan) {
      setDataLocal('userMode', userMode)
      link += userMode === 'expert' ? `mission/${wan}/information` : `claim/${wan}/information`
    }

    window.location.href = link
  }, [customerId, token, refreshToken, expiresIn, userMode, wan, logout])

  return <div></div>
}

export default inject('UserStore')(LoginTransparent)
