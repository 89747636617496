import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { add, multiply } from 'ramda'

import RecapRowLayout from 'pages/Mission/pages/Calculation/DetailPackage/RecapRowLayout'
import { LabelVAT } from 'pages/Mission/pages/Calculation/DetailPackage/RecapComponents'
import Toggle from 'components/Toggle/Toggle'
import { euro } from 'utils/currency'
import { computeTotalItems, getMultiplier } from 'utils/easyEstimation'

const _convertDuration = (value, round = false) => {
  const sec_num = parseInt(value, 10)
  const hours = Math.floor(sec_num / 3600)
  let minutes = Math.floor((sec_num - hours * 3600) / 60)
  let seconds = sec_num - hours * 3600 - minutes * 60

  if (seconds > 30 && round) minutes = minutes + 1
  if (seconds < 10 && !round) seconds = `0${seconds}`

  return `${minutes}h${!round ? seconds : ''}`
}

const CoveringRecap = ({ rcpData, rcpPackages, CartStore: { findDifficultyRate } }) => {
  const [showRecap, setShowRecap] = useState(false)
  const { t } = useTranslation()
  if (rcpPackages.length === 0) return null

  const packageData = rcpPackages[0]
  const selfRepair = packageData.selfRepair

  // TIME CATALOG
  const totalTime = rcpPackages.reduce((acc, data) => {
    data.items.forEach(item => {
      if (item.piActive) acc = add(acc, multiply(item.qtUnitMinute, getMultiplier(data, rcpData)))
    })
    return acc
  }, 0)
  const catalogDuration = _convertDuration(totalTime)

  // TIME SELF REPAIR
  const totalSelfRepairTime = rcpPackages.reduce((acc, data) => {
    data.items.forEach(item => {
      if (item.piActive)
        acc = add(
          acc,
          item.qtUnitMinute *
            findDifficultyRate(item.packageDifficulty) *
            getMultiplier(data, rcpData),
        )
    })
    return acc
  }, 0)
  const selfRepairDuration = _convertDuration(totalSelfRepairTime, true)

  // TOTAL ITEMS
  const totalItems = computeTotalItems(rcpPackages, rcpData)

  // TOTAL PRICE
  const totalCatalog =
    rcpPackages.reduce((acc, data) => add(acc, data.finalPriceCatalogWithoutVAT), 0) - totalItems

  const totalSelfRepair =
    rcpPackages.reduce((acc, data) => add(acc, data.finalPriceSelfRepairWithoutVAT), 0) - totalItems

  // AVERAGE HOUR PRICE
  const averageHourPrice = (totalCatalog / totalTime) * 60

  // NORMAL COMPUTATION
  const totalCovering = rcpPackages.reduce(
    (acc, data) => add(acc, data.finalPriceCatalogWithoutVAT),
    0,
  )
  const totalVAT = rcpPackages.reduce((acc, data) => add(acc, data.totalVAT), 0)
  const totalPriceVAT = rcpPackages.reduce((acc, data) => add(acc, data.totalPriceVAT), 0)

  const onChange = e => {
    rcpPackages.forEach(packageData => packageData.setProperty(e.target.name, e.target.value))
  }

  return (
    <div className="covering-recap-wrapper">
      <button
        className={classNames('btn rounded-circle mr-1', {
          'btn-primary': showRecap,
          'btn-outline-primary': !showRecap,
        })}
        onClick={() => setShowRecap(!showRecap)}
      >
        <i
          className={classNames('fa', {
            'fa-search-minus': showRecap,
            'fa-search-plus': !showRecap,
          })}
        />
      </button>

      {showRecap && (
        <div className="covering-recap border p-3">
          <RecapRowLayout
            labelComponent={
              <div className="d-flex align-items-center">
                <span className="mr-2">REN :</span>
                <Toggle name="ren" onChange={onChange} checked={packageData.ren} />
              </div>
            }
            priceComponent={
              <div className="row">
                <div className="col-6 text-right">GAG</div>
                <div className="col-6 text-right">Prix marché</div>
              </div>
            }
            packageLayout={false}
          />

          <RecapRowLayout
            labelComponent={<span className="recap-label mr-3">Taux horaire</span>}
            priceComponent={
              <div className="row">
                <div className="col-6 text-right">-</div>
                <div className="col-6 text-right">{euro(averageHourPrice)}</div>
              </div>
            }
            packageLayout={false}
          />

          <RecapRowLayout
            labelComponent={<span className="recap-label mr-3">Temps MO</span>}
            priceComponent={
              <div className="row">
                <div className="col-6 text-right">{selfRepairDuration}</div>
                <div className="col-6 text-right">{catalogDuration}</div>
              </div>
            }
            packageLayout={false}
          />

          <RecapRowLayout
            labelComponent={<span className="recap-label mr-3">Coût MO</span>}
            priceComponent={
              <div className="row">
                <div className="col-6 text-right">{euro(Math.round(totalSelfRepair))}</div>
                <div className="col-6 text-right">{euro(totalCatalog)}</div>
              </div>
            }
            packageLayout={false}
          />

          <RecapRowLayout
            labelComponent={<span className="recap-label mr-3">Coût fournitures</span>}
            priceComponent={
              <div className="row">
                <div className="col-6 text-right">{euro(totalItems * 1.2)}</div>
                <div className="col-6 text-right">{euro(totalItems)}</div>
              </div>
            }
            packageLayout={false}
          />

          <hr />

          <RecapRowLayout
            labelComponent={
              <span className="recap-label mr-3">
                {t('mission.calculation.detailPackage.recapTitle')}
              </span>
            }
            priceComponent={euro(totalCovering)}
            packageLayout={false}
          />

          {!selfRepair && (
            <RecapRowLayout
              labelComponent={<LabelVAT packageData={packageData} onChange={onChange} />}
              priceComponent={euro(totalVAT)}
              packageLayout={false}
            />
          )}

          {!selfRepair && (
            <RecapRowLayout
              labelComponent={
                <span className="recap-label">
                  {t('mission.calculation.detailPackage.totalWithVAT')}
                </span>
              }
              priceComponent={euro(totalPriceVAT)}
              packageLayout={false}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default inject('CartStore')(observer(CoveringRecap))
