import { observable, action, decorate } from 'mobx'
import { isAfter } from 'date-fns'

class Customer {
  id = null
  name = ''
  phoneNumber = ''
  vatNumber = null
  email = ''
  customerType = {}
  country = ''
  contracts = []
  breCalculation = null
  address = ''
  active = true

  constructor(data) {
    if (data) {
      this.id = data.id
      this.name = data.name
      this.phoneNumber = data.phoneNumber
      this.vatNumber = data.vatNumber
      this.email = data.email
      this.customerType = data.customerType
      this.country = data.country
      this.contracts = data.contracts
      this.breCalculation = data.breCalculation
      this.address = data.addresses.find(address => address.type.id === 'M')
      this.active = data.active
    }
  }

  hasContract(contractId) {
    const contractSPS = this.contracts.find(contract => contract.contract.id === contractId)

    if (!contractSPS) {
      return false
    } else if (contractSPS.canceledAt) {
      return isAfter(new Date(contractSPS.canceledAt), new Date())
    }

    return true
  }

  setProperty(key, value) {
    this[key] = value
  }
}
const DecoratedCustomer = decorate(Customer, {
  id: observable,
  name: observable,
  vatNumber: observable,
  email: observable,
  customerType: observable,
  country: observable,
  contracts: observable,
  breCalculation: observable,
  address: observable,
  active: observable,

  setProperty: action,
  hasContract: action.bound,
})

export default DecoratedCustomer
