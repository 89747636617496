import { path, reduce, add, keys, values, isNil } from 'ramda'

import MissionStore from 'stores/Mission/domain/MissionStore'
import { roundDecimal } from 'utils/currency'

const _overrideLabelIfExist = roomType => {
  return path(['override', 'key'], roomType) || roomType.key || roomType.cde
}

export const sortByRoom = packages => {
  const { isIRSI, selectedIRSICart } = MissionStore
  if (isIRSI) {
    packages = packages.filter(packageData => packageData.involvedParty === selectedIRSICart)
  }

  const rooms = []

  packages.forEach(packageData => {
    if (rooms.length) {
      const room = rooms.find(
        room =>
          _overrideLabelIfExist(room.cartRoomType) === _overrideLabelIfExist(packageData.roomType),
      )
      if (room) {
        room.packages.push(packageData)
        return
      }
    }
    rooms.push({
      cartRoomType: packageData.roomType,
      packages: [packageData],
    })
  })
  return rooms
}

export const packagesPrice = (rooms, selfRepair = false) => {
  const packages = rooms.reduce((acc, room) => {
    room.packages.forEach(packageData => acc.push(packageData))
    return acc
  }, [])

  const total = packages.reduce((acc, packageData) => {
    if (selfRepair && packageData.selfRepair) {
      return acc + packageData.finalPriceSelfRepair
    } else if (!selfRepair && !packageData.selfRepair) {
      return acc + packageData.finalPriceCatalog
    }
    return acc
  }, 0)
  return total
}

export const furnituresPrice = furnitures => {
  const total = furnitures.reduce((acc, furniture) => {
    return acc + furniture.finalPrice
  }, 0)

  return total
}

export const outdoorPackagesPrice = (rooms, selfRepair = false) => {
  const packages = rooms.reduce((acc, room) => {
    room.packages.forEach(packageData => acc.push(packageData))
    return acc
  }, [])

  const total = packages.reduce((acc, packageData) => {
    if (selfRepair && packageData.selfRepair) {
      return acc + packageData.finalPriceSelfRepair
    } else if (!selfRepair && packageData.modalityAcquisition !== undefined) {
      return acc + packageData.finalPrice
    } else if (!selfRepair && packageData.modalityAcquisition === undefined) {
      return acc + packageData.finalPriceCatalog
    }
    return acc
  }, 0)
  return total
}

export const relatedCostsPrice = (relatedCosts, packageType) => {
  const total = relatedCosts.reduce((acc, data) => {
    if (packageType === 'relatedCost' && data.type !== 'ANNEX') return acc
    return add(acc, data.priceWithVAT)
  }, 0)
  return total
}

export const calculTotalPackages = ({ packages, key, selfRepair }) => {
  let total = 0

  if (selfRepair === null) {
    packages.forEach(packageData => {
      total += packageData[key]
    })
  } else {
    packages.forEach(packageData => {
      if (packageData.selfRepair === selfRepair) total += packageData[key]
    })
  }

  if (total === 0) return 0

  return roundDecimal(total)
}

export const computeCatalogOutdoorPackages = ({ outdoorPackages, furnitureKey, packageKey }) => {
  return reduce(
    (acc, outdoorPackage) => {
      let price = 0
      if (outdoorPackage.modalityAcquisition === undefined) {
        price = outdoorPackage.selfRepair === false ? outdoorPackage[packageKey] : 0
      } else {
        price = outdoorPackage[furnitureKey]
      }

      return add(acc, price)
    },
    0,
    outdoorPackages,
  )
}

// FURNITURES
export const furnitureCategoryIds = [
  'noCategory',
  'MC001',
  'MC002',
  'MC003',
  'MC004',
  'MC005',
  'MC006',
  'MC007',
  'MC008',
  'MC009',
  'MC010',
  'MC011',
  'MC012',
  'MC013',
  'MC014',
  'MC015',
]

export const furnitureCategoryComputations = [
  { name: 'totalWithVAT', key: 'totalPriceVAT' },
  { name: 'totalOptionWithVAT', key: 'totalPriceVAT' },
  { name: 'totalObsWithVAT', key: 'totalObsolescence' },
  { name: 'totalUsedWithVAT', key: 'totalPriceVAT' },
  { name: 'finalPriceWithVAT', key: 'finalPrice' },
]

export const furnitureSkipComputationRules = {
  MC003: ['totalOptionWithVAT'],
  MC008: ['totalWithVAT', 'totalOptionWithVAT', 'totalObsWithVAT'],
  MC014: ['totalOptionWithVAT'],
}

export const shouldCompute = ({ categoryId, computation, data }) => {
  const skip = path([categoryId], furnitureSkipComputationRules)
  if (skip && skip.indexOf(computation) > -1) return 0
  return data
}

export const computeFurnitureTotalsPerCategory = (data, computation) => {
  return reduce(
    (acc, categoryId) => {
      return (acc += data[categoryId][computation])
    },
    0,
    furnitureCategoryIds,
  )
}

export const furnitureComputationsPerCategory = data => {
  const furnituresTotalsByCategory = {}
  furnitureCategoryIds.forEach(categoryId => {
    const category = categoryId !== 'noCategory' ? categoryId : null
    const furnitures = data.filter(furniture => furniture.category === category)
    const totalsPerCategory = {
      totalWithVAT: 0,
      totalOptionWithVAT: 0,
      totalObsWithVAT: 0,
      totalUsedWithVAT: 0,
      finalPriceWithVAT: 0,
    }
    furnitures.forEach(furniture => {
      // 4eme colonne Valeur d'occasion TTC => modalite d'indemnisation === valeur d'occasion
      if (furniture.compensation === 'used') {
        totalsPerCategory.totalUsedWithVAT += shouldCompute({
          categoryId: furniture.category,
          computation: 'totalUsedWithVAT',
          data: furniture.totalPriceVAT,
        })
        // 2eme colonne Option VNR etendu => option garantie existe
      } else if (!isNil(MissionStore.optionGuarantee)) {
        totalsPerCategory.totalOptionWithVAT += shouldCompute({
          categoryId: furniture.category,
          computation: 'totalOptionWithVAT',
          data: furniture.totalPriceVAT,
        })
        // 3eme VRN TTC VD => correction > 0 (vetuste reelle > 0)
      } else if (furniture.correction > 0) {
        totalsPerCategory.totalObsWithVAT += shouldCompute({
          categoryId: furniture.category,
          computation: 'totalObsWithVAT',
          data: furniture.finalPrice,
        })
        // 1er colonne VRN TTC => correction = 0 (pas vetuste reelle)
      } else {
        totalsPerCategory.totalWithVAT += shouldCompute({
          categoryId: furniture.category,
          computation: 'totalWithVAT',
          data: furniture.finalPrice,
        })
      }
    })
    furnituresTotalsByCategory[categoryId] = totalsPerCategory
  })

  keys(furnituresTotalsByCategory).forEach(category => {
    furnituresTotalsByCategory[category].finalPriceWithVAT = reduce(
      add,
      0,
      values(furnituresTotalsByCategory[category]),
    )
  })

  const totals = {}
  furnitureCategoryComputations.forEach(computation => {
    totals[computation.name] = computeFurnitureTotalsPerCategory(
      furnituresTotalsByCategory,
      computation.name,
    )
  })

  furnituresTotalsByCategory.totals = totals
  furnituresTotalsByCategory.furnituresLength = data.length
  return furnituresTotalsByCategory
}

export const validRCP = (rcp, packages) => {
  return rcp
    .map(rcp => {
      const group = packages.filter(data => data.groupId === rcp.groupId)
      if (group.length > 0) return rcp
      return null
    })
    .filter(rcp => rcp)
}

const _getCompensatory = (type, config, trueTotal) => {
  switch (type) {
    case 'selfRepair':
      return {
        show: trueTotal > 0,
        min: parseFloat(config.minGAG),
        max: parseFloat(config.maxGAG),
      }
    case 'ren':
      return {
        show: trueTotal > 0,
        min: parseFloat(config.minREN),
        max: parseFloat(config.maxREN),
      }
    case 'propertyAndEmbellishment':
      return {
        show: trueTotal > 0,
        min: parseFloat(config.minEE),
        max: parseFloat(config.maxEE),
      }
    default:
      return 0
  }
}

export const computeCompensatoryPackages = ({
  type,
  immediate,
  deffered,
  total,
  configuration,
  trueTotal,
}) => {
  const compensatory = _getCompensatory(type, configuration, trueTotal)

  let value = 0
  if (compensatory.show) {
    const totalImmediateDeffered = immediate + deffered
    if (compensatory.min > totalImmediateDeffered) {
      value = compensatory.min - totalImmediateDeffered
    } else if (compensatory.max < totalImmediateDeffered) {
      value = compensatory.max - totalImmediateDeffered
    } else if (
      compensatory.max >= totalImmediateDeffered ||
      compensatory.min <= totalImmediateDeffered
    ) {
      value = 0
    }
  }

  return {
    value,
    total: total + value,
  }
}
