import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import classNames from 'classnames'

import Tooltip from 'components/Tooltip/Tooltip'
import { MISSION_TYPE_ID } from 'utils/constants'
import { concatNames, arrayToDivs } from 'utils'

function Event({
  t,
  CommonStore: { coveragesData },
  CalendarExpertStore: { viewType },
  UserStore: { isInsurer },
  event: {
    type: eventType,
    obj: { title, address, insurerClaimId, coverageKey, missionNature, expertiseCharacteristic },
  },
}) {
  const addressFormat = address ? concatNames(address.zipCode, address.city) : ''
  const showCoverageKeyColor = isInsurer && viewType === 'week' && eventType === 'MISS'

  let eventName = ''
  switch (eventType) {
    case MISSION_TYPE_ID:
      eventName = coveragesData[coverageKey]
      break
    case 'VACA':
      eventName = title
      break
    case 'PERM':
      eventName = t('calendar.appointment.PERM')
      break
    default:
      break
  }

  let text = '-'
  if (insurerClaimId) {
    text = arrayToDivs([insurerClaimId, addressFormat, coveragesData[coverageKey]])
  } else {
    text = eventName
  }

  return (
    <Tooltip text={text} innerClassName="text-white" placement="top">
      <div className="my-event pt-1">
        <div className="rbc-event-content mb-1">
          <div className="mb-1">
            {!!insurerClaimId && (
              <div className="text-truncate mb-1">
                {showCoverageKeyColor && (
                  <span
                    className={classNames(
                      'd-inline-block rounded-circle mr-2',
                      `coverage--${coverageKey}`,
                    )}
                    style={{ height: '12px', width: '12px' }}
                  />
                )}
                {missionNature === 'Mission urgente' && (
                  <span className="text-danger mr-2">
                    <i className="fa fa-warning" />
                  </span>
                )}
                {expertiseCharacteristic && expertiseCharacteristic.includes('Contradictoire') && (
                  <span className="text-dark mr-2">
                    <i className="fa fa-calendar" />
                  </span>
                )}
                {insurerClaimId}
              </div>
            )}
            {!!addressFormat && <div className="address mb-1 text-truncate">{addressFormat}</div>}

            <span className="text-truncate">{eventName}</span>
          </div>
        </div>
      </div>
    </Tooltip>
  )
}

export default compose(
  inject('CommonStore', 'CalendarExpertStore', 'UserStore'),
  withTranslation(),
  observer,
)(Event)
