import React from 'react'
import { observer } from 'mobx-react'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import { geolocated } from 'react-geolocated'
import classNames from 'classnames'

import './AppointmentMap.css'
import Map from './Map'
import MapCtrl from 'stores/Mission/view/MapCtrl'

class ClaimMap extends React.Component {
  componentWillUnmount() {
    MapCtrl.resetDatas()
  }

  render() {
    return (
      <div className={classNames('map-wrapper', { collapsed: MapCtrl.collapsed })} id="map-wrapper">
        <div className={'map-wrapper_inner'}>
          {[<Map key="map-without-geolocation" destination={this.props.destination} />]}
        </div>
        <div
          className={classNames('btn-circle', {
            'geolocation-failure-btn': false,
          })}
          onClick={() => MapCtrl.toggleCollapsed()}
        >
          <i
            className={classNames('fa', {
              'fa-angle-double-down': MapCtrl.collapsed,
              'fa-angle-double-up': !MapCtrl.collapsed,
            })}
          />
        </div>
      </div>
    )
  }
}

export default compose(
  geolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
    userDecisionTimeout: 2000,
  }),
  withTranslation(),
)(observer(ClaimMap))
