import { observable, computed, action, runInAction, decorate } from 'mobx'
import i18next from 'i18next'

import { searchFurniture, furnitureDataTransformer, fetchRoomList } from 'services/calculation'
import FurnitureCatalog from 'stores/Mission/domain/FurnitureCatalog'

class SearchFurnitureCtrl {
  search = ''
  searching = false
  activeSearch = false
  list = []
  page = 1
  totalSearchFurniture = 0
  rooms = []
  selectedRoom = { label: 'selectFilter' }

  get roomsForSelect() {
    const rooms = this.rooms.map(room => ({ value: room.cde, label: room.label }))
    rooms.push({ value: 'selectFilter', label: i18next.t('common.select') })
    rooms.push({
      value: 'noFilter',
      label: i18next.t('mission.calculation.searchPackage.noFilter'),
    })
    return rooms
  }

  setProperty(key, value) {
    this[key] = value
  }

  async loadFurnitureRooms(wan) {
    try {
      const res = await fetchRoomList({ wan, params: { location: 'O' } })
      runInAction(() => {
        this.rooms = res
      })
    } catch (error) {
      console.log(error)
    }
  }

  async searchFurniture(wan, location) {
    this.searching = true
    this.activeSearch = true
    this.list = []

    try {
      const { furnitures, totalSearchFurniture } = await searchFurniture({
        wan,
        search: this.search,
        page: this.page,
      })
      runInAction(() => {
        this.totalSearchFurniture = totalSearchFurniture

        this.list = furnitures.map(data => {
          return new FurnitureCatalog({
            ...furnitureDataTransformer(data.attributes),
            location,
            roomType: this.selectedRoom,
          })
        })
        this.searching = false
      })
    } catch (err) {
      runInAction(() => {
        this.list = []
        this.searching = false
      })
    }
  }
}

const DecoratedSearchFurnitureCtrl = decorate(SearchFurnitureCtrl, {
  search: observable,
  searching: observable,
  activeSearch: observable,
  list: observable,
  page: observable,
  totalSearchFurniture: observable,
  rooms: observable,
  selectedRoom: observable,
  type: observable,

  roomsForSelect: computed,

  setProperty: action,
  searchFurniture: action,
  loadFurnitureRooms: action,
})

export default new DecoratedSearchFurnitureCtrl()
