import { observable, computed, action, decorate } from 'mobx'

import AbstractField from 'utils/dynform/AbstractField'

class TextArea extends AbstractField {
  value = ''
  originalValue = null
  errors = []
  show = true

  type = 'textarea'
  choices = null
  maxLength = null
  multiple = false
  expanded = false

  children = null

  constructor(data) {
    super(data)

    this.type = data.type || 'textarea'
    this.choices = data.choices || null
    this.maxLength = data.maxLength || null

    if (data.value) this.value = data.value
    else if (data.default) this.value = data.default
    else this.value = ''

    this.show = data.show
    this.originalValue = this.value
  }

  get changed() {
    return this.value !== this.originalValue
  }

  setOriginalData() {
    this.value = this.originalValue
  }
}

const DecoratedTextArea = decorate(TextArea, {
  value: observable,
  originalValue: observable,
  errors: observable,
  show: observable,

  setOriginalData: action,

  changed: computed,
})

export default DecoratedTextArea
