import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'

import './Forms.css'

const noop = value => value

class TextArea extends React.Component {
  render() {
    const {
      autoComplete,
      className,
      cols,
      disabled,
      errors,
      minLength,
      maxLength,
      name,
      onBlur,
      onChange,
      onFocus,
      placeholder,
      readOnly,
      required,
      rows,
      t,
      value,
      autoFocus,
    } = this.props

    return [
      <textarea
        key={`textarea-${name}`}
        autoComplete={autoComplete}
        className={classNames(
          className,
          { error: !!errors.length, required },
          { 'bg-required': required && !value },
        )}
        cols={cols}
        disabled={disabled}
        minLength={minLength}
        maxLength={maxLength || 500}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
        rows={rows}
        value={value || ''}
        autoFocus={autoFocus}
      />,
      !!errors.length || maxLength ? (
        <div key={`textarea-additionnal-infos-${name}`} className="row no-gutters">
          {!!errors.length && (
            <div className="error-message col-sm-6">
              {errors.map(errorDescription => (
                <div key={`${errorDescription}`}>{t(errorDescription)}</div>
              ))}
            </div>
          )}
          {maxLength && (
            <div
              key={'counter-textarea'}
              className={classNames('counter-block', {
                'col-sm-6': !!errors.length,
                'col-sm-12': errors.length === 0,
              })}
            >
              <span className={classNames('float-right')}>
                {t('mission.damageDetails.form.counterTextArea', {
                  counter: value ? value.length : 0,
                  maxLength,
                })}
              </span>
            </div>
          )}
        </div>
      ) : null,
    ]
  }
}

TextArea.defaultProps = {
  name: 'textAreaName',
  onChange: noop,
  rows: 5,
  cols: 50,
  autoFocus: false,
  errors: [],
}

TextArea.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  cols: PropTypes.number,
  disabled: PropTypes.bool,
  errors: PropTypes.array,
  label: PropTypes.string,
  minLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  rows: PropTypes.number,
}

export default withTranslation()(TextArea)
