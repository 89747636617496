import React, { useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { compose } from 'recompose'
import { useParams } from 'react-router-dom'

import CardLayout from 'components/CardLayout/CardLayout'
import Form from 'components/DynForm/Form'
import Loader from 'components/Loader/Loader'

const Instruction = ({
  InstructionCtrl: { loading, form, save },
  CartStore: { instructionsChanged },
}) => {
  const { id } = useParams()

  useEffect(() => {
    return () => form && form.resetForm()
  }, [form])

  if (loading || !form) return <Loader />

  return (
    <CardLayout>
      <div className="coverage-details-page">
        <Form
          formObj={form}
          saveService={save.bind(this, id)}
          additionalSaveCondition={instructionsChanged}
        />
      </div>
    </CardLayout>
  )
}

export default compose(
  inject('InstructionCtrl', 'CartStore'),
  observer,
)(Instruction)
