import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { Switch, Route, useRouteMatch, useParams, Redirect } from 'react-router-dom'
import { compose } from 'recompose'
import { path as ramdaPath, isNil } from 'ramda'
import { useTranslation } from 'react-i18next'

import './ManagerClaim.css'
import Loader from 'components/Loader/Loader'
import PageLayout from 'components/PageLayout/PageLayout'
import SideNav from 'components/SideNav/SideNav'
import PartyInvolved from 'pages/PartyInvolved/PartyInvolved'
import SupportingDocumentsPage from 'pages/SupportingDocuments/SupportingDocumentsPage'
import NotFound from 'pages/NotFound/NotFound'
import StatusSteps from 'components/StatusSteps/StatusSteps'
import Events from 'pages/Events/Events'
import EasyEstimation from 'pages/EasyEstimation'
import Messaging from 'pages/Messaging'
import MissionHeaderCtrl from 'stores/Mission/view/MissionHeaderCtrl'
import CardLayout from 'components/CardLayout/CardLayout'
import MissInfo from 'pages/Mission/components/MissionInformation'
import Sinister from 'pages/ManagerClaim/pages/Sinister'
import OrientationReport from 'pages/ManagerClaim/pages/OrientationReport'
import Orientation from 'pages/ManagerClaim/pages/Orientation'
import Instruction from 'pages/ManagerClaim/pages/Instruction'
import PolicyInformation from 'pages/ManagerClaim/pages/PolicyInformation'
import Calculation from 'pages/Mission/pages/Calculation/Calculation'
import VentilationREN from 'pages/Mission/pages/VentilationREN/Ren'
import ErrorBoundary from 'components/ErrorBoundary'
import Button from 'components/Button/Button'
import Teleexpertise from 'pages/Mission/components/Teleexpertise/Teleexpertise'

const ManagerClaim = ({
  ManagerClaimStore,
  ManagerClaimStore: { loadData, loading, claim, isPending, isIRSI },
  UserStore: { customer, hasContractEE, isExpert },
  CartStore: { easyEstimation },
  TeleexpertiseCtrl: { setProperty },
}) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { path } = useRouteMatch('/:type/:id')

  useEffect(() => {
    loadData(id)
  }, [loadData, id])

  if (loading || isNil(claim)) return <Loader loading={t('common.loading')} />

  return (
    <div className="claim-page container pl-sm-0 pr-sm-0 pb-5 mb-5">
      {isPending && (
        <div className="alert alert-warning text-center">{t('managerClaim.sideNav.pending')}</div>
      )}

      <div className="row d-none d-lg-flex align-items-center mb-3">
        <div className="col-3">
          <h5 className="title-side-nav mb-0">{t('claim.sideNav.title')}</h5>
        </div>

        <h5 className="col-2 sinister-title pr-0 mb-0">
          {t('mission.titleSinister')}
          &nbsp;
          {ramdaPath(['claimInformation', 'claimNumber'], claim) || '-'}
        </h5>

        <div className="col-3 pl-4">
          {customer.hasContract('CAM') && (
            <React.Fragment>
              <Teleexpertise />
              <Button
                className="btn btn-primary"
                onClick={() => {
                  setProperty('showTeleexpertiseForm', true)
                }}
              >
                {t('teleexpertise.openTeleexpertise')}
              </Button>
            </React.Fragment>
          )}
        </div>

        <div className="col-4">
          <StatusSteps current={claim.status.key} />
        </div>
      </div>

      <div className="row align-items-center align-items-lg-start">
        <div className="col-3 mb-3 mb-lg-0">
          <SideNav />
        </div>
        <div className="col-7 offset-2 mb-3 d-block d-lg-none">
          <StatusSteps current={claim.status.key} />
        </div>

        <div className="col-12 col-lg-9">
          {isExpert && MissionHeaderCtrl.headerInfo && (
            <CardLayout className="mission-info-header mb-3">
              <MissInfo />
            </CardLayout>
          )}
          <ErrorBoundary>
            <Switch key="claim-pages">
              <Route exact path={`${path}/policy`} component={PolicyInformation} />
              <Route exact path={`${path}/partyInvolved`} component={PartyInvolved} />
              <Route
                exact
                path={`${path}/SupportingDocuments`}
                component={SupportingDocumentsPage}
              />
              <Route
                path={`${path}/calculation`}
                render={() => {
                  if (!hasContractEE || isIRSI || !easyEstimation) return <Calculation />

                  return <EasyEstimation store={ManagerClaimStore} />
                }}
              />
              <Route
                exact
                path={`${path}/ventilation`}
                render={() => {
                  if (!easyEstimation) return <VentilationREN />

                  return <Redirect to="calculation" />
                }}
              />
              <Route exact path={`${path}/declaration`} component={Sinister} />
              <Route exact path={`${path}/instruction`} component={Instruction} />
              <Route exact path={`${path}/report`} component={OrientationReport} />
              <Route exact path={`${path}/orientation`} component={Orientation} />
              <Route exact path={`${path}/events`} component={Events} />
              <Route exact path={`${path}/messaging`} component={Messaging} />
              <Route component={NotFound} />
            </Switch>
          </ErrorBoundary>
        </div>
      </div>
    </div>
  )
}

export default compose(
  PageLayout,
  inject('ManagerClaimStore', 'UserStore', 'CartStore', 'TeleexpertiseCtrl'),
  observer,
)(ManagerClaim)
