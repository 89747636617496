import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { isNil, add } from 'ramda'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import PropertyEmbellishmentCtrl from 'stores/Mission/EasyEstimation/PropertyEmbellishmentCtrl'
import CardLayout from 'components/CardLayout/CardLayout'
import Loader from 'components/Loader/Loader'
import PropertyEmbellishment from './PropertyEmbellishment'
import Furniture from './Furniture'
import RelatedCost from './RelatedCost'
import LeakSearch from './LeakSearch'
import SaveEstimation from 'components/SaveEstimation'
import { euro } from 'utils/currency'
import { furnituresPrice, relatedCostsPrice, calculTotalPackages } from 'utils/cart'
import AlertCompensatory from 'pages/ManagerClaim/pages/Instruction/Tables/AlertCompensatory'

const EasyEstimation = ({
  store,
  CartStore: {
    loading,
    setProperty,
    propertyPackages,
    embellishmentPackages,
    furnitures,
    relatedCostPackages,
    leakPackages,
    handleKonamiCode,
  },
  ManagerClaimStore: { isClaimClosed },
  MissionStore: { isMissionClosed },
}) => {
  const { t } = useTranslation()
  const { type } = useParams()
  const isClosed = type === 'mission' ? isMissionClosed : isClaimClosed

  useEffect(() => {
    window.scrollTo(0, 0)
    document.addEventListener('keydown', handleKonamiCode)
    return () => {
      PropertyEmbellishmentCtrl.resetCoveringForm()
      document.removeEventListener('keydown', handleKonamiCode)
    }
    // eslint-disable-next-line
  }, [])

  if (loading && !isNil(store)) {
    return (
      <CardLayout>
        <Loader />
      </CardLayout>
    )
  }

  const total =
    [...propertyPackages, ...embellishmentPackages].reduce(
      (acc, data) => add(acc, data.finalPrice),
      0,
    ) +
    furnituresPrice(furnitures) +
    relatedCostsPrice(relatedCostPackages, 'relatedCost') +
    calculTotalPackages({ packages: leakPackages, key: 'finalPriceCatalog', selfRepair: false })

  return (
    <CardLayout>
      <div className="easy-estimation container pl-0 pr-0">
        <SaveEstimation className="btn btn-danger mb-3" />
        <div className="d-flex justify-content-between align-items-center mb-3">
          <button
            className={classNames('btn btn-primary mb-3', { 'not-allowed': isClosed })}
            onClick={() => {
              setProperty('easyEstimation', false)
            }}
            disabled={isClosed}
          >
            {t('mission.easyEstimation.transformToCalculation')}
          </button>
          <AlertCompensatory />
          <div className="d-flex flex-column align-items-end">
            <div className="font-weight-bold">Total des dommages : {euro(total)}</div>
          </div>
        </div>

        <PropertyEmbellishment store={store} className="mb-4" />

        <Furniture className="mb-4" />

        <RelatedCost />

        {store.isWaterDamage && <LeakSearch className="mt-4" />}
      </div>
    </CardLayout>
  )
}

EasyEstimation.defaultProps = {
  store: null,
}

EasyEstimation.propTypes = {
  store: PropTypes.object.isRequired,
}

export default inject('UserStore', 'CartStore', 'MissionStore', 'ManagerClaimStore')(
  observer(EasyEstimation),
)
