import React, { Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import { NavLink, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { formatWan } from 'utils'
import ProtectedLink from 'components/ProtectedLink/ProtectedLink'
import ClaimButtons from 'pages/Claim/components/ClaimButtons'

const ClaimDesktop = ({
  ClaimStore: { validMissions, isQualified, isToQualify, isClaimOverran },
  MessagingStore: { notifications },
}) => {
  const { t } = useTranslation()
  const { id } = useParams()

  return (
    <Fragment>
      <div className="nav flex-column nav-pills">
        <NavLink className="nav-link" activeClassName="active" to={`/claim/${id}/information`}>
          {t('mission.sideNav.information')}
        </NavLink>
        <NavLink className="nav-link" activeClassName="active" to={`/claim/${id}/claim`}>
          {t('claim.sideNav.information')}
        </NavLink>
        <NavLink className="nav-link" activeClassName="active" to={`/claim/${id}/policy`}>
          {t('mission.sideNav.policy')}
        </NavLink>
      </div>
      <h5 className="mt-4 mb-4">{t('mission.sideNav.titleAnnexe')}</h5>
      <div className="nav flex-column nav-pills">
        <NavLink className="nav-link" activeClassName="active" to={`/claim/${id}/partyInvolved`}>
          {t('mission.sideNav.partyInvolved')}
        </NavLink>

        <NavLink
          className="nav-link"
          activeClassName="active"
          to={`/claim/${id}/supportingDocuments`}
        >
          {t('mission.sideNav.supportingDocument')}
        </NavLink>
      </div>

      <h5 className="mt-4 mb-4">{t('mission.sideNav.titleCommunication')}</h5>

      <div className="nav flex-column nav-pills">
        <div className="container-notif">
          <NavLink className="nav-link" activeClassName="active" to={`/claim/${id}/messaging`}>
            {t('messaging.title')}

            {notifications > 0 && <span className="badge badge-danger ml-1">{notifications}</span>}
          </NavLink>

          <NavLink className="nav-link" activeClassName="active" to={`/claim/${id}/events`}>
            {t('events.title')}
          </NavLink>
        </div>
      </div>

      <h5 className="mt-4 mb-4">{t('claim.sideNav.missions.title')}</h5>
      <div className="nav flex-column nav-pills">
        <ProtectedLink to={`/claim/${id}/qualification`} condition={!isToQualify}>
          {t('claim.sideNav.missions.qualification')}
        </ProtectedLink>

        <ProtectedLink to={`/claim/${id}/planner`} condition={!isQualified}>
          {t('claim.sideNav.missions.planner')}
        </ProtectedLink>

        {isClaimOverran && (
          <div className="nav-link protected">{t('claim.sideNav.missions.overran')}</div>
        )}

        {validMissions.map(mission => (
          <NavLink
            className="nav-link"
            activeClassName="active"
            to={`/claim/${id}/currentMission/${mission.cfaWan}`}
            key={mission.cfaWan}
          >
            {formatWan(mission.cfaWan)}
          </NavLink>
        ))}
      </div>

      <div className="mt-4 justify-content-between">
        <ClaimButtons />
      </div>
    </Fragment>
  )
}

export default inject('ClaimStore', 'MessagingStore')(observer(ClaimDesktop))
