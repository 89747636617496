import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer, inject } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import Label from 'components/Label/Label'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'
import TextArea from 'components/Forms/TextArea'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import Button from 'components/Button/Button'
import AlertCtrl from 'stores/Common/view/AlertCtrl'
import classNames from 'classnames'

const ActionModal = ({
  CreateMissionCtrl: {
    formatMotifs,
    formatPoles,
    cancelMission,
    canceling,
    reassignPole,
    reassigning,
  },
  history,
  match: {
    params: { id },
  },
  showModal,
  closeModal,
}) => {
  const [grabbing, setGrabbing] = useState(false)
  const [motif, setMotif] = useState('')
  const [reassignMessage, setReassignMessage] = useState('')
  const [pole, setPole] = useState('')
  const [cancelMessage, setCancelMessage] = useState('')
  const [messageDisabled, setMessageDisabled] = useState(true)
  const [messageRequired, setMessageRequired] = useState(true)
  const { t } = useTranslation()

  useEffect(() => {
    if (showModal === 'cancel') {
      setMessageRequired(false)
      if ('CANCELED_INJURED_DEFICIENCY' === motif) {
        setMessageDisabled(true)
        setCancelMessage()
      } else {
        setMessageDisabled(false)
      }
    } else {
      setMessageDisabled(false)
      setMessageRequired(true)
    }
  }, [showModal, motif])

  const onClose = () => {
    setValue(null)
    setMessage('')
    closeModal()
  }

  const options = showModal === 'reassign' ? formatPoles : formatMotifs

  const validateCancel = async () => {
    if (motif) {
      try {
        await cancelMission(id, { motif, message: cancelMessage })
        history.push('/')
      } catch (err) {}
    } else {
      AlertCtrl.alert('danger', 'mission.qualification.motifRequired')
    }
  }

  const validateReassign = async () => {
    if (pole && reassignMessage) {
      try {
        await reassignPole(id, { pole, message: reassignMessage })
        history.push('/')
      } catch (err) {}
    } else if (pole && !reassignMessage) {
      AlertCtrl.alert('danger', 'mission.qualification.messageRequired')
    } else {
      AlertCtrl.alert('danger', 'mission.qualification.poleRequired')
    }
  }

  const setValue = value => (showModal === 'reassign' ? setPole(value) : setMotif(value))
  const setMessage = value =>
    showModal === 'reassign' ? setReassignMessage(value) : setCancelMessage(value)

  const message = showModal === 'reassign' ? reassignMessage : cancelMessage
  const choicesName = t(`mission.qualification.${showModal === 'reassign' ? 'pole' : 'motif'}`)

  return (
    <ModalWithBackdrop
      size="modal-md"
      show={Boolean(showModal)}
      close={onClose}
      draggable
      setGrabbing={setGrabbing}
    >
      <div className={classNames('modal-header', { grabbable: !grabbing, grabbing: grabbing })}>
        <h5 className="modal-title">{t(`mission.qualification.${showModal}Modal`)}</h5>
        <button type="button" className="close" onClick={closeModal}>
          <span>&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="row no-gutters form-group">
          <Label className="col-2 col-form-label" required>
            {choicesName} :
          </Label>
          <div className="col-10">
            <ResponsiveSelect
              options={options}
              value={showModal === 'reassign' ? pole : motif}
              onChange={e => setValue(e.target.value)}
            />
          </div>
        </div>

        <div className="row no-gutters form-group">
          <Label className="col-2 col-form-label" required={messageRequired}>
            {t('mission.qualification.message')} :
          </Label>

          <div className="col-10">
            <TextArea
              className="form-control"
              value={message}
              onChange={e => setMessage(e.target.value)}
              required={messageRequired}
              disabled={messageDisabled}
            />
          </div>
        </div>
      </div>
      <div className="modal-footer pb-2 pt-2">
        <button type="button" className="btn btn-secondary" onClick={onClose}>
          {t('common.cancel')}
        </button>
        <Button
          loading={showModal === 'reassign' ? reassigning : canceling}
          disabled={showModal === 'reassign' ? reassigning : canceling}
          className="btn btn-primary"
          onClick={showModal === 'reassign' ? validateReassign : validateCancel}
        >
          {t('mission.qualification.validate')}
        </Button>
      </div>
    </ModalWithBackdrop>
  )
}

export default compose(
  inject('CreateMissionCtrl'),
  withRouter,
  observer,
)(ActionModal)
