import { observable, computed, action, decorate } from 'mobx'

import { uid } from 'utils'

class AbstractField {
  condition = null

  constructor(data) {
    this.id = data.id || uid(10)
    this.type = data.type
    this.parent = data.parent || null
    this.evaluationRule = data.evaluationRule || null
    this.label = data.label || ''
    this.required = data.required || false
    this.name = data.name || ''
    this.rank = data.rank || null
    this.text = data.text || ''
    this.path = data.path || null
    this.disabled = data.disabled || false
    this.group = data.group || ''
    this.groupId = data.groupId || null
    this.groupLabel = data.groupLabel || ''
    this.placeholder = data.placeholder || ''
    this.exclude = data.exclude || []
    if (data.parent) {
      this.condition = data.parent.notEmpty ? true : data.parent.condition
    }
    this.value = data.value || null
    this.originalValue = data.value || null
    this.default = data.default || null
    this.alertMessage = data.alertMessage || null
    this.rows = data.rows || 5
  }

  get changed() {
    return this.originalValue !== this.value
  }

  setOriginalData() {
    this.value = this.originalValue
  }

  setProperty = (key, value) => {
    this[key] = value
  }
}

const DecoratedAbstractField = decorate(AbstractField, {
  value: observable,
  originalValue: observable,

  changed: computed,

  setProperty: action,
  setOriginalData: action,
})

export default DecoratedAbstractField
