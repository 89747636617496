import { observable, action, computed, runInAction, toJS, decorate } from 'mobx'
import { parseISO, compareAsc, compareDesc } from 'date-fns'
import moment from 'moment'
import i18next from 'i18next'

import DashboardFilter from './DashboardFilter'
import DashboardCtrl from 'stores/Common/view/DashboardCtrl'
import CommonStore from 'stores/Common/domain/CommonStore'
import UserStore from 'stores/Common/domain/UserStore'
import { getDataLocal, setDataLocal, deleteDataLocal } from 'stores/localStorage'
import { saveDashboardFilter } from 'services/dashboardFilters'
import { uid } from 'utils'

const DashboardSorting = Object.freeze({
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
  TYPE: {
    STRING: 'string',
    DATE: 'date',
    NUMBER: 'number',
  },
})

class DashboardFilterStore {
  showForm = false
  currentFilter = null
  filters = []
  search = ''
  searchingNow = ''
  previousFilter = null

  constructor() {
    this.currentFilter = new DashboardFilter()
  }

  init = () => {
    const dashboardFilter = getDataLocal(this.localStorageKey)

    if (dashboardFilter) this.currentFilter = new DashboardFilter(dashboardFilter)
    else this.initDefaultFilter()
  }

  initDefaultFilter() {
    const { defaultFilterSelected } = DashboardCtrl
    if (defaultFilterSelected) {
      this.setDefaultFilter({
        missionStatus: defaultFilterSelected.key,
        name: i18next.t(defaultFilterSelected.name),
      })
    } else {
      this.currentFilter = new DashboardFilter({
        id: uid(10),
        name: i18next.t('dashboard.filter.allMissions'),
        assigneeUserId: 'all',
        missionType: 'all',
        missionStatus: 'allOpen',
        sortedBy: 'descending_date_mission.createdAt',
        dynamicFilters: [],
        default: true,
      })
    }
  }

  get localStorageKey() {
    return `dashboardFilter_${UserStore.id}_${UserStore.isExpert}_${DashboardCtrl.missionsView}`
  }

  get defaultFilters() {
    return DashboardCtrl.defaultFilters
  }

  get isNewFilter() {
    return this.filters.find(filter => filter.id === this.currentFilter.id) === undefined
  }

  filterByAssigneeUserId(missions) {
    if (this.currentFilter.assigneeUserId === 'all') return missions
    return missions
    // return missions.filter(mission => mission.id === this.currentFilter.assigneeUserId)
  }

  filterByMissionType(missions) {
    if (this.currentFilter.missionType === 'all') return missions

    return missions.filter(
      mission =>
        mission['mission.missionInsurerInformations.missionType.value'] ===
        CommonStore.findMissionTypeValue(this.currentFilter.missionType),
    )
  }

  filterByMissionStatus(missions) {
    if (this.currentFilter.missionStatus === 'allOpen')
      return missions.filter(
        m => m['status.key'] !== 'CICC' && m['status.key'] !== 'CACC' && m['status.key'] !== 'CICT',
      )

    const statuses = DashboardCtrl.getStatusesByFilters([this.currentFilter.missionStatus])

    return missions.filter(mission => statuses.includes(mission['status.key']))
  }

  filterByDate(dateFilter, missions) {
    let startDate = null
    let endDate = null

    if (dateFilter.value && typeof dateFilter.value.key === 'string') {
      const now = moment()
      switch (dateFilter.value.key) {
        case 'today':
          startDate = now.clone().startOf('day')
          endDate = now.clone().endOf('day')
          break
        case 'todayMorning':
          startDate = now.clone().startOf('day')
          endDate = now
            .clone()
            .hours(12)
            .minutes(0)
            .seconds(0)
          break
        case 'todayAfternoon':
          startDate = now
            .clone()
            .hours(12)
            .minutes(0)
            .seconds(0)
          endDate = now.clone().endOf('day')
          break
        case 'tomorrow':
          startDate = now
            .clone()
            .add(1, 'days')
            .startOf('day')
          endDate = now
            .clone()
            .add(1, 'days')
            .endOf('day')
          break
        case 'tomorrowMorning':
          startDate = now
            .clone()
            .add(1, 'days')
            .startOf('day')
          endDate = now
            .clone()
            .add(1, 'days')
            .hours(12)
            .minutes(0)
            .seconds(0)
          break
        case 'tomorrowAfternoon':
          startDate = now
            .clone()
            .add(1, 'days')
            .hours(12)
            .minutes(0)
            .seconds(0)
          endDate = now
            .clone()
            .add(1, 'days')
            .endOf('day')
          break
        default:
          break
      }
    }

    if (dateFilter.value.startDate instanceof moment) {
      startDate = dateFilter.value.startDate
    }

    if (dateFilter.value.endDate instanceof moment) {
      endDate = dateFilter.value.endDate
    }

    return missions.filter(mission => {
      const missionDate = toJS(mission[dateFilter.key])

      // protection if mission date is null
      if (!missionDate) return false
      // => createdAt is a string
      // => appointmentDate is a object with timezone
      const momentMissionDate =
        typeof missionDate === 'string'
          ? moment(missionDate)
          : moment(missionDate.date).utc(missionDate.timezone_type)

      // start date and end date is filled
      if (startDate && endDate) {
        return momentMissionDate.isBetween(startDate, endDate)
      }

      if (startDate) {
        return momentMissionDate.isSameOrAfter(startDate)
      }

      if (endDate) {
        return momentMissionDate.isSameOrBefore(endDate)
      }

      // No previous case return all mission for now
      return true
    })
  }

  filterByDynamicFilters(missions) {
    if (this.currentFilter === null || this.currentFilter.dynamicFilters.length === 0)
      return missions

    let filteredMissions = missions

    this.currentFilter.dynamicFilters.forEach(df => {
      if (df.type === 'string') {
        filteredMissions = filteredMissions.filter(mission =>
          mission[df.key]
            ? mission[df.key].toLowerCase().indexOf(df.value.toLowerCase()) > -1
            : false,
        )
      } else if (df.type === 'date') {
        filteredMissions = this.filterByDate(df, filteredMissions)
      }
    })

    return filteredMissions
  }

  searchableKey(key) {
    switch (key) {
      case 'mission.missionInsurerInformations.missionType.value':
        return false
      case 'mission.createdAt':
        return false
      case 'cfi.createdAt':
        return false
      case 'createdAt':
        return false
      case 'mission.appointmentDate':
        return false
      default:
        return this.currentFilter.dynamicFilters.map(df => df.key).indexOf(key) === -1
    }
  }

  get searchableKeys() {
    return DashboardCtrl.headers.reduce((keys, header) => {
      if (header.searchable && this.searchableKey(header.field)) {
        keys.push(header.field)
      }
      return keys
    }, [])
  }

  searchableFields(mission) {
    return this.searchableKeys.reduce((values, key) => {
      if (mission[key] !== undefined) values.push(mission[key])
      return values
    }, [])
  }

  filterBySearch(missions) {
    if (!this.search.length) return missions

    return missions.filter(mission =>
      this.searchableFields(mission).some(value =>
        value.toLowerCase().includes(this.search.toLowerCase()),
      ),
    )
  }

  sortMissions(a, b) {
    if (this.currentFilter === null || this.currentFilter.sortedBy === null) return 0

    const {
      sortedByInformations: { direction, type, field },
    } = this.currentFilter

    if (type === DashboardSorting.TYPE.DATE) {
      var date1 = null
      var date2 = null
      if (a[field] !== undefined && a[field].date !== undefined) {
        date1 = parseISO(a[field].date)
      } else {
        date1 = new Date('January 1, 1970 00:00:00')
      }

      if (b[field] !== undefined && b[field].date !== undefined) {
        date2 = parseISO(b[field].date)
      } else {
        date2 = new Date('January 1, 1970 00:00:00')
      }

      return direction === DashboardSorting.ASCENDING
        ? compareAsc(date1, date2)
        : compareDesc(date1, date2)
    }

    if (a[field] > b[field]) {
      return direction === DashboardSorting.ASCENDING ? 1 : -1
    }

    if (a[field] < b[field]) {
      return direction === DashboardSorting.ASCENDING ? -1 : 1
    }
    return 0
  }

  loadFilters(filters) {
    this.filters = filters.map(filter => new DashboardFilter(filter))
  }

  setDefaultFilter({ missionStatus, name }) {
    this.currentFilter = new DashboardFilter({
      id: uid(10),
      name,
      assigneeUserId: 'all',
      missionType: 'all',
      missionStatus,
      sortedBy: 'descending_date_mission.createdAt',
      dynamicFilters: [],
      default: true,
    })
    setDataLocal(this.localStorageKey, toJS(this.currentFilter))
  }

  setCurrentFilter(id = null) {
    if (id) {
      const selectedFilter = this.filters.find(filter => filter.id === id)
      this.currentFilter = selectedFilter
      setDataLocal(this.localStorageKey, toJS(selectedFilter))
    } else {
      this.previousFilter = this.currentFilter
      this.currentFilter = new DashboardFilter()
      deleteDataLocal(this.localStorageKey)
    }
  }

  resetFilter() {
    this.initDefaultFilter()
    deleteDataLocal(this.localStorageKey)
  }

  submitSearch() {
    this.searchingNow = this.search
  }

  closeFilter() {
    if (!this.currentFilter.saved) {
      this.currentFilter = this.previousFilter
    }
    this.showForm = false
  }

  async deleteFilter() {
    this.filters = this.filters.filter(filter => filter.id !== this.currentFilter.id)
    try {
      const dashboardType = DashboardCtrl.getMissionsParams.replace('?service=', '')
      await saveDashboardFilter({ dashboardType, filters: toJS(this.filters) })

      runInAction(() => {
        this.showForm = false
        this.resetFilter()
      })
    } catch (err) {
      console.log(err)
    }
  }

  async saveFilters() {
    if (this.currentFilter.name !== '') {
      const exist = this.filters.find(filter => filter.id === this.currentFilter.id)
      if (!exist) {
        this.filters.push(this.currentFilter)
      }

      try {
        const dashboardType = DashboardCtrl.getMissionsParams.replace('?service=', '')
        await saveDashboardFilter({ dashboardType, filters: toJS(this.filters) })

        runInAction(() => {
          this.showForm = false
          this.currentFilter.setProperty('saved', true)
          setDataLocal(this.localStorageKey, toJS(this.currentFilter))
        })
      } catch (err) {
        console.log(err)
        // remove not saved filter
        runInAction(() => {
          this.filters = this.filters.filter(filter => filter.name === this.currentFilter)
        })
      }
    }
  }

  setProperty(key, value) {
    this[key] = value
  }

  getOptionsOfSelectField(field) {
    let options = []
    DashboardCtrl.missionsData.forEach(mission => {
      if (!mission[field]) return
      if (
        options.find(option => {
          return option.value === mission[field]
        })
      )
        return
      options.push({
        label: mission[field],
        value: mission[field],
      })
    })

    return options
  }
}

const DecoratedDashboardFilterStore = decorate(DashboardFilterStore, {
  showForm: observable,
  currentFilter: observable,
  filters: observable,
  search: observable,
  searchingNow: observable,
  previousFilter: observable,

  setProperty: action,
  saveFilters: action,
  deleteFilter: action,
  submitSearch: action,
  resetFilter: action,
  setCurrentFilter: action,
  setDefaultFilter: action,
  loadFilters: action,
  init: action,
  closeFilter: action,

  localStorageKey: computed,
  defaultFilters: computed,
  isNewFilter: computed,
  searchableKeys: computed,
})

export default new DecoratedDashboardFilterStore()
