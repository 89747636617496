import React from 'react'
import { inject, observer } from 'mobx-react'
import { path } from 'ramda'

const AlertCompensatory = ({ CartStore: { compensatoryPackages } }) => {
  const compensatoryGag = path(['selfRepair', 'value'], compensatoryPackages)
  const compensatoryRen = path(['ren', 'value'], compensatoryPackages)
  const compensatoryPecunary = path(['propertyAndEmbellishment', 'value'], compensatoryPackages)

  return (
    <div>
      {compensatoryGag > 0 && (
        <div className="text-danger">
          <i className="fa fa-warning" /> Plancher GAG non atteint
        </div>
      )}
      {compensatoryGag < 0 && (
        <div className="text-danger">
          <i className="fa fa-warning" /> Plafond GAG dépassé
        </div>
      )}
      {compensatoryRen > 0 && (
        <div className="text-danger">
          <i className="fa fa-warning" /> Plancher REN non atteint
        </div>
      )}
      {compensatoryRen < 0 && (
        <div className="text-danger">
          <i className="fa fa-warning" /> Plafond REN dépassé
        </div>
      )}
      {compensatoryPecunary > 0 && (
        <div className="text-danger">
          <i className="fa fa-warning" /> Plancher Indemmité pécuniaire non atteint
        </div>
      )}
      {compensatoryPecunary < 0 && (
        <div className="text-danger">
          <i className="fa fa-warning" /> Plafond Indemmité pécuniaire dépassé
        </div>
      )}
    </div>
  )
}

export default inject('CartStore')(observer(AlertCompensatory))
