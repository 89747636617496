import React, { Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import { NavLink, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import SmartLink from 'components/SmartLink/SmartLink'
import GenerateReportLink from './GenerateReportLink'

const MissionMobile = ({
  PrejudiceStore: { payloads, ready },
  UiCtrl: { toggleSideNav },
  MissionStore: { isHorsDarva, isIRSI, isASP, pvRequired },
}) => {
  const onClick = () => toggleSideNav(false)
  const { t } = useTranslation()
  const { id } = useParams()

  return (
    <div className="side-nav">
      <h5 className="pt-3">{t('mission.sideNav.title')}</h5>
      <NavLink
        className="nav-link"
        activeClassName="active"
        to={`/mission/${id}/information`}
        onClick={onClick}
      >
        {t('mission.sideNav.information')}
      </NavLink>
      <NavLink
        className="nav-link"
        activeClassName="active"
        to={`/mission/${id}/policy`}
        onClick={onClick}
      >
        {t('mission.sideNav.policy')}
      </NavLink>
      <NavLink
        className="nav-link"
        activeClassName="active"
        to={`/mission/${id}/claim`}
        onClick={onClick}
      >
        {t('mission.sideNav.claim')}
      </NavLink>

      <h5 className="border-top pt-3 mt-2">{t('mission.sideNav.titleAnnexe')}</h5>

      <NavLink
        className="nav-link"
        activeClassName="active"
        to={`/mission/${id}/partyInvolved`}
        onClick={onClick}
      >
        {t('mission.sideNav.partyInvolved')}
      </NavLink>
      <NavLink
        className="nav-link"
        activeClassName="active"
        to={`/mission/${id}/supportingDocuments`}
        onClick={onClick}
      >
        {t('mission.sideNav.supportingDocument')}
      </NavLink>
      {!isHorsDarva && (
        <Fragment>
          <h5 className="border-top pt-3 mt-2">{t('mission.sideNav.titleEvaluation')}</h5>

          <SmartLink page="riskConformity">{t('mission.sideNav.riskConformity')}</SmartLink>
          <SmartLink page="causesAndCircumstances">
            {t('mission.sideNav.causesAndCircumstances')}
          </SmartLink>
          <SmartLink page="damageDescription">{t('mission.sideNav.damageDescription')}</SmartLink>
          {isIRSI && <SmartLink page="irsi">{t('mission.sideNav.irsi')}</SmartLink>}
          <SmartLink page="calculation">{t('mission.sideNav.calculation')}</SmartLink>
          <SmartLink page="ventilation">{t('mission.sideNav.ventilationREN')}</SmartLink>
          {!isASP && ready && !!payloads.length && (
            <SmartLink page="prejudice">{t('mission.sideNav.prejudice')}</SmartLink>
          )}
          <SmartLink page="conclusion">{t('mission.sideNav.conclusion')}</SmartLink>
          {pvRequired && <SmartLink page="pv">{t('mission.sideNav.pv')}</SmartLink>}
          <SmartLink page="rates">{t('mission.sideNav.rates')}</SmartLink>

          <h5 className="border-top pt-3 mt-2">{t('mission.sideNav.titleReport')}</h5>

          <GenerateReportLink />
        </Fragment>
      )}
      <h5 className="border-top pt-3 mt-2">{t('mission.sideNav.titleCommunication')}</h5>
      <NavLink className="nav-link" activeClassName="active" to={`/mission/${id}/events`}>
        {t('events.title')}
      </NavLink>
    </div>
  )
}

export default inject('PrejudiceStore', 'UiCtrl', 'MissionStore')(observer(MissionMobile))
