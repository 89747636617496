import React, { useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import CardLayout from 'components/CardLayout/CardLayout'
import Loader from 'components/Loader/Loader'
import Filters from './Filters'
import Event from './Event'

function Events({
  UiCtrl: { isMobile },
  EventCtrl: { loading, eventsFiltered, loadData, resetFilters },
}) {
  const { t } = useTranslation()
  const { id } = useParams()
  useEffect(() => {
    loadData(id)

    return resetFilters
  }, [id, loadData, resetFilters])

  if (loading)
    return (
      <CardLayout className="events">
        <Loader />
      </CardLayout>
    )
  return (
    <CardLayout className="events">
      <div className="row mb-3">
        <div className="col-12">{t('events.title')}</div>
      </div>

      <Filters />

      <table className="table table-bordered">
        <thead>
          <tr className="bg-primary text-light">
            {!isMobile && <th>{t('events.user')}</th>}
            {!isMobile && <th>{t('events.level')}</th>}
            {!isMobile && <th>{t('events.category')}</th>}
            <th>{t('events.action')}</th>
            <th>{t('events.date')}</th>
          </tr>
        </thead>
        <tbody>
          {eventsFiltered.length > 0 ? (
            eventsFiltered.map(event => <Event key={event.id} data={event} />)
          ) : (
            <tr>
              <td colSpan="6" className="text-center">
                {t('events.none')}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </CardLayout>
  )
}

export default inject('UiCtrl', 'EventCtrl')(observer(Events))
