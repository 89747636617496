import React from 'react'
import { observer } from 'mobx-react'

import SupportingDocumentsCtrl from 'stores/Common/view/SupportingDocumentsCtrl'
import SupportingDocuments from 'pages/SupportingDocuments/SupportingDocuments'

class SupportingDocumentsPage extends React.Component {
  componentDidMount() {
    SupportingDocumentsCtrl.setIsCreateMissionSelector(false)
  }

  render() {
    return <SupportingDocuments />
  }
}

export default observer(SupportingDocumentsPage)
