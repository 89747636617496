import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { path, isNil } from 'ramda'

import CardLayout from 'components/CardLayout/CardLayout'
import { formatDate, concatAddress, formatPhone } from 'utils'
import Table from 'components/Table'
import FieldsBloc from 'components/FieldsBloc'
import CollapseRow from 'components/CollapseRow/CollapseRow'
import { formatCurrency } from 'utils/currency'

const Claim = ({
  t,
  ClaimStore,
  ClaimStore: { claim: clm, loading },
  MissionStore,
  UserStore: { isExpert },
}) => {
  if (loading) return null

  const claim = isExpert ? MissionStore.cfa.cfi : clm
  const isIME = isExpert ? MissionStore.isIME : ClaimStore.isIME
  const previousClaimTable = {
    fields: ['previousClaimId', 'previousDisasterDate', 'previousPaidIndemnity'],
    values: path(['claimInformation', 'previousClaim'], claim) || [],
    headers: [
      t('mission.claim.previousClaimId'),
      t('mission.claim.previousDisasterDate'),
      t('mission.claim.paidIndemnity'),
    ],
    noValue: t('mission.claim.noClaimFound'),
  }

  const personsIdentified = {
    fields: isIME
      ? ['thirdPartyRole.name', 'fullName', 'qualifyingValues', 'referenceValues']
      : ['thirdPartyRole.name', 'fullName', 'secondaryThirdPartyRole'],
    values: path(['involvedParties'], claim) || [],
    headers: isIME
      ? [
          t('mission.claim.quality'),
          t('mission.claim.name'),
          t('mission.claim.qualifying'),
          t('mission.claim.reference'),
        ]
      : [t('mission.claim.quality'), t('mission.claim.name'), t('mission.claim.codeIdentiy')],
    noValue: t('mission.claim.noPersonFound'),
  }

  const claimAmount = path(['claimInformation', 'claimAmount', 'amount'], claim)

  return (
    <CardLayout className="claim">
      <FieldsBloc
        noTopDivider
        fields={[
          {
            name: t('mission.claim.lossDate'),
            value: formatDate(path(['claimInformation', 'dateOfLoss'], claim)),
          },
          {
            name: t('mission.claim.id'),
            value: path(['claimInformation', 'insurerClaimId'], claim),
          },
          {
            name: t('mission.claim.claimNature'),
            value: `${path(['claimInformation', 'coverage', 'value'], claim)}
            ${
              path(['claimInformation', 'eventType', 'value'], claim)
                ? `(${path(['claimInformation', 'eventType', 'value'], claim)})`
                : ''
            }`,
          },
          {
            name: t('mission.claim.guarantee'),
            value:
              path(['contract', 'guarantees[0]', 'guarantee', 'value'], claim) ||
              path(['contract', 'guarantees[0]', 'guaranteeStringName'], claim),
          },
          {
            name: t('mission.claim.amount'),
            value: formatCurrency(
              claimAmount !== 0 && !isNil(claimAmount) ? claimAmount : '-',
              path(['claimInformation', 'claimAmount', 'currency'], claim),
            ),
          },
          {
            name: t('mission.claim.startingPoint'),
            value: path(['claimInformation', 'declaredStartPoint', 'value'], claim),
          },
          {
            name: isIME
              ? t('mission.claim.circumstances')
              : t('mission.claim.startingOriginDescription'),
            value: path(['claimInformation', 'startingOriginDescription'], claim),
          },
          {
            name: t('mission.claim.observedDammage'),
            value: path(['claimInformation', 'observedDammage'], claim),
          },
          {
            name: t('mission.claim.observations'),
            value: path(['claimInformation', 'observations'], claim),
          },
          {
            name: t('mission.claim.reclamation'),
            value: path(['contract', 'riskDescription', 'reclamation'], claim),
          },
          {
            name: t('mission.claim.reclamationToBeSpecified'),
            value: path(['claimInformation', 'claimTypeKey'], claim),
          },
          {
            name: t('mission.claim.darvaComments'),
            value: path(['contract', 'riskDescription', 'comment'], claim),
          },
        ]}
      />
      <Table
        title={t('mission.claim.previousClaimTitle')}
        {...previousClaimTable}
        classNames="mx-4"
      />
      <Table
        title={t('mission.claim.personsIdentified')}
        {...personsIdentified}
        classNames="mx-4"
      />

      <FieldsBloc
        fields={[
          {
            name: t('mission.claim.victimQuality'),
            value: path(
              ['claimantInformation', 'victimInformation', 'corporalQuality', 'value'],
              claim,
            ),
          },
          {
            name: t('mission.claim.victimAge'),
            value: path(['claimantInformation', 'victimInformation', 'age'], claim),
            unit: t('common.years'),
          },
          {
            name: t('mission.claim.sexe'),
            value: path(['claimantInformation', 'claimant', 'civility'], claim),
          },
        ]}
      />
      <div className="divider" />
      <div className="row">
        <div className="font-weight-bold">{t('mission.claim.managerIdentificationTitle')}</div>
      </div>
      <CollapseRow
        rowLabel={t('mission.claim.name')}
        rowValue={`${path(['contract', 'salesAgent', 'firstName'], claim) || ''} ${path(
          ['contract', 'salesAgent', 'lastName'],
          claim,
        ) || ''}`}
        rows={[
          {
            key: t('mission.contact.address'),
            val: concatAddress(path(['contract', 'salesAgent', 'address'], claim)) || '-',
          },
          {
            key: t('mission.contact.phoneNumber'),
            val: formatPhone(path(['contract', 'salesAgent', 'phoneNumber'], claim)) || '-',
          },
          {
            key: t('mission.contact.email'),
            val: path(['contract', 'salesAgent', 'email'], claim) || '-',
          },
          {
            key: t('mission.claim.ug'),
            val: path(['contract', 'salesAgent', 'cde'], claim) || '-',
          },
        ]}
      />
      <FieldsBloc
        title={t('mission.claim.additionalInformation')}
        fields={[
          {
            name: t('mission.claim.numberOfAffectedParts'),
            value: path(['claimInformation', 'numberOfDamagedRooms'], claim),
          },
          {
            name: t('mission.claim.phenat'),
            value: path(['claimInformation', 'phenat'], claim),
          },
          {
            name: t('mission.claim.comment'),
            value: path(['comment'], claim),
          },
        ]}
      />
    </CardLayout>
  )
}

export default withTranslation()(inject('ClaimStore', 'UserStore', 'MissionStore')(observer(Claim)))
