import { observable, action, decorate } from 'mobx'

class MapCtrl {
  itineraryInformations = {
    distance: '',
    duration: '',
  }
  collapsed = true

  setItineraryInformations(informations) {
    if (
      !!informations['routes'].length &&
      informations['routes'][0]['legs'] &&
      !!informations['routes'][0]['legs'].length
    ) {
      this.itineraryInformations = {
        distance: informations.routes[0].legs[0].distance.text,
        duration: informations.routes[0].legs[0].duration.text,
      }
    }
  }

  resetDatas() {
    this.itineraryInformations = {
      distance: '',
      duration: '',
    }
    this.collapsed = true
  }

  toggleCollapsed() {
    this.collapsed = !this.collapsed
  }
}

const DecoratedMapCtrl = decorate(MapCtrl, {
  itineraryInformations: observable,
  collapsed: observable,

  setItineraryInformations: action,
  resetDatas: action,
  toggleCollapsed: action,
})

export default new DecoratedMapCtrl()
