import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'

import CreateManagerClaimCtrl from 'stores/Mission/view/CreateManagerClaimCtrl'
import CommonStore from 'stores/Common/domain/CommonStore'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import Input from 'components/Forms/Input'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'
import Button from 'components/Button/Button'
import Label from 'components/Label/Label'
import Address from 'components/Address/Address'
import DatePicker from 'components/Forms/DatePicker'

class CreateManagerClaimModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      grabbing: false,
    }
  }

  closeModal = () => {
    CreateManagerClaimCtrl.setProperty('showModal', false)
    CreateManagerClaimCtrl.clearForm()
  }
  onChange = e => CreateManagerClaimCtrl.newClaim.setProperty(e.target.name, e.target.value)
  onChangeDate = date => CreateManagerClaimCtrl.newClaim.setProperty('dateOfLoss', date)
  onChangeAddress = address => {
    CreateManagerClaimCtrl.newClaim.setProperty('streetNumber', address.streetNumber)
    CreateManagerClaimCtrl.newClaim.setProperty('addressLine1', address.addressLine1)
    CreateManagerClaimCtrl.newClaim.setProperty('addressLine2', address.addressLine2)
    CreateManagerClaimCtrl.newClaim.setProperty('addressLine3', address.addressLine3)
    CreateManagerClaimCtrl.newClaim.setProperty('city', address.city)
    CreateManagerClaimCtrl.newClaim.setProperty('zipCode', address.zipCode)
    CreateManagerClaimCtrl.newClaim.setProperty('geometry', {
      long: address.long,
      lat: address.lat,
    })
    CreateManagerClaimCtrl.newClaim.setProperty(
      'country',
      address.country === 'FR' ? 'France' : address.country,
    )
  }
  createClaim = async () => {
    try {
      const res = await CreateManagerClaimCtrl.createManagerClaim()
      this.props.history.push(`/managerClaim/${res.id}/policy`)
      CreateManagerClaimCtrl.clearForm()
    } catch (error) {
      console.error(error)
    }
  }
  generateRandomId = () =>
    CreateManagerClaimCtrl.newClaim.setProperty('externalId', new Date().getTime().toString())

  handleError = (errors, name) => {
    if (errors.length === 0) return []
    const error = errors.find(error => error.key === name)
    if (error) return [error.detail]
    return []
  }

  render() {
    const { t } = this.props
    const {
      insurerCustomerId,
      externalId,
      insuredFirstName,
      policyNumber,
      insuredLastName,
      claimType,
      dateOfLoss,
      riskType,
      contractNature,
      errors,
    } = CreateManagerClaimCtrl.newClaim
    const {
      coveragesForSelectInput,
      insuranceCompaniesForSelect,
      riskTypesOptions,
      contractNaturesForSelect,
    } = CommonStore
    const { saving } = CreateManagerClaimCtrl

    return (
      <ModalWithBackdrop
        show={CreateManagerClaimCtrl.showModal}
        size="modal-xl"
        close={this.closeModal}
        draggable
        setGrabbing={grabbing => {
          this.setState({ ...this.state, grabbing })
        }}
      >
        <div
          className={classNames('modal-header', {
            grabbable: !this.state.grabbing,
            grabbing: this.state.grabbing,
          })}
        >
          <h5 className="modal-title">{t('dashboard.createManagerClaim.modal.title')}</h5>
          <button type="button" className="close" onClick={this.closeModal}>
            <span>&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <form autoComplete="off">
            <div className="row">
              <div className="col-12 col-sm-6">
                <div className="row no-gutters">
                  <div className="col-12 col-sm-4 form-group">
                    <Label required>{t('dashboard.createClaim.modal.externalId')}</Label>
                  </div>
                  <div className="col-12 col-sm-8 form-group">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <i
                          className="btn btn-primary fa fa-random"
                          onClick={this.generateRandomId}
                        />
                      </div>
                      <Input
                        className="form-control"
                        name="externalId"
                        value={externalId}
                        onChange={this.onChange}
                        required={true}
                        placeholder={t('dashboard.createClaim.modal.externalId')}
                        autoComplete="new-externalId"
                        errors={this.handleError(errors, 'externalId')}
                        maxLength={20}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6">
                <div className="row no-gutters">
                  <div className="col-12 col-sm-4 form-group">
                    <Label required>{t('dashboard.createClaim.modal.dateOfLoss')}</Label>
                  </div>
                  <div className="col-12 col-sm-8 form-group">
                    <DatePicker
                      className="form-control"
                      name="dateOfLoss"
                      onChange={this.onChangeDate}
                      selected={dateOfLoss}
                      isClearable
                      placeholder={t('dashboard.createClaim.modal.dateOfLoss')}
                      errors={this.handleError(errors, 'dateOfLoss')}
                      maxDate={new Date()}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-6">
                <div className="row no-gutters">
                  <div className="col-12 col-sm-4 form-group">
                    <Label required>{t('dashboard.createClaim.modal.claimType')}</Label>
                  </div>
                  <div className="col-12 col-sm-8 form-group">
                    <ResponsiveSelect
                      name="claimType"
                      value={claimType}
                      options={coveragesForSelectInput}
                      placeholder={t('dashboard.createClaim.modal.claimType')}
                      onChange={this.onChange}
                      required={true}
                      isSearchable
                      errors={this.handleError(errors, 'claimType')}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6">
                <div className="row no-gutters">
                  <div className="col-12 col-sm-4 form-group">
                    <Label required>{t('dashboard.createClaim.modal.insurerCustomerId')}</Label>
                  </div>
                  <div className="col-12 col-sm-8 form-group">
                    <ResponsiveSelect
                      name="insurerCustomerId"
                      value={insurerCustomerId}
                      options={insuranceCompaniesForSelect}
                      placeholder={t('dashboard.createClaim.modal.insurerCustomerId')}
                      onChange={this.onChange}
                      isSearchable
                      errors={this.handleError(errors, 'insurerCustomerId')}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-6">
                <div className="row no-gutters">
                  <div className="col-12 col-sm-4 form-group">
                    <Label required>{t('dashboard.createClaim.modal.insuredLastName')}</Label>
                  </div>
                  <div className="col-12 col-sm-8 form-group">
                    <Input
                      className="form-control"
                      name="insuredLastName"
                      value={insuredLastName}
                      onChange={this.onChange}
                      required={true}
                      autoComplete="new-lastname"
                      placeholder={t('dashboard.createClaim.modal.insuredLastName')}
                      errors={this.handleError(errors, 'insuredLastName')}
                      maxLength={64}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6">
                <div className="row no-gutters">
                  <div className="col-12 col-sm-4 form-group">
                    <Label required>{t('dashboard.createClaim.modal.insuredFirstName')}</Label>
                  </div>
                  <div className="col-12 col-sm-8 form-group">
                    <Input
                      className="form-control"
                      name="insuredFirstName"
                      value={insuredFirstName}
                      onChange={this.onChange}
                      autoComplete="new-firstname"
                      placeholder={t('dashboard.createClaim.modal.insuredFirstName')}
                      errors={this.handleError(errors, 'insuredFirstName')}
                      maxLength={64}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-6">
                <div className="row no-gutters">
                  <div className="col-12 col-sm-4 form-group">
                    <Label required>{t('dashboard.createClaim.modal.policyNumber')}</Label>
                  </div>
                  <div className="col-12 col-sm-8 form-group">
                    <Input
                      className="form-control"
                      name="policyNumber"
                      value={policyNumber}
                      onChange={this.onChange}
                      autoComplete="new-policyNumber"
                      placeholder={t('dashboard.createClaim.modal.policyNumber')}
                      errors={this.handleError(errors, 'policyNumber')}
                      maxLength={20}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="row no-gutters">
                  <div className="col-12 col-sm-4 form-group">
                    <Label required>{t('dashboard.createClaim.modal.riskType')}</Label>
                  </div>
                  <div className="col-12 col-sm-8 form-group">
                    <ResponsiveSelect
                      name="riskType"
                      value={riskType}
                      options={riskTypesOptions}
                      placeholder={t('dashboard.createClaim.modal.riskType')}
                      onChange={this.onChange}
                      errors={this.handleError(errors, 'riskType')}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-6">
                <div className="row no-gutters">
                  <div className="col-12 col-sm-4 form-group">
                    <Label required>{t('dashboard.createManagerClaim.modal.contractNature')}</Label>
                  </div>
                  <div className="col-12 col-sm-8 form-group">
                    <ResponsiveSelect
                      name="contractNature"
                      value={contractNature}
                      options={contractNaturesForSelect}
                      placeholder={t('dashboard.createManagerClaim.modal.contractNature')}
                      onChange={this.onChange}
                      errors={this.handleError(errors, 'contractNature')}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-2 form-group">
                <Label required>{t('dashboard.createClaim.modal.address')}</Label>
              </div>
              <div className="col-12 col-sm-10 form-group pl-sm-1">
                <Address
                  name="address"
                  onSelected={this.onChangeAddress}
                  errors={this.handleError(errors, 'zipCode')}
                />
              </div>
            </div>
          </form>
        </div>

        <div className="modal-footer pb-2 pt-2">
          <button type="button" className="btn btn-secondary" onClick={this.closeModal}>
            {t('common.close')}
          </button>
          <Button
            className="btn btn-primary"
            onClick={this.createClaim}
            loading={saving}
            disabled={saving}
          >
            {t('common.create')}
          </Button>
        </div>
      </ModalWithBackdrop>
    )
  }
}

export default withRouter(withTranslation()(observer(CreateManagerClaimModal)))
