import React from 'react'
import { inject, observer } from 'mobx-react'
import classNames from 'classnames'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import PartyInvolvedCtrl from 'stores/Common/view/PartyInvolvedCtrl'
import { formatPhone, concatAddress, concatNames } from 'utils'
import DeleteButton from './DeleteButton'

const CardTitle = ({ civility, lastName, firstName, companyName, type, dataCyIndex = '' }) => {
  let name = `${civility} ${concatNames(lastName, firstName)}`
  if (companyName && type !== 'personal')
    name = `${companyName} ${
      lastName || firstName ? concatNames(civility, lastName, firstName) : ''
    }`

  return (
    <div className="card-title">
      <span className="party-card-title" data-cy={`party-card-title-${dataCyIndex}`}>
        {name}
      </span>
    </div>
  )
}

const Role = ({ type, name, companyName }) => {
  return (
    <h6 className="card-subtitle mb-2 text-muted">
      {type === 'personal' ? <i className="fa fa-user" /> : <i className="fa fa-briefcase" />}{' '}
      {name}
      {type === 'business' && companyName && companyName.length > 0 ? ` | ${companyName}` : null}
    </h6>
  )
}

const Info = ({ email, phoneNumber, mobilePhone, professionalPhone, fax, currentType, type }) => {
  if (currentType === 'personal' && type === 'business') return null
  const firstField = email ? (
    <span>
      <i className="fa fa-envelope" /> {email} <br />
    </span>
  ) : null
  const secondField = phoneNumber ? (
    <span>
      <i className="fa fa-phone" /> {formatPhone(phoneNumber)} <br />
    </span>
  ) : null
  const thirdField = professionalPhone ? (
    <span>
      <i className="fa fa-briefcase" /> {formatPhone(professionalPhone)} <br />
    </span>
  ) : null
  let lastField = null
  if (type === 'personal') {
    lastField = mobilePhone ? (
      <span>
        <i className="fa fa-mobile" /> {mobilePhone ? formatPhone(mobilePhone) : '-'}
      </span>
    ) : null
  } else {
    lastField = fax ? (
      <span>
        <i className="fa fa-fax" /> {fax ? formatPhone(fax) : '-'}
      </span>
    ) : null
  }

  return (
    <p className="card-text">
      {firstField}
      {secondField}
      {thirdField}
      {lastField}
    </p>
  )
}

class PartyCard extends React.Component {
  openModal = party => PartyInvolvedCtrl.showForm(party)

  render() {
    const {
      civility,
      firstName,
      lastName,
      emailAddress,
      phoneNumber,
      mobilePhone,
      professionalPhone,
      streetNumber,
      addressLine1,
      addressLine2,
      addressLine3,
      city,
      zipCode,
      companyName,
      companyPhoneNumber,
      companyEmailAddress,
      companyFax,
      additionalInformation,
      thirdPartyName,
      thirdPartyType,
      type,
      involvedPartyResponsabilityName,
      isUnknownAddress,
    } = this.props.data
    const { isMobile } = this.props.UiCtrl
    const { showActions } = PartyInvolvedCtrl.ctrl
    const { t } = this.props

    return (
      <div
        className={classNames('col-12 col-sm-6 mb-3', {
          'pl-0': !isMobile && this.props.index % 2 === 1,
        })}
      >
        <div className={classNames('party-card card', { 'read-only': !showActions })}>
          <div className="card-body" onClick={this.openModal.bind(this, this.props.data)}>
            <CardTitle
              civility={civility}
              firstName={firstName}
              lastName={lastName}
              companyName={companyName}
              type={type}
              dataCyIndex={`${companyName || ''}${firstName || ''}${lastName || ''}`}
            />

            <Role type={thirdPartyType} name={thirdPartyName} companyName={companyName} />

            <dl className="d-flex">
              <dt>{t('partyInvolved.modal.responsability')} :</dt>
              <dd className="pl-2 flex-grow-1">{involvedPartyResponsabilityName}</dd>
            </dl>

            <p className="card-text">
              {isUnknownAddress
                ? t('partyInvolved.modal.unknownAddress')
                : concatAddress({
                    streetNumber,
                    addressLine1,
                    addressLine2,
                    addressLine3,
                    city,
                    zipCode,
                  })}
            </p>

            <Info
              email={emailAddress}
              phoneNumber={phoneNumber}
              mobilePhone={mobilePhone}
              professionalPhone={professionalPhone}
              currentType={type}
              type="personal"
            />

            <Info
              email={companyEmailAddress}
              phone={companyPhoneNumber}
              fax={companyFax}
              currentType={type}
              type="business"
            />

            <p className="card-text font-italic">{additionalInformation}</p>
          </div>

          <DeleteButton involvedParty={this.props.data} />
        </div>
      </div>
    )
  }
}

export default inject('UiCtrl')(withRouter(withTranslation()(observer(PartyCard))))
