import React from 'react'
import { observer } from 'mobx-react'

import TextArea from 'components/Forms/TextArea'

const TextAreaWrapper = ({ inputData }) => {
  const onChange = e => inputData.setProperty('value', e.target.value)

  return <TextArea className="form-control" {...inputData} onChange={onChange} />
}

export default observer(TextAreaWrapper)
