import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import './DatePicker.css'
import InputErrors from 'components/Forms/InputErrors'

const dateFnsLocales = {
  'en-US': require('date-fns/locale/en-US'),
  'fr-FR': require('date-fns/locale/fr'),
}

class FormDatePicker extends React.Component {
  render() {
    const {
      children,
      className,
      dateFormat,
      required,
      selected,
      name,
      errors,
      onChange,
      onBlur,
      onFocus,
      placeholder,
      minDate,
      maxDate,
      showTimeSelect,
      startOpen,
      timeIntervals,
      showTimeInput,
      UiCtrl: { language },
      t,
    } = this.props
    const locale = dateFnsLocales[language]

    return (
      <div className="input-group date-picker">
        <DatePicker
          name={name}
          selected={selected}
          onChange={onChange}
          className={classNames(
            className,
            {
              error: errors && !!errors.length,
              required,
            },
            { 'bg-required': required && !selected },
          )}
          placeholderText={placeholder}
          onBlur={onBlur}
          onFocus={onFocus}
          dateFormat={dateFormat}
          locale={locale}
          minDate={minDate}
          maxDate={maxDate}
          showTimeSelect={showTimeSelect}
          autoComplete="off"
          timeFormat={t('common.timeFormat')}
          timeCaption={t('common.time')}
          startOpen={startOpen}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          timeIntervals={timeIntervals}
          showTimeInput={showTimeInput}
        >
          {children}
        </DatePicker>
        <InputErrors errors={errors} />
      </div>
    )
  }
}

FormDatePicker.defaultProps = {
  minDate: null,
  maxDate: null,
  dateFormat: 'dd/MM/y',
  startOpen: false,
  timeIntervals: 30,
  showTimeInput: false,
}

FormDatePicker.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.array,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  dateFormat: PropTypes.string,
  startOpen: PropTypes.bool,
  timeIntervals: PropTypes.number,
  showTimeInput: PropTypes.bool,
}

export default compose(
  inject('UiCtrl'),
  withTranslation(),
  observer,
)(FormDatePicker)
