import { observable, computed, action, decorate, runInAction } from 'mobx'
import { format } from 'date-fns'

import CommonStore from 'stores/Common/domain/CommonStore'
import { createManagerClaim } from 'services/claim'

class ManagerClaimFormClass {
  insurerCustomerId = ''
  insuredFirstName = ''
  insuredLastName = ''
  addressLine1 = ''
  addressLine2 = ''
  addressLine3 = ''
  city = ''
  zipCode = ''
  country = ''
  policyNumber = ''
  streetNumber = ''
  claimType = 'WTDG'
  dateOfLoss = new Date()
  riskType = ''
  contractNature = ''
  errors = []

  get asJson() {
    return {
      contract: {
        contractNatureKey: this.contractNature,
        policyNumber: this.policyNumber,
        insurer: {
          code: this.insurerCustomerId,
          companyName: CommonStore.getInsuranceCompanyNameFromGta(this.insurerCustomerId),
        },
      },
      insuredInformation: {
        insured: {
          civility: null,
          firstName: this.insuredFirstName,
          lastName: this.insuredLastName,
        },
      },
      claimInformation: {
        riskTypeKey: this.riskType,
        coverageKey: this.claimType,
        claimNumber: this.externalId,
        dateOfLoss: format(this.dateOfLoss, 'y-MM-dd'),
        declarationDate: format(new Date(), 'y-MM-dd'),
        addressOfLoss: {
          name: null,
          streetNumber: this.streetNumber,
          addressLine1: this.addressLine1,
          addressLine2: this.addressLine2,
          addressLine3: this.addressLine3,
          zipCode: this.zipCode,
          city: this.city,
          country: this.country,
          optOut: false,
          conformity: null,
        },
      },
    }
  }

  setProperty(key, value) {
    this[key] = value
  }
}

const ManagerClaimForm = decorate(ManagerClaimFormClass, {
  insurerCustomerId: observable,
  insurerClaimId: observable,
  externalId: observable,
  claimantFirstName: observable,
  claimantLastName: observable,
  insuredFirstName: observable,
  addressLine1: observable,
  addressLine2: observable,
  addressLine3: observable,
  city: observable,
  zipCode: observable,
  geometry: observable,
  country: observable,
  policyNumber: observable,
  insuredLastName: observable,
  streetNumber: observable,
  claimType: observable,
  expertiseTypeKey: observable,
  dateOfLoss: observable,
  riskType: observable,
  contractNature: observable,
  errors: observable,

  setProperty: action,

  asJson: computed,
})

class CreateManagerClaimCtrl {
  newClaim = null
  showModal = false
  saving = false

  constructor() {
    this.newClaim = new ManagerClaimForm()
  }

  setProperty(key, value) {
    this[key] = value
  }

  async createManagerClaim() {
    this.saving = true
    this.newClaim.setProperty('errors', [])

    try {
      const res = await createManagerClaim(this.newClaim.asJson)
      runInAction(() => {
        this.saving = false
        this.showModal = false
      })
      return res
    } catch (error) {
      runInAction(() => {
        this.newClaim.setProperty('errors', error)
        this.saving = false
      })

      return Promise.reject(error)
    }
  }

  clearForm() {
    this.newClaim = new ManagerClaimForm()
  }
}

const DecoratedCreateManagerClaimCtrl = decorate(CreateManagerClaimCtrl, {
  newClaim: observable,
  showModal: observable,
  saving: observable,

  setProperty: action,
  createManagerClaim: action,
  clearForm: action,
})

export default new DecoratedCreateManagerClaimCtrl()
