import React from 'react'
import { toJS } from 'mobx'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import ImageLoader from 'react-load-image'

import SupportingDocumentsCtrl, { isImage } from 'stores/Common/view/SupportingDocumentsCtrl'
import SupportingDocumentStore from 'stores/Common/domain/SupportingDocumentStore'
import { baseUrl } from 'services/utils'
import { noop } from 'utils'
import ImageLoading from 'components/ImageLoading/ImageLoading'
import FallBackThumbnail from 'components/FallBackThumbnail/FallBackThumbnail'
import ItemActions from './ItemActions'

class Item extends React.Component {
  onDrag = sdItem => {
    if (!SupportingDocumentsCtrl.dropZoneFilteredUpdateType)
      SupportingDocumentsCtrl.setDropZoneFilteredUpdateType(true, sdItem.id)
  }

  render() {
    const {
      t,
      sdItem,
      itemIndex,
      MissionStore: { isMissionClosed },
      ClaimStore: { isClaimClosed },
      UserStore: { token },
    } = this.props

    return (
      <div
        className="sd-item col-lg-3 col-md-4 col-xs-6"
        draggable={true}
        onDrag={this.onDrag.bind(this, sdItem)}
        onDragEnd={noop}
      >
        {!isMissionClosed && !isClaimClosed && <ItemActions sdItem={sdItem} />}

        <ImageLoader
          src={`${baseUrl()}/supportingDocuments/v1/${sdItem.id}/thumbnail?access_token=${token}`}
          onLoad={() => {
            SupportingDocumentsCtrl.setImagesLoaded(sdItem.id)
          }}
          onError={() => {
            SupportingDocumentsCtrl.setImagesLoaded(sdItem.id)
          }}
        >
          <img
            onClick={() => {
              SupportingDocumentsCtrl.setSliderModalDisplay(true)
              SupportingDocumentsCtrl.setSelectedSlide(itemIndex)
            }}
            draggable={true}
            alt={'supporting document'}
          />
          <div
            onClick={() => {
              SupportingDocumentsCtrl.setSliderModalDisplay(true)
              SupportingDocumentsCtrl.setSelectedSlide(itemIndex)
            }}
            draggable={true}
          >
            <FallBackThumbnail sliderIndex={itemIndex} mimeType={sdItem.mimeType} />
          </div>
          <ImageLoading />
        </ImageLoader>
        {toJS(SupportingDocumentsCtrl.imageLoaded[sdItem.id]) && (
          <div
            className="sd-infos"
            onClick={() => {
              SupportingDocumentsCtrl.setSliderModalDisplay(true)
              SupportingDocumentsCtrl.setSelectedSlide(itemIndex)
            }}
          >
            {isImage(sdItem.mimeType) && (
              <div>
                {sdItem.visionResults && sdItem.visionResults.pagesWithMatchingImages ? (
                  <p className="sd-alert">
                    <i className="fa fa-exclamation-triangle" />{' '}
                    {t('mission.supportingDocuments.ai.fraudAlertTitle')}
                  </p>
                ) : null}
                {sdItem.visionAlerts && sdItem.visionAlerts.includes('DISTANCE ALERT') ? (
                  <p className="sd-alert">
                    <i className="fa fa-exclamation-triangle" />{' '}
                    {t('mission.supportingDocuments.ai.distanceAlertTitle')}
                  </p>
                ) : null}
                {sdItem.visionResults &&
                sdItem.visionResults.safeSearchAnnotation &&
                (sdItem.visionResults.safeSearchAnnotation.adult === 'VERY_LIKELY' ||
                  sdItem.visionResults.safeSearchAnnotation.adult === 'LIKELY') ? (
                  <p className="sd-alert">
                    <i className="fa fa-thermometer-empty" />{' '}
                    {t('mission.supportingDocuments.ai.adultAlertTitle')}
                  </p>
                ) : null}
                {sdItem.visionResults && sdItem.visionResults.faceAnnotations ? (
                  <p className="sd-alert">
                    <i className="fa fa-info-circle " />{' '}
                    {t('mission.supportingDocuments.ai.faceCount', {
                      count: sdItem.visionResults.faceAnnotations.nb,
                    })}
                  </p>
                ) : null}
              </div>
            )}
            <p className="sd-name">
              <span
                className="badge"
                style={{
                  background: SupportingDocumentStore.typeKeysFilterList[sdItem.type.key].color,
                }}
              >
                &nbsp;
              </span>
              &nbsp;
              {sdItem.name}
            </p>
          </div>
        )}
      </div>
    )
  }
}

export default inject('MissionStore', 'ClaimStore', 'UserStore')(withTranslation()(observer(Item)))
