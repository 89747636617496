import { observable, computed, action, decorate, runInAction } from 'mobx'
import { differenceInHours, addHours } from 'date-fns'
import { path } from 'ramda'
import i18next from 'i18next'

import AlertCtrl from 'stores/Common/view/AlertCtrl'
import { fetchMission, getMissionsFromClaimId } from 'services/mission'
import { fetchClaim } from 'services/claim'
import { upsertAppointment, deleteAppointment } from 'services/calendar'
import { concatNames } from 'utils'

class Appointement {
  loading = false
  loadingValidation = false
  loadingWans = false

  id = null
  title = ''
  start = null
  end = null
  type = ''
  address = null
  wan = ''
  comment = ''
  assigner = null
  assignee = null
  originalAssignee = null
  coverageKey = null
  previousStart = null
  previousEnd = null
  insurerClaimId = null
  wans = []

  constructor(data) {
    if (data) {
      this.mergeData(data)
    }
  }

  get addressFormat() {
    if (!this.address) return null
    return {
      addressLine1: this.address.addressLine1,
      streetNumber: this.address.streetNumber,
      zipCode: this.address.zipCode,
      city: this.address.city,
      country: 'FR',
    }
  }

  get isValid() {
    const defaultValidation = this.title.length > 0 && this.type !== ''

    if (this.type === 'MISS') {
      return defaultValidation && this.wan.length > 0
    }

    return defaultValidation
  }

  get asJson() {
    const appointment = {
      id: this.id,
      title: this.title,
      startDate: this.start,
      endDate: this.end,
      type: this.type,
      wan: this.wan.replace(/ /g, ''),
      comment: this.comment,
      assignee: this.assignee,
    }
    if (this.addressFormat) appointment.address = this.addressFormat
    return appointment
  }

  get asEvent() {
    return {
      title: this.title,
      start: this.start,
      end: this.end,
      allDay: false,
      type: this.type,
      obj: this,
    }
  }

  get backgroundColor() {
    switch (this.type) {
      case 'VACA':
        return 'type-holiday'
      case 'PERM':
        return 'type-perm'
      case 'MISS':
        return this.coverageKey ? `coverage--${this.coverageKey}` : 'type-no-color'
      default:
        return 'type-no-color'
    }
  }

  get wansForSelect() {
    return this.wans.map(({ wan, cfi }) => ({
      value: wan,
      label: concatNames(
        wan,
        '/',
        i18next.t('calendar.appointment.missionNum'),
        ':',
        path(['claimInformation', 'insurerMissionId'], cfi),
      ),
    }))
  }

  mergeData = data => {
    this.id = data.id || null
    this.title = data.title || ''
    this.start = data.start
    this.end = data.end
    this.type = data.type || ''
    this.address = data.address || null
    this.wan = data.wan || ''
    this.comment = data.comment || ''
    this.assigner = data.assigner || null
    this.assignee = data.assignee || null
    this.coverageKey = data.coverageKey || null
    this.previousStart = data.start || null
    this.previousEnd = data.end || null
    this.insurerClaimId = data.insurerClaimId || null
    this.expertiseCharacteristic = data.expertiseCharacteristic || null
    this.missionNature = data.missionNature || null
    this.expertColor = data.expertColor || null
    this.originalAssignee = this.assignee

    // Only used for redirection to the the corresponding CFI wan if it's a manager/secretary (CFI)
    this.cfiWan = data.cfiWan || ''

    this.originalData = data
  }

  resetData() {
    this.mergeData(this.originalData)
  }

  isWAnValid = async (wan, isExpert, isInsurer) => {
    try {
      this.loadingValidation = true
      if (isExpert) await fetchMission(wan)
      else if (isInsurer) await fetchClaim(wan)
      else return false

      return true
    } catch (err) {
      return false
    } finally {
      runInAction(() => (this.loadingValidation = false))
    }
  }

  getWans = async id => {
    this.loadingWans = true
    try {
      const wans = await getMissionsFromClaimId(id)
      runInAction(() => {
        if (wans.length === 0) {
          this.address = null
          this.wan = ''
          AlertCtrl.alert('danger', 'calendar.appointment.fetchWanError')
        } else if (wans.length === 1) {
          this.wan = wans[0].wan
          this.address = path(['cfi', 'claimInformation', 'addressOfLoss'], wans[0])
        } else {
          this.wans = wans
        }
      })
    } catch (err) {
      runInAction(() => {
        this.wans = []
        this.address = null
        this.wan = ''
      })
    } finally {
      runInAction(() => (this.loadingWans = false))
    }
  }

  changeStartDate(value) {
    let difference = 1
    if (this.start && this.end) difference = differenceInHours(this.end, this.start)

    this.start = value
    this.end = addHours(value, difference)
  }

  setProperty(key, value) {
    this[key] = value
  }

  async save() {
    this.loading = true
    try {
      const res = await upsertAppointment(this.asJson)
      runInAction(() => {
        this.previousStart = this.start
        this.previousEnd = this.end
      })
      return res
    } catch (err) {
      runInAction(() => {
        this.start = this.previousStart
        this.end = this.previousEnd
        this.assignee = this.originalAssignee
      })
      throw err
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  async destroy() {
    this.loading = true
    try {
      const res = await deleteAppointment(this.id)
      return res
    } catch (err) {
      throw err
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }
}

const AppointementDecorated = decorate(Appointement, {
  title: observable,
  start: observable,
  end: observable,
  type: observable,
  address: observable,
  wan: observable,
  cfiWan: observable,
  comment: observable,
  assignee: observable,
  originalAssignee: observable,
  loading: observable,
  previousStart: observable,
  previousEnd: observable,
  loadingValidation: observable,
  insurerClaimId: observable,
  wans: observable,
  loadingWans: observable,
  expertColor: observable,
  originalData: observable,

  asJson: computed,
  asEvent: computed,
  backgroundColor: computed,
  addressFormat: computed,
  isValid: computed,
  wansForSelect: computed,

  setProperty: action.bound,
  save: action.bound,
  destroy: action.bound,
  changeStartDate: action.bound,
  isWAnValid: action,
  getWans: action,
  mergeData: action,
  resetData: action,
})

export default AppointementDecorated
