import { observable, action, decorate, runInAction } from 'mobx'

import AlertCtrl from 'stores/Common/view/AlertCtrl'
import { startNewSession } from 'services/teleexpertise'
import { createSupportingDocuments } from 'services/supportingDocuments'
import { dataURLtoFile } from 'utils/upload'

class TeleexpertiseCtrl {
  showTeleexpertiseForm = false
  involvedParty = null
  phoneNumber = ''
  email = ''
  sessionStarted = false
  token = null
  sessionId = null
  showScreenshotModal = false
  imgData = null
  uploading = false
  sending = false

  setProperty = (key, value) => {
    this[key] = value
  }
  resetForm = () => {
    this.phoneNumber = ''
    this.email = ''
    this.involvedParty = null
  }
  startSession = async (wan, wanType) => {
    this.sending = true
    try {
      const res = await startNewSession(wan, this.phoneNumber, this.email, wanType)

      runInAction(() => {
        this.sending = false
        this.token = res.token
        this.sessionId = res.sessionId
        this.sessionStarted = true
        this.showTeleexpertiseForm = false
        window.open(
          `/${wan}/expertVisio/${this.sessionId}/${this.token}`,
          'Visio',
          `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
        width=690,height=650,left=200,top=200`,
        )
      })
    } catch (error) {
      console.log(error)
      runInAction(() => {
        this.sending = false
      })
    }
  }

  endSession = () => {
    this.token = null
    this.sessionId = null
    this.sessionStarted = false
    this.email = ''
    this.involvedParty = null
    this.showTeleexpertiseForm = false
    this.showScreenshotModal = false
    this.imgData = null
  }

  uploadScreenshot = async ({ wan, fileName, category }) => {
    this.uploading = true

    try {
      await createSupportingDocuments({
        url: `/claimFiles/v1/claimFileAssessor/${wan}/documents`,
        name: fileName,
        type: { key: category },
        file: dataURLtoFile(`data:image/png;base64,${this.imgData}`, fileName),
        extraData: null,
        noProgress: true,
      })

      runInAction(() => {
        AlertCtrl.alert('success', 'teleexpertise.uploadSuccess')
        this.uploading = false
        this.closeScreenshotModal()
      })
    } catch (error) {
      AlertCtrl.alert('error', 'teleexpertise.uploadError')
      console.error(error)
    }
  }

  openScreenshotModal = imgData => {
    this.showScreenshotModal = true
    this.imgData = imgData
  }

  closeScreenshotModal = () => {
    this.showScreenshotModal = false
    this.imgData = null
  }
}

const TeleexpertiseCtrlDecorated = decorate(TeleexpertiseCtrl, {
  showTeleexpertiseForm: observable,
  phoneNumber: observable,
  email: observable,
  sessionStarted: observable,
  token: observable,
  sessionId: observable,
  involvedParty: observable,
  showScreenshotModal: observable,
  imgData: observable,
  uploading: observable,
  sending: observable,

  startSession: action,
  resetForm: action,
  endSession: action,
  setProperty: action,
  openScreenshotModal: action,
  closeScreenshotModal: action,
  uploadScreenshot: action,
})

export default new TeleexpertiseCtrlDecorated()
