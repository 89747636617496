import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { inject, observer } from 'mobx-react'

import QlickLink from './QlikLink'

const MissionLinks = ({ DashboardCtrl: { newMissions }, InboxStore: { notifications } }) => {
  const { t } = useTranslation()
  const missionNotification = newMissions > 9 ? '9+' : newMissions

  return (
    <Fragment>
      <li className="nav-item">
        <NavLink exact to="/" className="nav-link text-primary" activeClassName="active">
          {t('header.navbar.links.missions')}

          {newMissions > 0 && (
            <span className="badge badge-danger ml-1">{missionNotification}</span>
          )}
        </NavLink>
      </li>

      <span className="divider" />

      <li className="nav-item">
        <NavLink exact className="nav-link text-primary" activeClassName="active" to="/inbox">
          {t('header.navbar.links.inbox')}

          {notifications > 0 && <span className="badge badge-danger ml-1">{notifications}</span>}
        </NavLink>
      </li>

      <span className="divider" />

      <li className="nav-item">
        <NavLink exact className="nav-link text-primary" activeClassName="active" to="/calendar">
          {t('header.navbar.links.calendar')}
        </NavLink>
      </li>

      <QlickLink />
    </Fragment>
  )
}

export default inject('DashboardCtrl', 'InboxStore')(observer(MissionLinks))
