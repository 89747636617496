import React from 'react'
import { inject, observer } from 'mobx-react'
import { isNil } from 'ramda'

import Button from 'components/Button/Button'
import ReportButton from './ReportButton'
import Tooltip from 'components/Tooltip/Tooltip'
import { arrayToDivs } from 'utils'

const SendReportButton = ({
  sendDarva,
  setShowModal,
  MissionStore: { loading: loadingMission, isWorkCFA, cfa },
  ReportCtrl: { loading: loadingReport, loadingValidator, currentReportObj, antiSpamActivated },
  CartStore: { saving },
}) => {
  if (isNil(isWorkCFA) || isNil(currentReportObj.reportType)) return null

  const btnSendIsVisible = isNil(currentReportObj.hideBtnSend) || !currentReportObj.hideBtnSend

  // SHOW MODAL BUTTON
  if (currentReportObj.modal) {
    return (
      <ReportButton>
        <Button
          className="btn btn-primary mr-2 send-report"
          onClick={() => setShowModal(true)}
          disabled={loadingReport || saving || loadingMission || antiSpamActivated}
        >
          {currentReportObj.buttonText}
        </Button>
      </ReportButton>
    )
  }

  const getTooltipText = () => {
    const tooltips = []
    if (!isNil(currentReportObj.isDisabled) && currentReportObj.isDisabled === true) {
      tooltips.push(currentReportObj.disabledReason)
    }

    return arrayToDivs(tooltips)
  }

  // SEND TO DARVA BUTTON
  return (
    <ReportButton>
      <Tooltip className="tooltip-action-bar" text={getTooltipText()}>
        {btnSendIsVisible && (
          <Button
            className="btn btn-primary mr-2 send-report"
            onClick={sendDarva}
            loading={loadingReport || loadingValidator}
            disabled={
              loadingValidator ||
              loadingReport ||
              saving ||
              loadingMission ||
              antiSpamActivated ||
              (!isNil(currentReportObj.isDisabled) && currentReportObj.isDisabled === true)
            }
          >
            {currentReportObj.buttonText}
          </Button>
        )}
      </Tooltip>
    </ReportButton>
  )
}

export default inject('MissionStore', 'ReportCtrl', 'CartStore')(observer(SendReportButton))
